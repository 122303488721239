import React, { useEffect, useState } from "react";
import axios from "axios";
import { 
  Box, 
  Button, 
  Typography, 
  Grid, 
  Dialog, 
  DialogContent, 
  IconButton,
  Paper,
  Divider,
  Fade,
  CircularProgress,
  Alert,
  Chip
} from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { FaFileExcel, FaFileWord } from 'react-icons/fa';
import { toast } from 'react-toastify';

const getFileIcon = (fileType) => {
  switch (fileType) {
    case 'pdf':
      return <PictureAsPdfIcon color="error" sx={{ fontSize: 100 }} />;
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'gif':
      return <ImageIcon color="primary" sx={{ fontSize: 100 }} />;
    case 'xls':
    case 'xlsx':
    case 'csv':
      return <FaFileExcel color="green" size={100} />;
    case 'doc':
    case 'docx':
      return <FaFileWord color="blue" size={100} />;
    default:
      return <InsertDriveFileIcon color="action" sx={{ fontSize: 100 }} />;
  }
};

const UserDocs = () => {
  const [userData, setUserData] = useState({
    iduser: null,
    uploadsPath: "",
    uploadsPathAso: ""
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [failedImages, setFailedImages] = useState(new Set());

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const userId = localStorage.getItem("userId");
        if (!userId) {
          setError("Usuário não identificado");
          return;
        }

        console.log("Buscando dados do usuário:", userId);
        const response = await axios.get(`/users/${userId}`);
        console.log("Dados recebidos do usuário:", response.data);

        // Ajuste para garantir que os caminhos sejam tratados corretamente
        const userData = {
          ...response.data,
          iduser: userId,
          uploadsPath: response.data.uploadsPath || response.data.uploads_path || "",
          uploadsPathAso: response.data.uploadsPathAso || response.data.uploads_path_aso || ""
        };

        console.log("Dados processados:", {
          userData,
          paths: {
            docs: userData.uploadsPath ? userData.uploadsPath.split(',').map(p => p.trim()).filter(Boolean) : [],
            aso: userData.uploadsPathAso ? userData.uploadsPathAso.split(',').map(p => p.trim()).filter(Boolean) : []
          }
        });
        
        setUserData(userData);
        setError(null);
      } catch (error) {
        console.error("Erro ao obter dados do usuário:", {
          error,
          message: error.message,
          response: error.response?.data
        });
        setError("Erro ao carregar dados do usuário");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  // Organizar documentos por categoria
  const docsByCategory = {
    aso: userData.uploadsPathAso ? userData.uploadsPathAso.split(',').map(path => path.trim()).filter(Boolean) : [],
    docs: userData.uploadsPath ? userData.uploadsPath.split(',').map(path => path.trim()).filter(Boolean) : []
  };

  const renderFilePreview = (path, category) => {
    if (!path) return null;
    
    const trimmedPath = path.trim();
    const fileType = trimmedPath.split('.').pop().toLowerCase();
    const fileName = path.split('/').pop();
    const fullPath = `/api/${trimmedPath}`;
    const isImage = ['jpeg', 'jpg', 'png', 'gif'].includes(fileType);

    return (
      <Grid item xs={12} sm={6} md={4} lg={3} key={trimmedPath}>
        <Fade in={true} timeout={500}>
          <Paper
            elevation={3}
            sx={{
              p: { xs: 1.5, sm: 2 },
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              transition: 'transform 0.2s, box-shadow 0.2s',
              cursor: 'pointer',
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: 6
              }
            }}
            onClick={() => handleFileClick(trimmedPath, category)}
          >
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column',
              alignItems: 'center',
              flex: 1
            }}>
              {isImage && !failedImages.has(trimmedPath) ? (
                <Box sx={{ 
                  width: '100%',
                  height: 140,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mb: 2,
                  borderRadius: 1,
                  overflow: 'hidden',
                  bgcolor: 'background.default'
                }}>
                  <img
                    src={fullPath}
                    alt={fileName}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain'
                    }}
                    onError={(e) => {
                      console.error("Erro ao carregar imagem:", fullPath);
                      setFailedImages(prev => new Set([...prev, trimmedPath]));
                    }}
                  />
                </Box>
              ) : (
                <Box sx={{ mb: 2 }}>
                  {getFileIcon(fileType)}
                </Box>
              )}
              <Typography 
                variant="body2" 
                sx={{ 
                  textAlign: 'center',
                  wordBreak: 'break-word',
                  color: 'text.secondary'
                }}
              >
                {fileName}
              </Typography>
            </Box>
            <Chip
              size="small"
              label={fileType.toUpperCase()}
              sx={{ 
                alignSelf: 'center',
                mt: 1,
                bgcolor: theme => theme.palette.mode === 'dark' ? 'grey.800' : 'grey.100'
              }}
            />
          </Paper>
        </Fade>
      </Grid>
    );
  };

  const handleFileClick = (path, category) => {
    if (!path) return;
    
    const trimmedPath = path.trim();
    const fileType = trimmedPath.split('.').pop().toLowerCase();
    const isImage = ['jpeg', 'jpg', 'png', 'gif'].includes(fileType);
    const fullPath = `/api/${trimmedPath}`;

    console.log("Clique no arquivo:", {
      path: trimmedPath,
      fileType,
      isImage,
      fullPath,
      category
    });

    if (isImage) {
      setSelectedImage(fullPath);
      setLightboxOpen(true);
    } else if (fileType === 'pdf') {
      window.open(fullPath, '_blank');
    } else {
      const link = document.createElement('a');
      link.href = fullPath;
      link.download = path.split('/').pop();
      link.click();
    }
  };

  const handleDeleteDocs = async () => {
    try {
      const userId = localStorage.getItem("userId");
      await axios.delete(`/uploads`, { params: { userId } });
      setUserData(prevData => ({
        ...prevData,
        uploadsPath: null
      }));
      toast.success('Documentos gerais excluídos com sucesso!');
    } catch (error) {
      console.error('Erro ao excluir documentos:', error);
      toast.error('Erro ao excluir documentos');
    }
  };

  const handleDeleteAso = async () => {
    try {
      const userId = localStorage.getItem("userId");
      await axios.delete(`/uploads/aso`, { params: { userId } });
      setUserData(prevData => ({
        ...prevData,
        uploadsPathAso: null
      }));
      toast.success('Documentos ASO excluídos com sucesso!');
    } catch (error) {
      console.error('Erro ao excluir documentos ASO:', error);
      toast.error('Erro ao excluir documentos ASO');
    }
  };

  const handleDeleteAll = async () => {
    try {
      const userId = localStorage.getItem("userId");
      await Promise.all([
        axios.delete(`/uploads`, { params: { userId } }),
        axios.delete(`/uploads/aso`, { params: { userId } })
      ]);
      setUserData(prevData => ({
        ...prevData,
        uploadsPath: null,
        uploadsPathAso: null
      }));
      toast.success('Todos os documentos excluídos com sucesso!');
      setShowConfirmation(false);
    } catch (error) {
      console.error('Erro ao excluir todos os documentos:', error);
      toast.error('Erro ao excluir documentos');
    }
  };

  const renderSection = (title, docs, handleDelete) => (
    <Box sx={{ mb: 4 }}>
      <Paper 
        elevation={2}
        sx={{ 
          p: { xs: 2, sm: 3 },
          bgcolor: theme => theme.palette.mode === 'dark' ? 'grey.900' : 'background.paper',
          borderRadius: 2
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between', 
          alignItems: { xs: 'flex-start', sm: 'center' },
          gap: { xs: 1, sm: 0 },
          mb: 3 
        }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1,
            width: { xs: '100%', sm: 'auto' },
            justifyContent: { xs: 'space-between', sm: 'flex-start' }
          }}>
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
            <Chip 
              label={`${docs.length} ${docs.length === 1 ? 'documento' : 'documentos'}`}
              size="small"
              sx={{ 
                bgcolor: theme => theme.palette.mode === 'dark' ? 'grey.800' : 'grey.100'
              }}
            />
            <IconButton
              color="error"
              size="small"
              onClick={handleDelete}
              sx={{ 
                display: { xs: 'flex', sm: 'none' },
                '&:hover': {
                  bgcolor: 'error.light',
                  color: 'white'
                }
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Box>
          <IconButton
            color="error"
            size="small"
            onClick={handleDelete}
            sx={{ 
              display: { xs: 'none', sm: 'flex' },
              '&:hover': {
                bgcolor: 'error.light',
                color: 'white'
              }
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
        <Grid container spacing={2}>
          {docs.map(path => renderFilePreview(path, title.toLowerCase()))}
        </Grid>
      </Paper>
    </Box>
  );

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Paper 
        elevation={0}
        sx={{ 
          p: { xs: 2, sm: 3 }, 
          mb: 4,
          bgcolor: theme => theme.palette.mode === 'dark' ? 'grey.900' : 'grey.50',
          borderRadius: 2,
          border: '1px solid',
          borderColor: theme => theme.palette.mode === 'dark' ? 'grey.800' : 'grey.200'
        }}
      >
        <Box>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 500 }}>
            Meus Documentos
          </Typography>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between', 
            alignItems: { xs: 'flex-start', sm: 'center' },
            gap: { xs: 2, sm: 3 }
          }}>
            <Typography 
              variant="body2" 
              color="text.secondary" 
              sx={{ 
                maxWidth: { xs: '100%', sm: '70%' }
              }}
            >
              Gerencie seus documentos enviados. Se necessário, você pode excluí-los
              e reenviá-los individualmente conforme solicitado.
            </Typography>
            {(docsByCategory.aso.length > 0 || docsByCategory.docs.length > 0) && (
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={() => setShowConfirmation(true)}
                startIcon={<DeleteIcon />}
                sx={{ 
                  alignSelf: { xs: 'flex-start', sm: 'center' },
                  whiteSpace: 'nowrap',
                  px: 2,
                  '&:hover': {
                    bgcolor: 'error.light',
                    color: 'white',
                    borderColor: 'error.light'
                  }
                }}
              >
                Excluir Todos
              </Button>
            )}
          </Box>
        </Box>
      </Paper>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ mb: 4 }}>
          {error}
        </Alert>
      )}

      {!loading && !error && (
        <>
          {docsByCategory.aso.length > 0 && renderSection('Documentos ASO', docsByCategory.aso, handleDeleteAso)}
          {docsByCategory.docs.length > 0 && renderSection('Documentos Gerais', docsByCategory.docs, handleDeleteDocs)}
        </>
      )}

      {!loading && !error && docsByCategory.aso.length === 0 && docsByCategory.docs.length === 0 && (
        <Alert severity="info" sx={{ mt: 2 }}>
          Você ainda não enviou seus documentos. Favor enviar os documentos solicitados em "Enviar documentos".
        </Alert>
      )}

      {/* Diálogo de Confirmação */}
      <Dialog 
        open={showConfirmation} 
        onClose={() => setShowConfirmation(false)}
        PaperProps={{
          elevation: 24,
          sx: { borderRadius: 2 }
        }}
      >
        <DialogContent sx={{ p: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
            <DeleteIcon color="error" />
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              Confirmar Exclusão
            </Typography>
          </Box>
          <Typography color="text.secondary" paragraph>
            Ao realizar a exclusão, será necessário reenviar todos os documentos novamente.
            Tem certeza que deseja excluir todos os documentos?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 3 }}>
            <Button
              onClick={() => setShowConfirmation(false)}
              variant="outlined"
              size="small"
            >
              Cancelar
            </Button>
            <Button
              onClick={handleDeleteAll}
              variant="contained"
              color="error"
              size="small"
              startIcon={<DeleteIcon />}
            >
              Confirmar
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Lightbox para Imagens */}
      <Dialog
        open={lightboxOpen}
        onClose={() => setLightboxOpen(false)}
        maxWidth="xl"
        fullWidth
        PaperProps={{
          elevation: 24,
          sx: { bgcolor: 'background.paper' }
        }}
      >
        <DialogContent sx={{ p: 0, position: 'relative' }}>
          <IconButton
            onClick={() => setLightboxOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
              bgcolor: 'rgba(0,0,0,0.5)',
              '&:hover': {
                bgcolor: 'rgba(0,0,0,0.7)'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Documento"
              style={{
                width: '100%',
                height: 'auto',
                maxHeight: '90vh',
                objectFit: 'contain'
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default UserDocs;
