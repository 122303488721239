import React from 'react';
import GlobalStyle from "../styles/global";
import { Link } from "react-router-dom";
import { Box, Typography, Button } from '@mui/material';

const Home = () => (
  <>
    <GlobalStyle />
    <Box
      sx={{
        position: 'relative',
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        color: 'white',
      }}
    >
      <video
        autoPlay
        loop
        muted
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          minWidth: '100%',
          minHeight: '100%',
          width: 'auto',
          height: 'auto',
          zIndex: -1,
          transform: 'translate(-50%, -50%)',
        }}
      >
        <source src="/assets/bg-3.mp4" type="video/mp4" />
        Seu navegador não suporta vídeos.
      </video>

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%', 
          backgroundColor: 'rgba(100, 0, 150, 0.4)', // Roxo com opacidade
          backdropFilter: 'blur(6px)', // Efeito de desfoque
          zIndex: 0,
        }}
      />

      <Box sx={{ zIndex: 1 }}>
        <img
          className="navLogo"
          src="/assets/iboard-logo-sfundo.png"
          alt="Logo"
          style={{ maxWidth: '180px', height: 'auto' }}
        />
        
        <Box className="ContentApp" sx={{  maxWidth: '650px' }}>
          <Typography variant="body1" gutterBottom sx={{  mb:2, p:2 }}>
            A plataforma inovadora que simplifica e agiliza o processo de admissão, conectando colaboradores e recrutadores de forma intuitiva e eficiente.
          </Typography>

          <Typography variant="body2" gutterBottom>
            Para continuar selecione uma opção:
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Link to="/login" style={{ textDecoration: 'none' }}>
            <Button variant="contained" sx={{ color: 'yellow',  p:2 }}>
                Colaborador
              </Button>
            </Link>

            <Link to="/LoginCnpj" style={{ textDecoration: 'none' }}>
            <Button variant="contained" sx={{ color: 'yellow', p:2 }}>
                Recrutador
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  </>
);

export default Home;