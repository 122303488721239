import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Button,
  TextField,
  IconButton,
  Box,
  Typography,
  Grid,
  Divider,
  useTheme,
  Paper,
  Chip,
  Stack,
  useMediaQuery,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
} from '@mui/material';
import { 
  Add as AddIcon, 
  Remove as RemoveIcon,
  LocalMall as UniformIcon,
  Security as EpiIcon,
  Straighten as SizeIcon,
  Edit as EditIcon,
  Delete as DeleteIcon, 
  Inventory as StockIcon,
  Person as UserIcon,
} from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

const UniformsEpiCreator = () => {
  const [tabValue, setTabValue] = useState(0);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [availableSizes, setAvailableSizes] = useState(['']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userCnpj, setUserCnpj] = useState('');
  const [error, setError] = useState('');
  const [uniformsEpi, setUniformsEpi] = useState([]);
  const [responses, setResponses] = useState([]);
  const [users, setUsers] = useState([]);
  const [openResponseDialog, setOpenResponseDialog] = useState(false);
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [responseData, setResponseData] = useState({
    status: '',
    justificativa: '',
    dataEntrega: '',
    observacoes: '',
  });
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Opções predefinidas de tipos
  const typeOptions = [
    'Uniforme - Camiseta',
    'Uniforme - Calça',
    'Uniforme - Jaleco',
    'EPI - Capacete',
    'EPI - Luvas',
    'EPI - Óculos',
    'EPI - Botas'
  ];

  // Opções predefinidas de tamanhos
  const commonSizes = {
    'Uniforme - Camiseta': ['PP', 'P', 'M', 'G', 'GG', 'XG'],
    'Uniforme - Calça': ['36', '38', '40', '42', '44', '46', '48', '50'],
    'Uniforme - Jaleco': ['PP', 'P', 'M', 'G', 'GG', 'XG'],
    'EPI - Capacete': ['Único', 'P', 'M', 'G'],
    'EPI - Luvas': ['P', 'M', 'G'],
    'EPI - Óculos': ['Único'],
    'EPI - Botas': ['34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45']
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const userIdFromStorage = localStorage.getItem('userId');
      if (userIdFromStorage) {
        const [userResponse, uniformsResponse, responsesResponse, usersResponse] = await Promise.all([
          axios.get(`/users/${userIdFromStorage}`),
          axios.get('/uniforms-epi'),
          axios.get('/uniforms-epi/responses'),
          axios.get('/users')
        ]);

        setUserCnpj(userResponse.data.id_cnpj);
        setUniformsEpi(uniformsResponse.data);
        setResponses(responsesResponse.data);
        setUsers(usersResponse.data);
      }
    } catch (error) {
      console.error('Erro ao carregar dados:', error);
      toast.error('Erro ao carregar dados');
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    if (error) setError('');
  };

  const handleTypeChange = (e) => {
    const selectedType = e.target.value;
    setType(selectedType);
    setAvailableSizes(commonSizes[selectedType] ? [commonSizes[selectedType][0]] : ['']);
    if (error) setError('');
  };

  const handleSizeChange = (index, value) => {
    const newSizes = [...availableSizes];
    newSizes[index] = value;
    setAvailableSizes(newSizes);
    if (error) setError('');
  };

  const handleAddSize = () => {
    if (type && commonSizes[type]) {
      const availableOptions = commonSizes[type].filter(size => !availableSizes.includes(size));
      if (availableOptions.length > 0) {
        setAvailableSizes([...availableSizes, availableOptions[0]]);
      } else {
        toast.info('Todos os tamanhos disponíveis já foram adicionados');
      }
    } else {
      setAvailableSizes([...availableSizes, '']);
    }
  };

  const handleRemoveSize = (index) => {
    const newSizes = availableSizes.filter((_, i) => i !== index);
    setAvailableSizes(newSizes);
  };

  const validateEpi = () => {
    if (!name.trim()) {
      setError('Por favor, insira o nome do item');
      return false;
    }
    if (!type) {
      setError('Por favor, selecione o tipo do item');
      return false;
    }
    if (availableSizes.some(size => !size.trim())) {
      setError('Por favor, preencha todos os tamanhos');
      return false;
    }
    setError('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEpi()) {
      toast.error('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    setIsSubmitting(true);

    try {
      await axios.post('/uniforms-epi', {
        name,
        type,
        availableSizes,
        id_cnpj: userCnpj,
      });
      
      // Limpar formulário
      setName('');
      setType('');
      setAvailableSizes(['']);
      toast.success('Item cadastrado com sucesso!');
      fetchData(); // Atualizar a lista
    } catch (error) {
      console.error('Erro ao cadastrar item', error);
      toast.error('Erro ao cadastrar item. Por favor, tente novamente.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Tem certeza que deseja excluir este item?')) {
      try {
        await axios.delete(`/uniforms-epi/${id}`);
        toast.success('Uniforme/EPI excluído com sucesso!');
        fetchData();
      } catch (error) {
        console.error('Erro ao excluir uniforme/EPI:', error);
        toast.error('Erro ao excluir uniforme/EPI');
      }
    }
  };

  const handleOpenResponseDialog = (response) => {
    setSelectedResponse(response);
    setResponseData({
      status: response.status || 'pendente',
      justificativa: response.justificativa || '',
      dataEntrega: response.dataEntrega ? moment(response.dataEntrega).format('YYYY-MM-DD') : '',
      observacoes: response.observacoes || '',
    });
    setOpenResponseDialog(true);
  };

  const handleCloseResponseDialog = () => {
    setOpenResponseDialog(false);
    setSelectedResponse(null);
    setResponseData({
      status: '',
      justificativa: '',
      dataEntrega: '',
      observacoes: '',
    });
  };

  const handleResponseSubmit = async () => {
    try {
      await axios.put(`/uniforms-epi/responses/${selectedResponse.id}`, responseData);
      toast.success('Solicitação atualizada com sucesso!');
      fetchData();
      handleCloseResponseDialog();
    } catch (error) {
      console.error('Erro ao processar solicitação:', error);
      toast.error('Erro ao processar solicitação');
    }
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'aprovado':
        return 'success';
      case 'pendente':
        return 'warning';
      case 'negado':
        return 'error';
      default:
        return 'default';
    }
  };

  const getUserName = (userId) => {
    const user = users.find(u => u.iduser === userId);
    return user ? user.nome : 'Usuário não encontrado';
  };

  const renderInventoryTab = () => (
    <TableContainer component={Paper} elevation={0}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Tamanhos</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {uniformsEpi.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.type}</TableCell>
              <TableCell>
                {Array.isArray(item.availableSizes) 
                  ? item.availableSizes.join(', ')
                  : item.availableSizes}
              </TableCell>
              <TableCell>
                <IconButton onClick={() => handleDelete(item.id)} color="error">
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderRequestsTab = () => (
    <TableContainer component={Paper} elevation={0}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Solicitante</TableCell>
            <TableCell>Item</TableCell>
            <TableCell>Tamanho</TableCell>
            <TableCell>Quantidade</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Data Solicitação</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {responses.map((response) => {
            const uniform = uniformsEpi.find(u => u.id === response.uniformEpiId);
            return (
              <TableRow key={response.id}>
                <TableCell>{getUserName(response.userId)}</TableCell>
                <TableCell>{uniform?.name}</TableCell>
                <TableCell>{response.size}</TableCell>
                <TableCell>{response.quantidade}</TableCell>
                <TableCell>
                  <Chip 
                    label={response.status}
                    color={getStatusColor(response.status)}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  {moment(response.dataSolicitacao).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell>
                  <IconButton 
                    onClick={() => handleOpenResponseDialog(response)}
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Grid container spacing={3} sx={{ p: 2 }}>
      <ToastContainer />
      
      <Grid item xs={12}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab 
              label="Cadastro" 
              icon={<AddIcon />} 
              iconPosition="start"
              sx={{ 
                '&.Mui-selected': {
                  color: '#6A438B'
                }
              }}
            />
            <Tab 
              label="Inventário" 
              icon={<StockIcon />} 
              iconPosition="start"
              sx={{ 
                '&.Mui-selected': {
                  color: '#6A438B'
                }
              }}
            />
            <Tab 
              label="Solicitações" 
              icon={<UserIcon />} 
              iconPosition="start"
              sx={{ 
                '&.Mui-selected': {
                  color: '#6A438B'
                }
              }}
            />
          </Tabs>
        </Box>
      </Grid>

      {tabValue === 0 && (
        <>
          {/* Seção de Cadastro */}
          <Grid item xs={12} md={6}>
            <Paper 
              elevation={0}
              sx={{ 
                p: 3, 
                borderRadius: 2,
                border: '1px solid #e0e0e0',
                height: '100%'
              }}
            >
              <Box sx={{ mb: 3 }}>
                <Typography variant="h5" gutterBottom sx={{ 
                  color: '#6A438B',
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}>
                  {type?.includes('EPI') ? <EpiIcon /> : <UniformIcon />}
                  Cadastrar {type?.includes('EPI') ? 'EPI' : 'Uniforme'}
                </Typography>
                <Divider />
              </Box>

              <Box component="form" onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    label="Nome do Item"
                    value={name}
                    onChange={handleNameChange}
                    fullWidth
                    required
                    error={Boolean(error && !name)}
                    helperText={error && !name ? error : ''}
                    size={isMobile ? "small" : "medium"}
                  />

                  <FormControl fullWidth required error={Boolean(error && !type)}>
                    <InputLabel>Tipo do Item</InputLabel>
                    <Select
                      value={type}
                      onChange={handleTypeChange}
                      label="Tipo do Item"
                      size={isMobile ? "small" : "medium"}
                    >
                      {typeOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Box>
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: 1, 
                      mb: 2 
                    }}>
                      <SizeIcon color="action" />
                      <Typography variant="subtitle1">
                        Tamanhos Disponíveis
                      </Typography>
                      <Tooltip title="Adicionar tamanho">
                        <IconButton 
                          onClick={handleAddSize}
                          size="small"
                          sx={{ 
                            bgcolor: '#6A438B',
                            color: '#fff',
                            '&:hover': {
                              bgcolor: '#5a3777'
                            }
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>

                    <Stack spacing={2}>
                      {availableSizes.map((size, index) => (
                        <Paper
                          key={index}
                          sx={{ 
                            p: 2, 
                            border: '1px solid #e0e0e0',
                            borderRadius: 2
                          }}
                        >
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs>
                              {type && commonSizes[type] ? (
                                <FormControl fullWidth size={isMobile ? "small" : "medium"}>
                                  <InputLabel>Tamanho {index + 1}</InputLabel>
                                  <Select
                                    value={size}
                                    onChange={(e) => handleSizeChange(index, e.target.value)}
                                    label={`Tamanho ${index + 1}`}
                                  >
                                    {commonSizes[type].map((option) => (
                                      <MenuItem 
                                        key={option} 
                                        value={option}
                                        disabled={availableSizes.includes(option) && size !== option}
                                      >
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              ) : (
                                <TextField
                                  label={`Tamanho ${index + 1}`}
                                  value={size}
                                  onChange={(e) => handleSizeChange(index, e.target.value)}
                                  fullWidth
                                  required
                                  size={isMobile ? "small" : "medium"}
                                />
                              )}
                            </Grid>
                            {index > 0 && (
                              <Grid item>
                                <Tooltip title="Remover tamanho">
                                  <IconButton
                                    onClick={() => handleRemoveSize(index)}
                                    size="small"
                                    sx={{ 
                                      bgcolor: '#ff5252',
                                      color: '#fff',
                                      '&:hover': {
                                        bgcolor: '#ff1744'
                                      }
                                    }}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            )}
                          </Grid>
                        </Paper>
                      ))}
                    </Stack>
                  </Box>

                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    sx={{ 
                      mt: 3,
                      bgcolor: '#6A438B',
                      '&:hover': {
                        bgcolor: '#5a3777'
                      }
                    }}
                  >
                    {isSubmitting ? 'Cadastrando...' : 'Cadastrar Item'}
                  </Button>
                </Stack>
              </Box>
            </Paper>
          </Grid>

          {/* Seção de Imagem e Descrição */}
          <Grid item xs={12} md={6}>
            <Paper 
              elevation={0}
              sx={{ 
                p: 3, 
                borderRadius: 2,
                border: '1px solid #e0e0e0',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Box
                component="img"
                src="/assets/epi_iboard.png"
                alt="Uniformes e EPIs"
                sx={{
                  width: '80%',
                  height: 'auto',
                  maxWidth: 400,
                  borderRadius: 2
                }}
              />
              
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h6" gutterBottom color="primary">
                  Gestão de Uniformes e EPIs
                </Typography>
                <Typography variant="body1" color="text.secondary" paragraph>
                  Cadastre uniformes e EPIs de forma fácil e rápida, incluindo informações detalhadas sobre tamanhos disponíveis e tipos de equipamentos.
                </Typography>
                <Stack direction="row" spacing={1} justifyContent="center">
                  <Chip 
                    icon={<UniformIcon />} 
                    label="Uniformes" 
                    color="primary" 
                    variant="outlined" 
                  />
                  <Chip 
                    icon={<EpiIcon />} 
                    label="EPIs" 
                    color="primary" 
                    variant="outlined" 
                  />
                </Stack>
              </Box>
            </Paper>
          </Grid>
        </>
      )}

      {tabValue === 1 && (
        <Grid item xs={12}>
          {renderInventoryTab()}
        </Grid>
      )}

      {tabValue === 2 && (
        <Grid item xs={12}>
          {renderRequestsTab()}
        </Grid>
      )}

      {/* Dialog para Gerenciar Solicitação */}
      <Dialog open={openResponseDialog} onClose={handleCloseResponseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          Gerenciar Solicitação
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                value={responseData.status}
                onChange={(e) => setResponseData(prev => ({ ...prev, status: e.target.value }))}
                label="Status"
              >
                <MenuItem value="pendente">Pendente</MenuItem>
                <MenuItem value="aprovado">Aprovado</MenuItem>
                <MenuItem value="negado">Negado</MenuItem>
              </Select>
            </FormControl>

            {responseData.status === 'negado' && (
              <TextField
                label="Justificativa"
                value={responseData.justificativa}
                onChange={(e) => setResponseData(prev => ({ ...prev, justificativa: e.target.value }))}
                multiline
                rows={3}
                fullWidth
              />
            )}

            {responseData.status === 'aprovado' && (
              <TextField
                label="Data de Entrega"
                type="date"
                value={responseData.dataEntrega}
                onChange={(e) => setResponseData(prev => ({ ...prev, dataEntrega: e.target.value }))}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            )}

            <TextField
              label="Observações"
              value={responseData.observacoes}
              onChange={(e) => setResponseData(prev => ({ ...prev, observacoes: e.target.value }))}
              multiline
              rows={3}
              fullWidth
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseResponseDialog} sx={{ color: 'text.secondary' }}>
            Cancelar
          </Button>
          <Button 
            onClick={handleResponseSubmit}
            variant="contained"
            sx={{ 
              bgcolor: '#6A438B',
              '&:hover': {
                bgcolor: '#5a3777'
              }
            }}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default UniformsEpiCreator;
