import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, TextField, MobileStepper, Box, Typography, Tabs, Tab, Modal } from '@mui/material';
import { CameraAlt, Close } from '@mui/icons-material';
import axios from 'axios';
import JustificationList from './JustificationList';
import { useTheme } from '@mui/material/styles';

const JustificationForm = () => {
  const theme = useTheme(); // Obtém o tema atual
  const [activeStep, setActiveStep] = useState(0);
  const [files, setFiles] = useState([]);
  const [imagePreview, setImagePreview] = useState([]);
  const [observations, setObservations] = useState('');
  const [userData, setUserData] = useState({ nome: '', cpf: '', email: '' });
  const [startDateTime, setStartDateTime] = useState(new Date().toISOString());
  const [endDateTime, setEndDateTime] = useState(new Date().toISOString());
  const [justifications, setJustifications] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    fetchJustifications();

    const userId = JSON.parse(localStorage.getItem('userId'));
    if (userId) {
      axios.get(`/users/${userId}`)
        .then(response => setUserData({ ...response.data, userId }))
        .catch(error => console.error('Erro ao buscar dados do usuário:', error));
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getImageUrl = (filename) => {
    // Se for uma URL base64 (preview), retorna ela mesma
    if (filename.startsWith('data:')) {
      return filename;
    }
    // Se for um nome de arquivo do servidor, constrói a URL completa
    return `/justifications/images/${filename}`;
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);

    // Cria previews das imagens selecionadas
    const previews = selectedFiles.map(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onloadend = () => resolve({
          preview: reader.result,
          name: file.name
        });
      });
    });

    Promise.all(previews).then(images => setImagePreview(images));
  };

  const handleSubmit = () => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      toast.error('Usuário não identificado. Por favor, faça login novamente.');
      return;
    }

    const formData = new FormData();
    formData.append('nome', userData.nome);
    formData.append('cpf', userData.cpf);
    formData.append('email', userData.email);
    formData.append('startDateTime', startDateTime);
    formData.append('endDateTime', endDateTime);
    formData.append('observations', observations);
    formData.append('userId', userId);
    files.forEach(file => formData.append('image', file));

    axios.post('/justifications', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'userId': userId
      }
    })
    .then(response => {
      toast.success('Justificativa enviada com sucesso!');
      fetchJustifications();
      setFiles([]);
      setImagePreview([]);
      setObservations('');
      setActiveStep(0);
      setTabIndex(0);
    })
    .catch(error => {
      console.error('Erro ao enviar justificativa:', error);
      toast.error('Erro ao enviar justificativa. Por favor, tente novamente.');
    });
  };

  const fetchJustifications = () => {
    axios.get('/justifications')
      .then(response => {
        setJustifications(response.data);
      })
      .catch(error => {
        console.error('Erro ao buscar justificativas:', error);
      });


  };

  const handleOpenModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setModalOpen(false);
  };

  return (
    <Box>
      <ToastContainer />
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        variant="fullWidth"
        sx={{
          backgroundColor: theme.palette.primary.main, // Cor do tema
          borderBottom: '1px solid #ddd',
        }}
      >
        <Tab
          label="Lista de Justificativas"
          sx={{
            textTransform: 'none',
            minWidth: 72,
            fontWeight: 'bold',
            color: theme.palette.secondary.main, // Cor do tema
            '&.Mui-selected': {
              color: '#F4F5FA', // Cor do texto ao selecionar
              backgroundColor: theme.palette.secondary.main, // Cor de fundo ao selecionar
              borderRadius: '8px 8px 0 0',
            },
            '&:hover': {
              color: '#F4F5FA', // Cor de hover
              opacity: 1,
            },
          }}
        />
        <Tab
          label="Nova Justificativa"
          sx={{
            textTransform: 'none',
            minWidth: 72,
            fontWeight: 'bold',
            color: theme.palette.secondary.main, // Cor do tema
            '&.Mui-selected': {
              color: '#F4F5FA', // Cor do texto ao selecionar
              backgroundColor: theme.palette.secondary.main, // Cor de fundo ao selecionar
              borderRadius: '8px 8px 0 0',
            },
            '&:hover': {
              color: '#F4F5FA', // Cor de hover
              opacity: 1,
            },
          }}
        />
      </Tabs>
      {tabIndex === 0 && (
        <JustificationList justifications={justifications} />
      )}
      {tabIndex === 1 && (
        <Box sx={{ width: '100%', p: 3 }}>
          {activeStep === 0 && (
            <div>
              <Typography variant="h6" gutterBottom>
                Passo 1: Capturar ou Anexar Imagem
              </Typography>
              <label htmlFor="fileInput" style={{ cursor: 'pointer', display: 'block', textAlign: 'center' }}>
                <input
                  type="file"
                  id="fileInput"
                  name="image"
                  multiple
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                  <CameraAlt sx={{ fontSize: 50, color: theme.palette.secondary.main }} />
                  <Typography align="center">Clique aqui para tirar fotos ou selecionar exames solicitados.</Typography>
                </Box>
              </label>
              <Box className="image-preview" sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', mt: 2 }}>
                {imagePreview.map((image, index) => (
                  <Box 
                    key={index} 
                    sx={{ 
                      m: 1, 
                      cursor: 'pointer',
                      '&:hover': {
                        opacity: 0.8
                      }
                    }}
                    onClick={() => handleOpenModal(image.preview || getImageUrl(image))}
                  >
                    <img 
                      src={image.preview || getImageUrl(image)}
                      alt={`Preview ${index}`} 
                      style={{ 
                        width: '100px', 
                        height: '100px', 
                        objectFit: 'cover',
                        borderRadius: '4px'
                      }} 
                    />
                    <Typography variant="caption" sx={{ mt: 1, textAlign: 'center', display: 'block' }}>
                      {image.name || `Imagem ${index + 1}`}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </div>
          )}
          {activeStep === 1 && (
            <div>
              <Typography variant="h6" gutterBottom>
                Passo 2: Adicionar Observações e Período
              </Typography>
              <TextField
                label="Observações"
                variant="outlined"
                fullWidth
                multiline
                rows={4} // Permite múltiplas linhas
                value={observations}
                onChange={(e) => setObservations(e.target.value)}
              />
              <TextField
                label="Data e Hora Inicial"
                type="datetime-local"
                variant="outlined"
                fullWidth
                value={startDateTime}
                onChange={(e) => setStartDateTime(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ marginTop: '16px' }}
              />
              <TextField
                label="Data e Hora Final"
                type="datetime-local"
                variant="outlined"
                fullWidth
                value={endDateTime}
                onChange={(e) => setEndDateTime(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ marginTop: '16px' }}
              />
            </div>
          )}
          <MobileStepper
            variant="dots"
            steps={2}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button size="small" onClick={activeStep === 1 ? handleSubmit : handleNext}>
                {activeStep === 1 ? 'Enviar' : 'Próximo'}
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                Voltar
              </Button>
            }
          />
        </Box>
      )}

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="image-modal"
        aria-describedby="image-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 2,
          maxWidth: '90vw',
          maxHeight: '90vh',
          outline: 'none',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <Button 
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'grey.500',
              zIndex: 1
            }}
          >
            <Close />
          </Button>
          <Box sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden'
          }}>
            <img
              src={selectedImage}
              alt="Imagem ampliada"
              style={{
                maxWidth: '100%',
                maxHeight: 'calc(90vh - 48px)',
                objectFit: 'contain'
              }}
              onError={(e) => {
                console.error('Erro ao carregar imagem:', e);
                toast.error('Erro ao carregar imagem');
              }}
            />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default JustificationForm;