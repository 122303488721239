import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
  useTheme,
  Paper,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  Stack,
} from '@mui/material';
import { 
  LocalMall as UniformIcon, 
  Security as EpiIcon,
  Edit as EditIcon,
  Info as InfoIcon
} from '@mui/icons-material';
import { toast } from 'react-toastify';
import moment from 'moment';

const UniformsEpiDashboard = () => {
  const [uniformsEpi, setUniformsEpi] = useState([]);
  const [userResponses, setUserResponses] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [formData, setFormData] = useState({
    size: '',
    quantidade: 1,
    observacoes: ''
  });
  const theme = useTheme();
  const userId = localStorage.getItem('userId');

  // Função auxiliar para processar os tamanhos disponíveis
  const processAvailableSizes = (sizes) => {
    if (!sizes) return [];
    if (Array.isArray(sizes)) return sizes;
    if (typeof sizes === 'string') return sizes.split(',').map(size => size.trim());
    return [];
  };

  // Buscar uniformes/EPIs e respostas do usuário
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [uniformsResponse, responsesResponse] = await Promise.all([
          axios.get('/uniforms-epi'),
          axios.get('/uniforms-epi/responses')
        ]);

        setUniformsEpi(uniformsResponse.data);
        // Filtrar apenas as respostas do usuário atual
        const userResps = responsesResponse.data.filter(resp => resp.userId === parseInt(userId));
        setUserResponses(userResps);
        
        // Inicializar selectedSizes com as respostas existentes
        const sizeMap = {};
        userResps.forEach(resp => {
          sizeMap[resp.uniformEpiId] = {
            size: resp.size,
            responseId: resp.id,
            status: resp.status,
            quantidade: resp.quantidade,
            observacoes: resp.observacoes,
            dataSolicitacao: resp.dataSolicitacao,
            dataEntrega: resp.dataEntrega,
            justificativa: resp.justificativa
          };
        });
        setSelectedSizes(sizeMap);
      } catch (error) {
        console.error('Erro ao carregar dados:', error);
        toast.error('Erro ao carregar uniformes e EPIs');
      }
    };

    fetchData();
  }, [userId]);

  const handleOpenDialog = (item) => {
    setSelectedItem(item);
    const existingResponse = selectedSizes[item.id];
    setFormData({
      size: existingResponse?.size || '',
      quantidade: existingResponse?.quantidade || 1,
      observacoes: existingResponse?.observacoes || ''
    });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedItem(null);
    setFormData({
      size: '',
      quantidade: 1,
      observacoes: ''
    });
  };

  const handleSubmit = async () => {
    try {
      const existingResponse = selectedSizes[selectedItem.id];

      const data = {
        size: formData.size,
        quantidade: formData.quantidade,
        observacoes: formData.observacoes,
        status: 'pendente'
      };

      if (existingResponse?.responseId) {
        // Atualizar resposta existente
        await axios.put(`/uniforms-epi/responses/${existingResponse.responseId}`, data);
        
        setSelectedSizes(prev => ({
          ...prev,
          [selectedItem.id]: {
            ...prev[selectedItem.id],
            ...data,
            responseId: existingResponse.responseId
          }
        }));
        
        toast.success('Solicitação atualizada com sucesso!');
      } else {
        // Criar nova resposta
        const response = await axios.post('/uniforms-epi/responses', {
          ...data,
          uniformEpiId: selectedItem.id,
          userId: parseInt(userId)
        });

        setSelectedSizes(prev => ({
          ...prev,
          [selectedItem.id]: {
            ...data,
            responseId: response.data.id
          }
        }));
        
        toast.success('Solicitação enviada com sucesso!');
      }

      handleCloseDialog();
    } catch (error) {
      console.error('Erro ao processar solicitação:', error);
      toast.error('Erro ao processar solicitação');
    }
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'aprovado':
        return 'success';
      case 'pendente':
        return 'warning';
      case 'negado':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={3}>
          {uniformsEpi.map((item) => {
            const availableSizes = processAvailableSizes(item.availableSizes);
            const response = selectedSizes[item.id];
            
            return (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <Card 
                  elevation={0}
                  sx={{ 
                    height: '100%',
                    border: '1px solid #e0e0e0',
                    borderRadius: 2,
                    transition: 'transform 0.2s',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
                    }
                  }}
                >
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      {item.type?.includes('EPI') ? (
                        <EpiIcon sx={{ color: '#6A438B', mr: 1 }} />
                      ) : (
                        <UniformIcon sx={{ color: '#6A438B', mr: 1 }} />
                      )}
                      <Typography variant="h6" component="div" sx={{ color: '#6A438B', flex: 1 }}>
                        {item.name}
                      </Typography>
                      {response && (
                        <InfoIcon 
                          sx={{ 
                            color: 'action.disabled',
                            cursor: 'help'
                          }}
                          titleAccess={`Solicitado em: ${moment(response.dataSolicitacao).format('DD/MM/YYYY')}`}
                        />
                      )}
                    </Box>

                    <Typography color="textSecondary" gutterBottom>
                      {item.type}
                    </Typography>

                    {response?.status && (
                      <Box sx={{ mb: 2 }}>
                        <Stack direction="row" spacing={1}>
                          <Chip 
                            label={response.status}
                            color={getStatusColor(response.status)}
                            size="small"
                          />
                          {response.quantidade > 1 && (
                            <Chip 
                              label={`Qtd: ${response.quantidade}`}
                              size="small"
                              variant="outlined"
                            />
                          )}
                        </Stack>
                      </Box>
                    )}

                    {response?.status === 'aprovado' ? (
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="body2" color="success.main">
                          Aprovado - Tamanho: {response.size}
                          {response.dataEntrega && (
                            <><br />Data de entrega: {moment(response.dataEntrega).format('DD/MM/YYYY')}</>
                          )}
                        </Typography>
                      </Box>
                    ) : (
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() => handleOpenDialog(item)}
                        startIcon={response ? <EditIcon /> : null}
                        sx={{ 
                          mt: 2,
                          bgcolor: '#6A438B',
                          '&:hover': {
                            bgcolor: '#5a3777'
                          }
                        }}
                      >
                        {response ? 'Atualizar Solicitação' : 'Solicitar'}
                      </Button>
                    )}

                    {response?.justificativa && (
                      <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                        Justificativa: {response.justificativa}
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          {selectedItem?.type?.includes('EPI') ? (
            <EpiIcon sx={{ verticalAlign: 'middle', mr: 1, color: '#6A438B' }} />
          ) : (
            <UniformIcon sx={{ verticalAlign: 'middle', mr: 1, color: '#6A438B' }} />
          )}
          {selectedItem?.name}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Tamanho</InputLabel>
              <Select
                value={formData.size}
                onChange={(e) => setFormData(prev => ({ ...prev, size: e.target.value }))}
                label="Tamanho"
              >
                {selectedItem && processAvailableSizes(selectedItem.availableSizes).map((size) => (
                  <MenuItem key={size} value={size}>
                    {size}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Quantidade"
              type="number"
              value={formData.quantidade}
              onChange={(e) => setFormData(prev => ({ 
                ...prev, 
                quantidade: Math.max(1, parseInt(e.target.value) || 1)
              }))}
              InputProps={{ inputProps: { min: 1 } }}
              fullWidth
            />

            <TextField
              label="Observações"
              multiline
              rows={3}
              value={formData.observacoes}
              onChange={(e) => setFormData(prev => ({ ...prev, observacoes: e.target.value }))}
              fullWidth
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} sx={{ color: 'text.secondary' }}>
            Cancelar
          </Button>
          <Button 
            onClick={handleSubmit}
            variant="contained"
            sx={{ 
              bgcolor: '#6A438B',
              '&:hover': {
                bgcolor: '#5a3777'
              }
            }}
          >
            {selectedSizes[selectedItem?.id] ? 'Atualizar' : 'Solicitar'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UniformsEpiDashboard;
