import React, { useState, useEffect } from 'react';
import { TextField, Menu, MenuItem, IconButton, Modal, Box, Typography, List, ListItem, ListItemText } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';

const JustificationList = () => {
  const [justifications, setJustifications] = useState([]);
  const [filter, setFilter] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedJustification, setSelectedJustification] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    // Fetch justifications from API
    axios.get('/justifications')
      .then(response => setJustifications(response.data))
      .catch(error => console.error('Erro ao buscar justificativas:', error));
  }, []);

  const handleMenuClick = (event, justification) => {
    setAnchorEl(event.currentTarget);
    setSelectedJustification(justification);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleView = () => {
    setOpenModal(true);
    handleMenuClose();
  };

  const handleDelete = () => {
    // Implement delete functionality
    handleMenuClose();
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setImageError(false);
  };

  const filteredJustifications = justifications.filter(justification =>
    justification.nome.toLowerCase().includes(filter.toLowerCase()) ||
    justification.email.toLowerCase().includes(filter.toLowerCase()) ||
    justification.cpf.includes(filter)
  );

  const getImageUrl = (imagePath) => {
    if (!imagePath) return null;
    if (imagePath.startsWith('data:') || imagePath.startsWith('http')) {
      return imagePath;
    }
    
    // Remover qualquer caminho absoluto que possa existir
    const parts = imagePath.split(/[\\/]/);
    const userId = parts[parts.length - 2];
    const filename = parts[parts.length - 1];
    
    // Garantir que a URL comece com /api
    const imageUrl = `/api/justifications/images/${userId}/${filename}`;
    console.log('URL da imagem:', imageUrl);
    return imageUrl;
  };

  return (
    <div>
 <Typography variant="h6">Atestados e Justificativas dos colaboradores</Typography>


      <TextField
        label="Pesquisar"
        variant="outlined"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        fullWidth
      />
      <List>
        {filteredJustifications.map(justification => (
          <ListItem key={justification.id} button onClick={(e) => handleMenuClick(e, justification)}>
            <ListItemText
              primary={`${justification.nome} ID: ${justification.userId}`}
              secondary={`Email: ${justification.email} | De: ${new Date(justification.startDateTime).toLocaleString()} Até:${new Date(justification.endDateTime).toLocaleString()}`}
            />
            <IconButton onClick={(e) => handleMenuClick(e, justification)}>
              <MoreVertIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleView}>Visualizar</MenuItem>
        <MenuItem onClick={handleDelete}>Excluir</MenuItem>
      </Menu>
      <Modal
        open={openModal}
        onClose={handleModalClose}
      >
        <Box sx={{ 
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 2,
          maxWidth: '90vw',
          maxHeight: '90vh',
          overflow: 'auto'
        }}>
          {selectedJustification && (
            <div>
              <Typography variant="h6">{selectedJustification.nome}</Typography>
              <Typography variant="body1"><strong>User ID:</strong> {selectedJustification.userId}</Typography>
              <Typography variant="body1"><strong>Email:</strong> {selectedJustification.email}</Typography>
              <Typography variant="body1"><strong>CPF:</strong> {selectedJustification.cpf}</Typography>
              <Typography variant="body1"><strong>Data Inicial:</strong> {new Date(selectedJustification.startDateTime).toLocaleString()}</Typography>
              <Typography variant="body1"><strong>Data Final:</strong> {new Date(selectedJustification.endDateTime).toLocaleString()}</Typography>
              <Typography variant="body1"><strong>Observações:</strong> {selectedJustification.observations}</Typography>
              <Box sx={{
                mt: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '& img': {
                  maxWidth: '100%',
                  maxHeight: 'calc(90vh - 300px)',
                  objectFit: 'contain'
                }
              }}>
                {imageError ? (
                  <Typography color="error" sx={{ mt: 2 }}>
                    Não foi possível carregar a imagem
                  </Typography>
                ) : (
                  <img 
                    src={getImageUrl(selectedJustification.imagePath)} 
                    alt="Justificativa"
                    onError={(e) => {
                      console.error('Erro ao carregar imagem:', e);
                      setImageError(true);
                    }}
                  />
                )}
              </Box>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default JustificationList;