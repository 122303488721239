import React, { useEffect, useState } from "react";
import axios from "axios";
import { CSVLink } from 'react-csv';
import { 
  Typography, 
  Button, 
  Card, 
  CardContent, 
  Grid, 
  CircularProgress, 
  Tooltip, 
  Alert, 
  Snackbar, 
  Fade, 
  Grow,
  Box,
  Divider,
  useTheme
} from "@mui/material";
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import RunningWithErrorsOutlinedIcon from '@mui/icons-material/RunningWithErrorsOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import PollIcon from '@mui/icons-material/Poll';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PieRh from "./PieRh";
import TrendChart from "./TrendChart";
import ContractsChart from "./ContractsChart";
import SurveyDashboardResponse from "./SurveyDashboardResponse";
import DownloadIcon from '@mui/icons-material/Download';

const DashBoardAdm = () => {
  const theme = useTheme();
  const [userData, setUserData] = useState([]);
  const [totalCollaborators, setTotalCollaborators] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Buscar usuário atual
        const userId = localStorage.getItem("userId");
        if (!userId) {
          throw new Error("Usuário não encontrado. Por favor, faça login novamente.");
        }

        const response = await axios.get(`/users/${userId}`);
        const currentUserData = response.data;
        if (!currentUserData) {
          throw new Error("Dados do usuário não encontrados.");
        }

        // Buscar todos os usuários
        const responseAllUsers = await axios.get("/users");
        const allUsers = responseAllUsers.data;
        if (!Array.isArray(allUsers)) {
          throw new Error("Formato de dados inválido recebido da API.");
        }

        // Filtrar usuários da mesma empresa
        const users = allUsers.filter(user => 
          user.id_cnpj === currentUserData.id_cnpj && !user.cnpj
        );

        // Calcular métricas
        let completed = 0;
        let pending = 0;

        users.forEach(user => {
          const isComplete = user.rg && user.uploadsPath && user.uploadsPathAso;
          if (isComplete) {
            completed++;
          } else {
            pending++;
          }
        });

        // Atualizar estado
        setCompletedCount(completed);
        setPendingCount(pending);
        setTotalCollaborators(users.length);
        setUserData(users);
        
      } catch (error) {
        console.error("Erro ao obter dados dos usuários:", error);
        setError(error.message || "Erro ao carregar dados. Tente novamente mais tarde.");
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
        setTimeout(() => setShowContent(true), 100);
      }
    };

    fetchUserData();
  }, []);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const generateCSV = () => {
    const headers = ["Nome", "Email", "Telefone", "CPF", "RG", "CEP", "Rua", "Numero", "Complemento", "Cidade", "Estado", "Estado Civil", "Genero", "Dependentes", "Data Nascimento", "UploadsPath", "Data", "CNPJ", "ID CNPJ", "UploadsPathAso", "Docs", "Pass"];
    const data = userData.map(user => Object.values(user));
    return [headers, ...data];
  };

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <CircularProgress />
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <Alert severity="error" sx={{ width: '80%', maxWidth: 600 }}>
          {error}
          <Button color="inherit" size="small" onClick={() => window.location.reload()}>
            Tentar Novamente
          </Button>
        </Alert>
      </Grid>
    );
  }

  return (
    <Fade in={showContent} timeout={1000}>
      <Box sx={{ p: 2 }}>
        {/* Seção de Métricas Principais */}
        <Grid container spacing={2} sx={{ mb: 2 }}>
          {[
            {
              title: "Colaboradores",
              value: totalCollaborators,
              icon: <GroupAddOutlinedIcon />,
              color: '#2E96FF',
              tooltip: "Total de colaboradores cadastrados no sistema"
            },
            {
              title: "Processos Pendentes",
              value: pendingCount,
              icon: <RunningWithErrorsOutlinedIcon />,
              color: '#B800D8',
              tooltip: "Processos que ainda não foram concluídos"
            },
            {
              title: "Processos concluídos",
              value: completedCount,
              icon: <MedicalInformationOutlinedIcon />,
              color: '#6A438B',
              tooltip: "Processos concluídos com sucesso"
            },
            {
              title: "Relatórios",
              value: null,
              icon: <AssignmentOutlinedIcon />,
              color: '#f4d318',
              tooltip: "Exportar relatórios"
            }
          ].map((item, index) => (
            <Grow key={index} in={showContent} timeout={500 + (index * 100)}>
              <Grid item xs={12} sm={6} md={3}>
                <Tooltip title={item.tooltip}>
                  <Card sx={{ 
                    boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
                    borderRadius: '12px',
                    background: '#ffffff',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 24px 48px -4px'
                    }
                  }}>
                    <CardContent sx={{ p: 2 }}>     
                      <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }} spacing={2}>
                        <Stack spacing={0.5}>
                          <Typography 
                            color="text.secondary" 
                            sx={{ 
                              fontSize: '0.875rem',
                              fontWeight: 600,
                              textTransform: 'none',
                              letterSpacing: '0.5px'
                            }}
                          >
                            {item.title}
                          </Typography>
                          {item.value !== null ? (
                            <Typography 
                              variant="h4" 
                              sx={{ 
                                color: item.color,
                                fontWeight: 600,
                                letterSpacing: '0.5px'
                              }}
                            >
                              {item.value}
                            </Typography>
                          ) : (
                            <CSVLink 
                              data={generateCSV()} 
                              filename={"dados_dashboard.csv"} 
                              target="_blank"
                              style={{ textDecoration: 'none' }}
                            >
                              <Box
                                sx={{
                                  background: 'linear-gradient(45deg, #6a438b 30%, #B800D8 90%)',
                                  color: 'white',
                                  py: 0.75,
                                  px: 1.5,
                                  borderRadius: '6px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                  fontSize: '0.875rem',
                                  fontWeight: 600,
                                  boxShadow: '0 2px 4px rgba(106, 67, 139, .3)',
                                  transition: 'all 0.2s ease-in-out',
                                  '&:hover': {
                                    transform: 'translateY(-2px)',
                                    boxShadow: '0 4px 8px rgba(106, 67, 139, .3)',
                                    background: 'linear-gradient(45deg, #B800D8 30%, #6a438b 90%)'
                                  }
                                }}
                              >
                                <DownloadIcon sx={{ fontSize: 18 }} />
                                Exportar
                              </Box>
                            </CSVLink>
                          )}
                        </Stack>
                        <Avatar sx={{ 
                          bgcolor: `${item.color}15`,
                          height: '42px',
                          width: '42px',
                          '& .MuiSvgIcon-root': {
                            color: item.color,
                            fontSize: '1.3rem'
                          },
                          transition: 'transform 0.3s',
                          '&:hover': {
                            transform: 'rotate(10deg)'
                          }
                        }}>
                          {item.icon}
                        </Avatar>
                      </Stack> 
                    </CardContent>
                  </Card>
                </Tooltip>
              </Grid>
            </Grow>
          ))}
        </Grid>

        {/* Seção de Gráficos Principais */}
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} md={7}>
            <Grow in={showContent} timeout={900}>
              <Card sx={{ 
                boxShadow: 3, 
                borderRadius: 2,
                height: '100%',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: 4
                }
              }}>
                <CardContent sx={{ p: 2 }}>
                  <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
                    <EqualizerIcon color="primary" />
                    <Typography variant="h6">Tendência de Processos</Typography>
                  </Stack>
                  <Divider sx={{ mb: 1 }} />
                  <TrendChart data={userData} />
                </CardContent>
              </Card>
            </Grow>
          </Grid>

          <Grid item xs={12} md={5}>
            <Grow in={showContent} timeout={1000}>
              <Card sx={{ 
                boxShadow: 3, 
                borderRadius: 2,
                height: '100%',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: 4
                }
              }}>
                <CardContent sx={{ p: 2 }}>
                  <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
                    <PollIcon color="primary" />
                    <Typography variant="h6">Visão Geral de Colaboradores</Typography>
                  </Stack>
                  <Divider sx={{ mb: 1 }} />
                  <PieRh />
                </CardContent>
              </Card>
            </Grow>
          </Grid>
        </Grid>

        {/* Seção de Documentos */}
        <Grow in={showContent} timeout={2200}>
          <Card sx={{ 
            boxShadow: 5, 
            borderRadius: 3, 
            mb: 4,
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              transform: 'translateY(-5px)',
              boxShadow: 8
            }
          }}>
            <CardContent>
              <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 2 }}>
                <AssignmentTurnedInIcon color="primary" />
                <Typography variant="h6">Análise de assinatura de documentos</Typography>
              </Stack>
              <Divider sx={{ mb: 2 }} />
              <ContractsChart />
            </CardContent>
          </Card>
        </Grow>

        {/* Seção de Pesquisa de Clima */}
        <Grow in={showContent} timeout={2400}>
          <Card sx={{ 
            boxShadow: 5, 
            borderRadius: 3, 
            mb: 4,
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              transform: 'translateY(-5px)',
              boxShadow: 8
            }
          }}>
            <CardContent>
              <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 2 }}>
                <PollIcon color="primary" />
                <Typography variant="h6">Análise de Pesquisas de Clima</Typography>
              </Stack>
              <Divider sx={{ mb: 2 }} />
              <Box sx={{ minHeight: 400 }}>
                <SurveyDashboardResponse />
              </Box>
            </CardContent>
          </Card>
        </Grow>

        <Snackbar 
          open={snackbarOpen} 
          autoHideDuration={6000} 
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      </Box>
    </Fade>
  );
};

export default DashBoardAdm;
