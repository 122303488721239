import React, { useEffect, useState } from "react";
import SideBarRh from "../components/SideBarRh";
import "../styles/styles.css";
import Dashboard from "../components/DashBoards/DashBoard";
import { useTheme } from "@mui/material";
import { Box, Typography } from "@mui/material";

const DashboardRh = () => {
  const [userName, setUserName] = useState("");

  const theme = useTheme();

  useEffect(() => {
    const userId = localStorage.getItem("userId");

    fetch(`/api/users/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        const name = data.nome;
        setUserName(name);
      })
      .catch((error) => {
        console.error("Erro ao obter dados do usuário:", error);
      });
  }, []);

  return (
    <>
      <SideBarRh />
      <Box
        component="main"
        sx={{
          marginTop: 7,
          p: 3,
          [theme.breakpoints.down("sm")]: {
            marginTop: 7,
          },
        }}
      >
        <Box>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
          }}>
            <Typography 
              variant="body2" 
              sx={{            
                fontWeight: 500,
                color: 'text.secondary',
                letterSpacing: '0.5px',
                '& span': {
                  color: 'main',
                  fontWeight: 600
                }
              }}
            >
              Olá, <span>{userName || "visitante"}</span>
            </Typography>
          </Box>

          <Dashboard />
        </Box>
      </Box>
    </>
  );
};

export default DashboardRh;
