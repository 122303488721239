import React, { useState } from "react";
import { toast } from 'react-toastify';
import { 
  Box,
  IconButton, 
  Card, 
  Stack, 
  Typography, 
  Chip, 
  Button, 
  Dialog, 
  DialogContent, 
  DialogTitle, 
  DialogActions,
  Divider, 
  ImageList, 
  ImageListItem,
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip
} from "@mui/material";
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DescriptionIcon from '@mui/icons-material/Description';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';

const UserList = ({ users, onSendMessage, currentUser, onEdit, onDelete }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedUser, setSelectedUser] = useState(null);
  const [userImages, setUserImages] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedActionUser, setSelectedActionUser] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const handleSendMessage = (phoneNumber) => {
    const message = "Olá, gostaria de mais informações sobre o seu cadastro.";
    const encodedMessage = encodeURIComponent(message);
    window.open(`https://wa.me/55${phoneNumber.replace(/\D/g, '')}?text=${encodedMessage}`, '_blank');
  };

  const handleVerClick = (user) => {
    setSelectedUser(user);
  };

  const openLightbox = (index) => {
    setSelectedImageIndex(index);
    setLightboxOpen(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setUserImages([]);
  };

  const isCurrentUserValid = currentUser && currentUser.id_cnpj;

  const filteredUsers = isCurrentUserValid
    ? users.filter((user) => user.id_cnpj === currentUser.id_cnpj)
    : users;

  const handleMenuOpen = (event, user) => {
    setAnchorEl(event.currentTarget);
    setSelectedActionUser(user);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (action) => {
    if (!selectedActionUser) return;

    switch(action) {
      case 'view':
        handleVerClick(selectedActionUser);
        break;
      case 'edit':
        console.log("Chamando onEdit com:", selectedActionUser);
        if (onEdit) {
          onEdit(selectedActionUser);
        } else {
          console.error("Função onEdit não fornecida");
        }
        break;
      default:
        break;
    }
    handleMenuClose();
  };

  const handleVerDoc = async (user) => {
    try {
      if (user.uploadsPath) {
        const images = user.uploadsPath.split(',').map(path => path.trim());
        setUserImages(images);
      } else {
        toast.warn("Usuário não possui documentos cadastrados");
      }
    } catch (error) {
      console.error("Erro ao obter imagens do usuário:", error);
      toast.error("Erro ao carregar documentos");
    }
  };

  const handleVerAso = async (user) => {
    try {
      if (user.uploadsPathAso) {
        const images = user.uploadsPathAso.split(',').map(path => path.trim());
        setUserImages(images);
      } else {
        toast.warn("Usuário não possui ASO cadastrado");
      }
    } catch (error) {
      console.error("Erro ao obter ASO do usuário:", error);
      toast.error("Erro ao carregar ASO");
    }
  };

  const handleDownloadExcel = async (user) => {
    try {
      console.log("Iniciando download do Excel para usuário:", user.iduser);
      toast.info("Preparando download...");

      const response = await axios.get(`/users/export/user/${user.iduser}`, {
        responseType: 'blob'
      });

      console.log("Resposta do servidor:", response);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `usuario_${user.nome}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

      toast.success("Download concluído com sucesso!");
    } catch (error) {
      console.error("Erro detalhado ao baixar dados:", error);
      console.error("Resposta do erro:", error.response);
      toast.error(error.response?.data?.message || "Erro ao gerar planilha");
    }
  };

  const handleDownloadFiles = async (user) => {
    try {
      console.log("Iniciando download dos arquivos para usuário:", user.iduser);
      toast.info("Preparando arquivos para download...");

      const response = await axios.get(`/users/download/files/${user.iduser}`, {
        responseType: 'blob'
      });

      console.log("Resposta do servidor:", response);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `arquivos_${user.nome}.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

      toast.success("Download dos arquivos concluído!");
    } catch (error) {
      console.error("Erro detalhado ao baixar arquivos:", error);
      console.error("Resposta do erro:", error.response);
      toast.error(error.response?.data?.message || "Erro ao baixar arquivos");
    }
  };

  const handleDeleteClick = (user) => {
    console.log("Iniciando exclusão do usuário:", user);
    setUserToDelete(user);
    setDeleteConfirmOpen(true);
    handleMenuClose();
  };

  const handleDeleteConfirm = () => {
    console.log("Confirmando exclusão do usuário:", userToDelete);
    if (userToDelete && onDelete) {
      try {
        onDelete(userToDelete);
        toast.success("Colaborador excluído com sucesso!");
      } catch (error) {
        console.error("Erro ao excluir colaborador:", error);
        toast.error("Erro ao excluir colaborador");
      }
    }
    setDeleteConfirmOpen(false);
    setUserToDelete(null);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setUserToDelete(null);
  };

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      {/* Cabeçalho da Lista */}
      <Card sx={{ 
        mb: 2,
        display: { xs: 'none', md: 'block' },
        backgroundColor: (theme) => theme.palette.background.neutral
      }}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ 
            px: 3,
            py: 2
          }}
        >
          <Typography variant="subtitle2" sx={{ width: '8%', color: 'text.secondary' }}>ID</Typography>
          <Typography variant="subtitle2" sx={{ width: '15%', color: 'text.secondary' }}>Status</Typography>
          <Typography variant="subtitle2" sx={{ width: '20%', color: 'text.secondary' }}>Nome</Typography>
          <Typography variant="subtitle2" sx={{ width: '22%', color: 'text.secondary' }}>E-mail</Typography>
          <Typography variant="subtitle2" sx={{ width: '15%', color: 'text.secondary' }}>Celular</Typography>
          <Typography variant="subtitle2" sx={{ width: '20%', color: 'text.secondary' }}>Ações</Typography>
        </Stack>
      </Card>

      {/* Lista de Usuários */}
      <Stack spacing={2}>
        {filteredUsers.map((user, index) => (
          <Card
            key={user.iduser || user.id || index}
            sx={{
              p: 2,
              transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: (theme) => theme.shadows[4]
              }
            }}
          >
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={{ xs: 2, md: 2 }}
              alignItems={{ xs: 'flex-start', md: 'center' }}
            >
              {/* Mobile: Cabeçalho com Nome e Status */}
              <Box sx={{ display: { xs: 'flex', md: 'none' }, width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>{user.nome}</Typography>
                <Box sx={{ minWidth: 120 }}>
                  {user.rg === null ? (
                    <Chip 
                      label="Cadastro Pendente" 
                      color="error" 
                      size="small"
                      icon={<ErrorOutlineOutlinedIcon />}
                      sx={{ width: '100%' }}
                    />
                  ) : user.uploadsPath === null ? (
                    <Chip 
                      label="Doc pendentes" 
                      color="warning" 
                      size="small"
                      icon={<ErrorOutlineOutlinedIcon />}
                      sx={{ width: '100%' }}
                    />
                  ) : user.uploadsPathAso === null ? (
                    <Chip 
                      label="ASO Pendente" 
                      color="info" 
                      size="small"
                      icon={<ErrorOutlineOutlinedIcon />}
                      sx={{ width: '100%' }}
                    />
                  ) : (
                    <Chip 
                      label="Processo concluído" 
                      color="success" 
                      size="small"
                      icon={<CheckCircleOutlineOutlinedIcon />}
                      sx={{ width: '100%' }}
                    />
                  )}
                </Box>
              </Box>

              {/* Desktop: Informações em linha */}
              <Box sx={{ display: { xs: 'none', md: 'flex' }, width: '8%' }}>
                <Typography variant="body2">{user.iduser}</Typography>
              </Box>

              {/* Status */}
              <Box sx={{ 
                display: { xs: 'none', md: 'flex' }, 
                width: '15%',
                justifyContent: 'flex-start'
              }}>
                {user.rg === null ? (
                  <Chip 
                    label="Pendente" 
                    color="error" 
                    size="small"
                    icon={<ErrorOutlineOutlinedIcon />}
                    sx={{ minWidth: 100 }}
                  />
                ) : user.uploadsPath === null ? (
                  <Chip 
                    label="Docs" 
                    color="warning" 
                    size="small"
                    icon={<ErrorOutlineOutlinedIcon />}
                    sx={{ minWidth: 100 }}
                  />
                ) : user.uploadsPathAso === null ? (
                  <Chip 
                    label="ASO" 
                    color="info" 
                    size="small"
                    icon={<ErrorOutlineOutlinedIcon />}
                    sx={{ minWidth: 100 }}
                  />
                ) : (
                  <Chip 
                    label="Concluído" 
                    color="success" 
                    size="small"
                    icon={<CheckCircleOutlineOutlinedIcon />}
                    sx={{ minWidth: 100 }}
                  />
                )}
              </Box>

              {/* Nome */}
              <Box sx={{ display: { xs: 'none', md: 'flex' }, width: '20%' }}>
                <Typography variant="body2">{user.nome}</Typography>
              </Box>

              {/* Email */}
              <Box sx={{ width: { xs: '100%', md: '22%' } }}>
                <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>{user.email}</Typography>
              </Box>

              {/* Celular com WhatsApp */}
              <Box sx={{ width: { xs: '100%', md: '15%' } }}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="body2">{user.fone}</Typography>
                  <IconButton
                    size="small"
                    onClick={() => handleSendMessage(user.fone)}
                    sx={{ 
                      color: 'success.main',
                      '&:hover': {
                        backgroundColor: 'success.lighter'
                      }
                    }}
                  >
                    <WhatsAppIcon fontSize="small" />
                  </IconButton>
                </Stack>
              </Box>

              {/* Botões de Ação */}
              <Stack 
                direction="row" 
                spacing={1}
                sx={{ 
                  width: { xs: '100%', md: '30%' },
                  justifyContent: { xs: 'space-between', md: 'flex-start' }
                }}
              >
                <Tooltip title="Visualizar Documentos">
                  <IconButton
                    size="small"
                    onClick={() => handleVerDoc(user)}
                    sx={{ 
                      color: 'success.main',
                      '&:hover': { backgroundColor: 'primary.lighter' }
                    }}
                  >
                    <FingerprintIcon fontSize="small" />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Visualizar ASO">
                  <IconButton
                    size="small"
                    onClick={() => handleVerAso(user)}
                    sx={{ 
                      color: '#6A438B',
                      '&:hover': { backgroundColor: 'info.lighter' }
                    }}
                  >
                    <LocalHospitalIcon fontSize="small" />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Baixar Dados (Excel)">
                  <IconButton
                    size="small"
                    onClick={() => handleDownloadExcel(user)}
                    sx={{ 
                      color: 'success.main',
                      '&:hover': { backgroundColor: 'success.lighter' }
                    }}
                  >
                    <DescriptionIcon fontSize="small" />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Baixar Todos os Arquivos">
                  <IconButton
                    size="small"
                    onClick={() => handleDownloadFiles(user)}
                    sx={{ 
                      color: 'warning.main',
                      '&:hover': { backgroundColor: 'warning.lighter' }
                    }}
                  >
                    <DownloadIcon fontSize="small" />
                  </IconButton>
                </Tooltip>

                <IconButton
                  size="small"
                  onClick={(event) => handleMenuOpen(event, user)}
                  sx={{ 
                    color: 'text.secondary',
                    '&:hover': { backgroundColor: 'action.hover' }
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Stack>
            </Stack>
          </Card>
        ))}
      </Stack>

      {/* Modais */}
      <Dialog 
        open={selectedUser !== null} 
        onClose={closeModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography component="div" variant="h6">
              Informações do Colaborador
            </Typography>
            <IconButton onClick={closeModal} size="small">
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          {selectedUser && (
            <Stack spacing={2}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2" color="text.secondary">ID</Typography>
                <Typography variant="body2">{selectedUser.iduser}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2" color="text.secondary">Nome</Typography>
                <Typography variant="body2">{selectedUser.nome}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2" color="text.secondary">CPF</Typography>
                <Typography variant="body2">{selectedUser.cpf}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2" color="text.secondary">RG</Typography>
                <Typography variant="body2">{selectedUser.rg}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2" color="text.secondary">Gênero</Typography>
                <Typography variant="body2">{selectedUser.genero}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2" color="text.secondary">Dependentes</Typography>
                <Typography variant="body2">{selectedUser.dependentes}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2" color="text.secondary">Data de Nascimento</Typography>
                <Typography variant="body2">{selectedUser.data_nascimento}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2" color="text.secondary">Data de Início</Typography>
                <Typography variant="body2">{selectedUser.data}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2" color="text.secondary">E-mail</Typography>
                <Typography variant="body2">{selectedUser.email}</Typography>
              </Stack>

              <Divider />
              
              <Typography variant="subtitle1" sx={{ mt: 2 }}>Endereço</Typography>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2" color="text.secondary">Rua</Typography>
                <Typography variant="body2">{selectedUser.rua}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2" color="text.secondary">Número</Typography>
                <Typography variant="body2">{selectedUser.numero}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2" color="text.secondary">Complemento</Typography>
                <Typography variant="body2">{selectedUser.complemento}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2" color="text.secondary">Cidade</Typography>
                <Typography variant="body2">{selectedUser.cidade}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2" color="text.secondary">Estado</Typography>
                <Typography variant="body2">{selectedUser.estado}</Typography>
              </Stack>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal de Documentos */}
      <Dialog 
        open={userImages.length > 0} 
        onClose={closeModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography component="div" variant="h6">
              Visualizar Arquivos
            </Typography>
            <IconButton onClick={closeModal} size="small">
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <ImageList cols={isMobile ? 1 : 2} gap={16}>
            {userImages.map((path, index) => (
              <ImageListItem 
                key={`image-${index}-${path}`}
                onClick={() => openLightbox(index)}
                sx={{ 
                  cursor: 'pointer',
                  '&:hover': { opacity: 0.8 }
                }}
              >
                <img
                  src={`/api/${path}`}
                  alt={`Documento ${index + 1}`}
                  loading="lazy"
                  style={{ 
                    width: '100%',
                    height: 'auto',
                    borderRadius: 8
                  }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal de Imagem Ampliada */}
      <Dialog
        open={lightboxOpen}
        onClose={() => setLightboxOpen(false)}
        maxWidth="xl"
        fullScreen={isMobile}
      >
        <DialogContent sx={{ p: 0 }}>
          <IconButton
            onClick={() => setLightboxOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.7)'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={`/api/${userImages[selectedImageIndex]}`}
            alt="Documento Ampliado"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain'
            }}
          />
        </DialogContent>
      </Dialog>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => handleAction('view')}>
          <ListItemIcon>
            <FindInPageOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Visualizar</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleAction('edit')}>
          <ListItemIcon>
            <EditOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Editar</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleDeleteClick(selectedActionUser)}>
          <ListItemIcon>
            <DeleteOutlineIcon fontSize="small" sx={{ color: 'error.main' }} />
          </ListItemIcon>
          <ListItemText sx={{ color: 'error.main' }}>Excluir</ListItemText>
        </MenuItem>
      </Menu>

      {/* Modal de Confirmação de Exclusão */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" component="div" sx={{ color: 'error.main' }}>
              Confirmar Exclusão
            </Typography>
            <IconButton onClick={handleDeleteCancel} size="small">
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Tem certeza que deseja excluir o colaborador{' '}
            <strong>{userToDelete?.nome}</strong>?
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            Esta ação não poderá ser desfeita.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleDeleteCancel}
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button 
            onClick={handleDeleteConfirm}
            variant="contained"
            color="error"
            startIcon={<DeleteOutlineIcon />}
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserList;
