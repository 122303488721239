import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell
} from 'recharts';
import { Box, Grid, Typography, CircularProgress, useTheme, useMediaQuery } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

const ContractsChart = () => {
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const COLORS = ['#6A438B', '#4caf50', '#ff9800', '#f44336'];

  useEffect(() => {
    const fetchContracts = async () => {
      try {
        const response = await axios.get('/contracts');
        console.log('Dados dos Documentos:', response.data);
        setContracts(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Erro ao buscar Documentos:', err);
        setError('Erro ao carregar dados dos Documentos');
        setLoading(false);
      }
    };

    fetchContracts();
  }, []);

  const getContractStats = () => {
    if (!contracts.length) return [];

    const stats = {
      assinados: contracts.filter(c => c.status === 'Assinado').length,
      pendentes: contracts.filter(c => c.status === 'Pendente').length,
      arquivados: contracts.filter(c => c.status === 'Arquivado').length
    };

    return [
      { name: 'Assinados', value: stats.assinados },
      { name: 'Pendentes', value: stats.pendentes },
      { name: 'Arquivados', value: stats.arquivados }
    ];
  };

  const getMonthlyStats = () => {
    if (!contracts.length) return [];

    const monthlyData = {};
    
    contracts.forEach(contract => {
      const month = moment(contract.createdAt).format('MMM/YY');
      if (!monthlyData[month]) {
        monthlyData[month] = { month, total: 0, assinados: 0, pendentes: 0 };
      }
      
      monthlyData[month].total++;
      
      if (contract.status === 'Assinado') {
        monthlyData[month].assinados++;
      } else if (contract.status === 'Pendente') {
        monthlyData[month].pendentes++;
      }
    });

    return Object.values(monthlyData).sort((a, b) => 
      moment(a.month, 'MMM/YY').diff(moment(b.month, 'MMM/YY'))
    );
  };

  const getSignerStats = () => {
    if (!contracts.length) return [];

    const signerData = contracts.reduce((acc, contract) => {
      const signedCount = contract.signers.filter(s => s.signed).length;
      const pendingCount = contract.signers.filter(s => !s.signed).length;
      
      return {
        signed: acc.signed + signedCount,
        pending: acc.pending + pendingCount
      };
    }, { signed: 0, pending: 0 });

    return [
      { name: 'Assinados', value: signerData.signed },
      { name: 'Pendentes', value: signerData.pending }
    ];
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 3 }}>
        <CircularProgress sx={{ color: '#6A438B' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <Grid container spacing={3}>
        {/* Gráfico de Status dos Documentos */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom sx={{ color: '#333', textAlign: 'center' }}>
            Status dos Documentos
          </Typography>
          <Box sx={{ height: isMobile ? 200 : 300 }}>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={getContractStats()}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={isMobile ? 80 : 100}
                  dataKey="value"
                  nameKey="name"
                  label={({ name, percent }) => 
                    `${name}: ${(percent * 100).toFixed(1)}%`
                  }
                >
                  {getContractStats().map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Grid>

        {/* Gráfico de Evolução Mensal */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom sx={{ color: '#333', textAlign: 'center' }}>
            Evolução Mensal
          </Typography>
          <Box sx={{ height: isMobile ? 200 : 300 }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={getMonthlyStats()}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="total" name="Total" fill="#6A438B" />
                <Bar dataKey="assinados" name="Assinados" fill="#4caf50" />
                <Bar dataKey="pendentes" name="Pendentes" fill="#ff9800" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Grid>

        {/* Gráfico de Status das Assinaturas */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom sx={{ color: '#333', textAlign: 'center' }}>
            Status das Assinaturas
          </Typography>
          <Box sx={{ height: isMobile ? 200 : 300 }}>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={getSignerStats()}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={isMobile ? 80 : 100}
                  dataKey="value"
                  nameKey="name"
                  label={({ name, percent }) => 
                    `${name}: ${(percent * 100).toFixed(1)}%`
                  }
                >
                  {getSignerStats().map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContractsChart; 