import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Typography,
  Box,
  CircularProgress,
  Card,
  CardContent,
  useTheme,
  useMediaQuery,
  Alert,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  Stack,
  InputAdornment,
  Grid,
  Divider,
  Paper,
  Chip,
  IconButton,
  Tooltip,

  Collapse,
} from '@mui/material';
import {
  Search as SearchIcon,
  Download as DownloadIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  PieChart as PieChartIcon,
  BarChart as BarChartIcon,
  TableChart as TableChartIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';
import { CSVLink } from 'react-csv';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ChartTooltip,
  Legend,
  ArcElement
);

const SurveyDashboardResponse = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [surveys, setSurveys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSurvey, setSelectedSurvey] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [chartData, setChartData] = useState(null);
  const [surveyStats, setSurveyStats] = useState(null);
  const [expandedQuestions, setExpandedQuestions] = useState({});
  const [chartType, setChartType] = useState('bar');
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem('userId');
        if (!userId) {
          throw new Error('Usuário não encontrado');
        }

        const userResponse = await axios.get(`/users/${userId}`);
        const surveyResponse = await axios.get('/surveys');

        // Filtrar pesquisas da empresa do usuário
        const filteredSurveys = surveyResponse.data.filter(
          survey => survey.id_cnpj === userResponse.data.id_cnpj
        );

        setSurveys(filteredSurveys);

        if (filteredSurveys.length > 0) {
          setSelectedSurvey(filteredSurveys[0].id);
        }

        setIsLoading(false);
      } catch (error) {
        console.error('Erro ao carregar dados:', error);
        setError('Erro ao carregar dados. Por favor, tente novamente mais tarde.');
        setIsLoading(false);
      }
    };

    fetchData();
  }, [refreshKey]);

  useEffect(() => {
    const fetchSurveyData = async () => {
      if (!selectedSurvey) return;

      try {
        setIsLoading(true);
        const responses = await axios.get(`/surveys/${selectedSurvey}/responses`);
        const survey = surveys.find(s => s.id === selectedSurvey);

        if (!survey || !survey.surveyQuestions || survey.surveyQuestions.length === 0) {
          setChartData(null);
          setSurveyStats(null);
          setIsLoading(false);
          return;
        }

        // Filtrar perguntas baseado na pesquisa
        let filteredQuestions = survey.surveyQuestions;
        if (searchQuery) {
          const query = searchQuery.toLowerCase();
          filteredQuestions = filteredQuestions.filter(q => 
            q.questionText.toLowerCase().includes(query)
          );
        }

        if (filteredQuestions.length === 0) {
          setChartData(null);
          setSurveyStats(null);
          setIsLoading(false);
          return;
        }

        // Calcular estatísticas da pesquisa
        const totalRespondents = new Set(responses.data.map(r => r.userId)).size;
        const questionStats = filteredQuestions.map(question => {
          const questionResponses = responses.data.filter(r => 
            r.questionIndex === survey.surveyQuestions.indexOf(question)
          );
          const answerCounts = {};
          question.answers.forEach(answer => {
            answerCounts[answer] = questionResponses.filter(r => r.answer === answer).length;
          });
          return {
            question: question.questionText,
            totalResponses: questionResponses.length,
            answerCounts
          };
        });

        setSurveyStats({
          totalRespondents,
          questionStats
        });

        // Criar datasets para o gráfico
        if (chartType === 'bar') {
          const datasets = filteredQuestions[0].answers.map((answer, index) => ({
            label: answer,
            data: filteredQuestions.map(question => {
              const responseCount = responses.data.filter(r => 
                r.questionIndex === survey.surveyQuestions.indexOf(question) && 
                r.answer === answer
              ).length;
              return responseCount;
            }),
            backgroundColor: getColorForAnswer(index),
            borderColor: getColorForAnswer(index, true),
            borderWidth: 2,
            borderRadius: 4,
            barPercentage: 0.8,
            categoryPercentage: 0.9
          }));

          setChartData({
            labels: filteredQuestions.map(q => q.questionText),
            datasets
          });
        } else if (chartType === 'pie') {
          // Dados para gráfico de pizza
          const pieDataArray = filteredQuestions.map(question => {
            const questionResponses = responses.data.filter(r => 
              r.questionIndex === survey.surveyQuestions.indexOf(question)
            );
            const answerCounts = {};
            question.answers.forEach(answer => {
              answerCounts[answer] = questionResponses.filter(r => r.answer === answer).length;
            });

            return {
              labels: question.answers,
              datasets: [{
                data: question.answers.map(answer => answerCounts[answer]),
                backgroundColor: question.answers.map((_, i) => getColorForAnswer(i)),
                borderColor: question.answers.map((_, i) => getColorForAnswer(i, true)),
                borderWidth: 2
              }]
            };
          });
          setChartData(pieDataArray);
        }

        setIsLoading(false);
      } catch (error) {
        console.error('Erro ao carregar dados da pesquisa:', error);
        setError('Erro ao carregar dados da pesquisa.');
        setIsLoading(false);
      }
    };

    fetchSurveyData();
  }, [selectedSurvey, searchQuery, surveys, chartType]);

  const getColorForAnswer = (index, solid = false) => {
    const alpha = solid ? '1' : '0.8';
    const colors = [
      `rgba(76, 175, 80, ${alpha})`,    // Verde
      `rgba(33, 150, 243, ${alpha})`,   // Azul
      `rgba(156, 39, 176, ${alpha})`,   // Roxo
      `rgba(255, 152, 0, ${alpha})`,    // Laranja
      `rgba(244, 67, 54, ${alpha})`,    // Vermelho
    ];
    return colors[index % colors.length];
  };

  const handleRefresh = () => {
    setRefreshKey(prev => prev + 1);
    toast.info('Atualizando dados...');
  };

  const toggleQuestion = (index) => {
    setExpandedQuestions(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const prepareCSVData = () => {
    if (!surveyStats || !surveys.find(s => s.id === selectedSurvey)) return [];

    const selectedSurveyData = surveys.find(s => s.id === selectedSurvey);
    const csvData = [];

    // Cabeçalho do relatório
    csvData.push(['Relatório de Pesquisa de Clima']);
    csvData.push([]);
    csvData.push(['Título da Pesquisa', selectedSurveyData.title]);
    csvData.push(['Total de Participantes', surveyStats.totalRespondents]);
    csvData.push(['Total de Perguntas', surveyStats.questionStats.length]);
    csvData.push([]);

    // Dados de cada pergunta
    surveyStats.questionStats.forEach((stat, index) => {
      csvData.push([`Pergunta ${index + 1}`, stat.question]);
      csvData.push(['Opção', 'Respostas', 'Porcentagem']);
      
      Object.entries(stat.answerCounts).forEach(([answer, count]) => {
        const percentage = ((count / surveyStats.totalRespondents) * 100).toFixed(1);
        csvData.push([answer, count, `${percentage}%`]);
      });
      
      csvData.push([]); // Linha em branco entre perguntas
    });

    return csvData;
  };

  if (isLoading) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="400px">
        <CircularProgress />
        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
          Carregando dados...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ width: '100%' }}>
        {error}
      </Alert>
    );
  }

  if (!chartData || !surveyStats) {
    return (
      <Alert severity="info" sx={{ width: '100%' }}>
        Nenhuma resposta de pesquisa disponível.
      </Alert>
    );
  }

  return (
    <Box>
      <ToastContainer />
      
      {/* Cabeçalho com Controles */}
      <Paper sx={{ p: 2, mb: 3 }}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h6">
              Análise de Respostas
            </Typography>
            <Tooltip title="Atualizar dados">
              <IconButton onClick={handleRefresh} size="small">
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Stack>
          
          <Stack direction="row" spacing={1}>
            <Tooltip title="Gráfico de Barras">
              <IconButton
                color={chartType === 'bar' ? 'primary' : 'default'}
                onClick={() => setChartType('bar')}
              >
                <BarChartIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Gráfico de Pizza">
              <IconButton
                color={chartType === 'pie' ? 'primary' : 'default'}
                onClick={() => setChartType('pie')}
              >
                <PieChartIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Visualização em Tabela">
              <IconButton
                color={chartType === 'table' ? 'primary' : 'default'}
                onClick={() => setChartType('table')}
              >
                <TableChartIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </Paper>

      {/* Filtros */}
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mb: 3 }}>
        <FormControl fullWidth>
          <InputLabel>Selecione a Pesquisa</InputLabel>
          <Select
            value={selectedSurvey}
            label="Selecione a Pesquisa"
            onChange={(e) => setSelectedSurvey(e.target.value)}
          >
            {surveys.map((survey) => (
              <MenuItem key={survey.id} value={survey.id}>
                {survey.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label="Pesquisar Perguntas"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {/* Visão Geral */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={4}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Visão Geral da Pesquisa
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Stack spacing={3}>
                <Box>
                  <Typography variant="subtitle2" color="text.secondary">
                    Total de Participantes
                  </Typography>
                  <Typography variant="h3">
                    {surveyStats.totalRespondents}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2" color="text.secondary">
                    Total de Perguntas
                  </Typography>
                  <Typography variant="h3">
                    {surveyStats.questionStats.length}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2" color="text.secondary">
                    Taxa de Resposta
                  </Typography>
                  <Typography variant="h3">
                    {surveyStats.totalRespondents > 0
                      ? `${((surveyStats.totalRespondents / surveys.find(s => s.id === selectedSurvey)?.surveyQuestions?.length || 1) * 100).toFixed(1)}%`
                      : '0%'}
                  </Typography>
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Gráfico de Respostas
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Box sx={{ height: 400, position: 'relative' }}>
                {chartType === 'bar' ? (
                  <Bar
                    data={chartData}
                    options={{
                      maintainAspectRatio: false,
                      responsive: true,
                      animation: {
                        duration: 1000,
                        easing: 'easeInOutQuart'
                      },
                      plugins: {
                        legend: {
                          position: 'top',
                          labels: {
                            boxWidth: isMobile ? 12 : 40,
                            padding: isMobile ? 10 : 20,
                            font: {
                              size: isMobile ? 10 : 12
                            }
                          }
                        },
                        tooltip: {
                          backgroundColor: 'rgba(255, 255, 255, 0.9)',
                          titleColor: '#000',
                          titleFont: {
                            size: 13,
                            weight: 'bold'
                          },
                          bodyColor: '#666',
                          bodyFont: {
                            size: 12
                          },
                          borderColor: '#ddd',
                          borderWidth: 1,
                          padding: 12,
                          callbacks: {
                            label: function(context) {
                              const value = context.raw;
                              const total = surveyStats.totalRespondents;
                              const percentage = ((value / total) * 100).toFixed(1);
                              return `${context.dataset.label}: ${value} respostas (${percentage}%)`;
                            }
                          }
                        }
                      },
                      scales: {
                        x: {
                          display: true,
                          grid: {
                            display: false
                          },
                          ticks: {
                            maxRotation: 45,
                            minRotation: 45
                          }
                        },
                        y: {
                          beginAtZero: true,
                          grid: {
                            color: 'rgba(0, 0, 0, 0.1)'
                          },
                          ticks: {
                            stepSize: 1,
                            font: {
                              size: isMobile ? 10 : 12
                            }
                          }
                        }
                      }
                    }}
                  />
                ) : chartType === 'pie' ? (
                  <Grid container spacing={2}>
                    {Array.isArray(chartData) ? chartData.map((pieData, index) => (
                      <Grid item xs={12} md={6} key={index}>
                        <Typography variant="subtitle2" align="center" gutterBottom>
                          {surveyStats.questionStats[index].question}
                        </Typography>
                        <Box sx={{ height: 200 }}>
                          <Pie
                            data={pieData}
                            options={{
                              maintainAspectRatio: false,
                              plugins: {
                                legend: {
                                  position: 'right',
                                  labels: {
                                    font: {
                                      size: 11
                                    }
                                  }
                                },
                                tooltip: {
                                  callbacks: {
                                    label: function(context) {
                                      const value = context.raw;
                                      const total = context.dataset.data.reduce((a, b) => a + b, 0);
                                      const percentage = ((value / total) * 100).toFixed(1);
                                      return `${context.label}: ${value} (${percentage}%)`;
                                    }
                                  }
                                }
                              }
                            }}
                          />
                        </Box>
                      </Grid>
                    )) : (
                      <Grid item xs={12}>
                        <Typography variant="body2" color="text.secondary" align="center">
                          Não há dados suficientes para exibir o gráfico de pizza.
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  <Box sx={{ overflowX: 'auto' }}>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                      <thead>
                        <tr>
                          <th style={{ padding: '12px', borderBottom: '2px solid #ddd', textAlign: 'left' }}>Pergunta</th>
                          <th style={{ padding: '12px', borderBottom: '2px solid #ddd', textAlign: 'right' }}>Respostas</th>
                          <th style={{ padding: '12px', borderBottom: '2px solid #ddd', textAlign: 'right' }}>Distribuição</th>
                        </tr>
                      </thead>
                      <tbody>
                        {surveyStats.questionStats.map((stat, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td
                                style={{ padding: '12px', borderBottom: '1px solid #ddd', cursor: 'pointer' }}
                                onClick={() => toggleQuestion(index)}
                              >
                                <Box display="flex" alignItems="center">
                                  <IconButton size="small">
                                    {expandedQuestions[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                  </IconButton>
                                  {stat.question}
                                </Box>
                              </td>
                              <td style={{ padding: '12px', borderBottom: '1px solid #ddd', textAlign: 'right' }}>
                                {stat.totalResponses}
                              </td>
                              <td style={{ padding: '12px', borderBottom: '1px solid #ddd', textAlign: 'right' }}>
                                {((stat.totalResponses / surveyStats.totalRespondents) * 100).toFixed(1)}%
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={3} style={{ padding: 0 }}>
                                <Collapse in={expandedQuestions[index]}>
                                  <Box sx={{ p: 2, bgcolor: 'grey.50' }}>
                                    {Object.entries(stat.answerCounts).map(([answer, count], i) => (
                                      <Box key={i} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                        <Typography variant="body2">{answer}</Typography>
                                        <Box>
                                          <Chip
                                            label={`${count} (${((count / surveyStats.totalRespondents) * 100).toFixed(1)}%)`}
                                            size="small"
                                            sx={{ bgcolor: getColorForAnswer(i, true), color: 'white' }}
                                          />
                                        </Box>
                                      </Box>
                                    ))}
                                  </Box>
                                </Collapse>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Botão de Download */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CSVLink
          data={prepareCSVData()}
          filename={`Pesquisa_de_Clima_${moment().format('DD-MM-YYYY')}.csv`}
          className="button"
          style={{ textDecoration: 'none' }}
        >
          <Paper
            elevation={3}
            sx={{
              background: 'linear-gradient(45deg, #6a438b 30%, #B800D8 90%)',
              color: 'white',
              py: 0.75,
              px: 1.5,
              borderRadius: '6px',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              fontSize: '0.875rem',
              fontWeight: 600,
              boxShadow: '0 2px 4px rgba(106, 67, 139, .3)',
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 8px rgba(106, 67, 139, .3)',
                background: 'linear-gradient(45deg, #B800D8 30%, #6a438b 90%)'
              }
            }}
          >
            <DownloadIcon color="primary" />
            <Typography variant="button" color="primary">
              Baixar Relatório Completo
            </Typography>
          </Paper>
        </CSVLink>
      </Box>
    </Box>
  );
};

export default SurveyDashboardResponse;
