import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import 'moment/locale/pt-br';
import { Box } from '@mui/material';

moment.locale('pt-br');

const TrendChart = ({ data }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (!data || data.length === 0) return;

    // Agrupar colaboradores por mês/ano
    const groupedData = data.reduce((acc, curr) => {
      const date = moment(curr.data_admissao);
      const monthYear = date.format('MMM/YYYY');
      
      if (!acc[monthYear]) {
        acc[monthYear] = 0;
      }
      acc[monthYear]++;
      return acc;
    }, {});

    // Ordenar as datas
    const sortedDates = Object.keys(groupedData).sort((a, b) => 
      moment(a, 'MMM/YYYY').diff(moment(b, 'MMM/YYYY'))
    );

    // Se houver apenas uma data, adicionar pontos antes e depois
    if (sortedDates.length === 1) {
      const currentDate = moment(sortedDates[0], 'MMM/YYYY');
      const prevMonth = currentDate.clone().subtract(1, 'month').format('MMM/YYYY');
      const nextMonth = currentDate.clone().add(1, 'month').format('MMM/YYYY');
      groupedData[prevMonth] = 0;
      groupedData[nextMonth] = 0;
      sortedDates.unshift(prevMonth);
      sortedDates.push(nextMonth);
    }

    // Preencher meses faltantes entre datas
    const filledDates = [];
    for (let i = 0; i < sortedDates.length - 1; i++) {
      const currentDate = moment(sortedDates[i], 'MMM/YYYY');
      const nextDate = moment(sortedDates[i + 1], 'MMM/YYYY');
      filledDates.push(sortedDates[i]);

      while (currentDate.add(1, 'month').isBefore(nextDate)) {
        const monthYear = currentDate.format('MMM/YYYY');
        if (!groupedData[monthYear]) {
          groupedData[monthYear] = 0;
          filledDates.push(monthYear);
        }
      }
    }
    filledDates.push(sortedDates[sortedDates.length - 1]);

    setChartData({
      labels: filledDates,
      datasets: [
        {
          label: 'Novos Colaboradores',
          data: filledDates.map(date => groupedData[date]),
          borderColor: 'rgb(75, 192, 192)',
          backgroundColor: 'rgba(75, 192, 192, 0.5)',
          tension: 0.3,
          fill: true,
          pointRadius: 4,
          pointHoverRadius: 6,
        },
      ],
    });
  }, [data]);

  if (!chartData) return null;

  return (
    <Box sx={{ height: 400, width: '100%', position: 'relative' }}>
      <Line
        data={chartData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Tendência de Contratações',
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  const value = context.raw;
                  return `${value} colaborador${value !== 1 ? 'es' : ''}`;
                }
              }
            }
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                maxRotation: 45,
                minRotation: 45,
              }
            },
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 1,
                precision: 0
              }
            }
          }
        }}
      />
    </Box>
  );
};

export default TrendChart; 