import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { 
    Box, 
    Typography, 
    Button, 
    Grid, 
    Modal,
    Paper,
    Divider,
    Stack,
    Chip,
    useTheme
} from '@mui/material';
import { 
    VisibilityOutlined,
    DownloadOutlined,
    AssignmentIndOutlined
} from '@mui/icons-material';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/pt-br';
import SideBarRh from './SideBarRh';

// Configurar worker do PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ContractView = () => {
    const theme = useTheme();
    const { contractId } = useParams();
    const [contract, setContract] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pdfUrl, setPdfUrl] = useState('');
    const [error, setError] = useState(null);
    const [allSigners, setAllSigners] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [userIp, setUserIp] = useState('');

    // Efeito para limpar a URL do blob quando o componente é desmontado
    useEffect(() => {
        return () => {
            if (pdfUrl && pdfUrl.startsWith('blob:')) {
                URL.revokeObjectURL(pdfUrl);
            }
        };
    }, [pdfUrl]);

    // Efeito para buscar os dados do Documento
    useEffect(() => {
        const fetchContract = async () => {
            try {
                // Buscar IP do usuário
                const ipResponse = await axios.get('https://api.ipify.org?format=json');
                setUserIp(ipResponse.data.ip);

                const response = await axios.get(`/contracts/${contractId}`);
                console.log('Resposta do Documento:', response.data);
                const documentPath = response.data.documentPath;
                console.log('Caminho do documento:', documentPath);
                
                // Buscar o PDF como blob
                const pdfResponse = await axios.get(`/uploads/${documentPath}`, {
                    responseType: 'blob',
                    headers: {
                        'Accept': 'application/pdf'
                    }
                });
                
                // Criar URL do blob
                const pdfBlob = new Blob([pdfResponse.data], { type: 'application/pdf' });
                const newPdfUrl = URL.createObjectURL(pdfBlob);
                console.log('URL do PDF criada:', newPdfUrl);
                setPdfUrl(newPdfUrl);
                setContract(response.data);
                setAllSigners(response.data.signers);
            } catch (error) {
                console.error('Erro ao buscar Documento:', error);
                setError('Erro ao carregar o Documento.');
            }
        };

        fetchContract();
    }, [contractId]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setError(null);
    };

    const onDocumentLoadError = (error) => {
        console.error('Erro ao carregar PDF:', error);
        setError('Erro ao carregar o PDF. Por favor, tente novamente mais tarde.');
    };

    const handleDownload = async () => {
        try {
            // Fazer o download do contrato via API
            const response = await axios.get(`/contracts/${contractId}/download`, {
                responseType: 'blob'
            });

            // Criar URL do blob e fazer download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Contrato_${contract.title}${contract.status === 'Assinado' ? '_Assinado' : ''}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Erro ao baixar contrato:', error);
            toast.error('Erro ao baixar o contrato');
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleViewPDF = () => {
        if (pdfUrl) {
            window.open(pdfUrl, '_blank');
        }
    };

    const handleViewDeclarations = () => {
        setModalOpen(true);
    };

    // Função para gerar a declaração de um signatário
    const generateSignerDeclaration = (signer, userIp) => {
        return (
            <Box key={signer.id} sx={{ mb: 4, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
                <Typography variant="h6" gutterBottom>Assinante</Typography>
                <Typography paragraph>
                    Eu, {signer.name}, declaro que estou ciente e de acordo com os termos deste documento.
                    Ao clicar no botão de assinatura, confirmo a veracidade das informações e minha
                    concordância com o conteúdo, com a validade jurídica de uma assinatura eletrônica,
                    conforme a Lei nº 14.063/2020.
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography><strong>CPF:</strong> {signer.cpf}</Typography>
                        <Typography><strong>Email:</strong> {signer.email}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography><strong>IP:</strong> {signer.ip || userIp}</Typography>
                        <Typography>
                            <strong>Data:</strong> {signer.signedAt ? 
                                moment(signer.signedAt).format('DD/MM/YYYY [às] HH:mm:ss') : 
                                'Não assinado'}
                        </Typography>
                    </Grid>
                </Grid>
                <Box sx={{ mt: 1 }}>
                    <Chip 
                        label={signer.signed ? "Assinado" : "Pendente"} 
                        color={signer.signed ? "success" : "warning"}
                        size="small"
                    />
                </Box>
            </Box>
        );
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <SideBarRh />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    mt: 8,
                    backgroundColor: '#fff',
                    minHeight: '100vh'
                }}
            >
                <ToastContainer />
                {error && (
                    <Paper sx={{ p: 2, mb: 2, bgcolor: '#FFF3F0' }}>
                        <Typography color="error">{error}</Typography>
                    </Paper>
                )}
               
                {contract && (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper 
                                elevation={0}
                                sx={{ 
                                    p: 3, 
                                    mb: 2, 
                                    borderRadius: 2,
                                    border: '1px solid #e0e0e0',
                                    background: 'linear-gradient(135deg, #6A438B 0%, #6A438B 100%)',
                                    color: '#fff'
                                }}
                            >
                                <Stack direction="row" alignItems="center" justifyContent="space-between">
                                    <Box>
                                        <Typography variant="h5" component="h1" gutterBottom sx={{ fontWeight: 600 }}>
                                            {contract.title}
                                        </Typography>
                                        <Typography 
                                            variant="subtitle1" 
                                            sx={{ 
                                                color: contract.status === 'Assinado' ? '#FBDD23' : '#fff',
                                                fontWeight: 'medium'
                                            }}
                                        >
                                            Status: {contract.status}
                                        </Typography>
                                    </Box>
                                    <Stack direction="row" spacing={2}>
                                        <Button
                                            variant="contained"
                                            startIcon={<VisibilityOutlined />}
                                            onClick={handleViewPDF}
                                            sx={{
                                                bgcolor: '#FBDD23',
                                                color: '#333',
                                                '&:hover': {
                                                    bgcolor: '#e5c920'
                                                }
                                            }}
                                        >
                                            Ler Documento
                                        </Button>
                                        {contract.status === 'Assinado' && (
                                            <>
                                                <Button
                                                    variant="contained"
                                                    startIcon={<AssignmentIndOutlined />}
                                                    onClick={handleViewDeclarations}
                                                    sx={{
                                                        bgcolor: '#FBDD23',
                                                        color: '#333',
                                                        '&:hover': {
                                                            bgcolor: '#e5c920'
                                                        }
                                                    }}
                                                >
                                                   Visualizar Declarações
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    startIcon={<DownloadOutlined />}
                                                    onClick={handleDownload}
                                                    sx={{
                                                        bgcolor: '#FBDD23',
                                                        color: '#333',
                                                        '&:hover': {
                                                            bgcolor: '#e5c920'
                                                        }
                                                    }}
                                                >
                                                    Baixar Documento assinado
                                                </Button>
                                            </>
                                        )}
                                    </Stack>
                                </Stack>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} md={8}>
                            <Paper 
                                sx={{ 
                                    height: '80vh', 
                                    overflow: 'hidden',
                                    borderRadius: 2,
                                    border: '1px solid #e0e0e0'
                                }}
                            >
                                {pdfUrl ? (
                                    <Box sx={{ 
                                        height: '100%', 
                                        overflow: 'auto',
                                        bgcolor: '#f5f5f5',
                                        p: 2
                                    }}>
                                        <Document
                                            file={pdfUrl}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                            onLoadError={onDocumentLoadError}
                                            loading={
                                                <Box sx={{ 
                                                    display: 'flex', 
                                                    justifyContent: 'center', 
                                                    alignItems: 'center', 
                                                    height: '100%' 
                                                }}>
                                                    <Typography>Carregando PDF...</Typography>
                                                </Box>
                                            }
                                        >
                                            {Array.from(new Array(numPages), (el, index) => (
                                                <Box sx={{ mb: 2, bgcolor: 'white', boxShadow: 1, borderRadius: 1 }}>
                                                    <Page 
                                                        key={`page_${index + 1}`} 
                                                        pageNumber={index + 1} 
                                                        width={window.innerWidth * 0.45}
                                                        renderTextLayer={false}
                                                        renderAnnotationLayer={false}
                                                    />
                                                </Box>
                                            ))}
                                        </Document>
                                    </Box>
                                ) : (
                                    <Box sx={{ 
                                        display: 'flex', 
                                        justifyContent: 'center', 
                                        alignItems: 'center', 
                                        height: '100%' 
                                    }}>
                                        <Typography>Carregando Documento...</Typography>
                                    </Box>
                                )}
                            </Paper>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Paper 
                                sx={{ 
                                    p: 3,
                                    borderRadius: 2,
                                    border: '1px solid #e0e0e0'
                                }}
                            >
                                <Box>
                                    <Typography variant="h6" gutterBottom sx={{ color: '#6A438B', fontWeight: 600 }}>
                                        Status das Assinaturas
                                    </Typography>
                                    <Divider sx={{ mb: 2 }} />
                                    {allSigners.map((signer, index) => (
                                        <Box 
                                            key={index} 
                                            sx={{ 
                                                mb: 2, 
                                                p: 2, 
                                                bgcolor: '#f8f9fa', 
                                                borderRadius: 2,
                                                border: signer.signed ? '2px solid #4caf50' : '2px solid #ff9800'
                                            }}
                                        >
                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#333' }}>
                                                {signer.name}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                                                {signer.signed ? 
                                                    `Assinado em ${moment(signer.signedAt).format('DD/MM/YYYY [às] HH:mm')}` : 
                                                    'Aguardando assinatura'}
                                            </Typography>
                                            <Chip 
                                                label={signer.signed ? "Assinado" : "Pendente"} 
                                                color={signer.signed ? "success" : "warning"}
                                                size="small"
                                                sx={{ mt: 1 }}
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                )}

                {/* Modal de Declarações */}
                <Modal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-declarations"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        maxWidth: 800,
                        maxHeight: '90vh',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflow: 'auto',
                        borderRadius: 2
                    }}>
                        <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#6A438B', fontWeight: 600 }}>
                            Declarações de Assinatura
                        </Typography>
                        <Divider sx={{ mb: 3 }} />
                        {allSigners.map(signer => generateSignerDeclaration(signer, userIp))}
                        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button 
                                onClick={handleCloseModal} 
                                variant="contained"
                                sx={{
                                    bgcolor: '#6A438B',
                                    '&:hover': {
                                        bgcolor: '#5a3777'
                                    }
                                }}
                            >
                                Fechar
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </Box>
        </Box>
    );
};

export default ContractView; 