import axios from 'axios';

// Configuração global do axios
const baseURL = process.env.REACT_APP_API_URL || 'https://flexit.site/api';
console.log('Axios baseURL:', baseURL);

// Criando uma instância do axios com a configuração
const api = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Configuração global para todas as requisições
axios.defaults.baseURL = baseURL;
axios.defaults.headers.common['Content-Type'] = 'application/json';

// Interceptador para requisições
api.interceptors.request.use(
  config => {
    console.log('Fazendo requisição para:', config.baseURL + config.url);
    return config;
  },
  error => {
    console.error('Erro na requisição:', error);
    return Promise.reject(error);
  }
);

// Interceptador para respostas
api.interceptors.response.use(
  response => {
    console.log('Resposta recebida de:', response.config.url);
    return response;
  },
  error => {
    if (error.response) {
      // O servidor respondeu com um status de erro
      console.error('Erro do servidor:', error.response.status, error.response.data);
    } else if (error.request) {
      // A requisição foi feita mas não houve resposta
      console.error('Sem resposta do servidor:', error.request);
    } else {
      // Algo aconteceu na configuração da requisição
      console.error('Erro na configuração da requisição:', error.message);
    }
    return Promise.reject(error);
  }
);

export default api; 