import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Box,
  TextField,
  Button,
  IconButton,
  Divider,
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Chip,
  Tooltip,
  Zoom,
  useTheme,
  useMediaQuery,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  Save as SaveIcon,
  Help as HelpIcon,
  ArrowForward as ArrowForwardIcon,
  ArrowBack as ArrowBackIcon,
  ContentCopy as ContentCopyIcon,
  Archive as ArchiveIcon,
  MoreVert as MoreVertIcon,
  FileDownload as FileDownloadIcon,
  Visibility as VisibilityIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

const steps = ['Informações Básicas', 'Perguntas', 'Revisão'];

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`survey-tabpanel-${index}`}
      aria-labelledby={`survey-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const SurveyCreator = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeStep, setActiveStep] = useState(0);
  const [surveyTitle, setSurveyTitle] = useState('');
  const [surveyDescription, setSurveyDescription] = useState('');
  const [questions, setQuestions] = useState([
    { questionText: '', answers: ['', ''] }
  ]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedSurveyResponses, setSelectedSurveyResponses] = useState([]);
  const [loadingResponses, setLoadingResponses] = useState(false);

  useEffect(() => {
    fetchSurveys();
  }, []);

  const fetchSurveys = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const userResponse = await axios.get(`/users/${userId}`);
      const id_cnpj = userResponse.data.id_cnpj;

      // Buscar pesquisas
      const surveysResponse = await axios.get('/surveys');
      const filteredSurveys = surveysResponse.data.filter(survey => survey.id_cnpj === id_cnpj);
      
      // Buscar respostas para cada pesquisa
      const surveysWithResponses = await Promise.all(
        filteredSurveys.map(async (survey) => {
          const responsesResponse = await axios.get(`/surveys/${survey.id}/responses`);
          return {
            ...survey,
            responseCount: responsesResponse.data.length,
            questionCount: survey.surveyQuestions?.length || 0
          };
        })
      );

      setSurveys(surveysWithResponses);
      setLoading(false);
    } catch (error) {
      console.error('Erro ao buscar pesquisas:', error);
      toast.error('Erro ao carregar pesquisas');
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleMenuOpen = (event, survey) => {
    setAnchorEl(event.currentTarget);
    setSelectedSurvey(survey);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedSurvey(null);
  };

  const handleDuplicateSurvey = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const userResponse = await axios.get(`/users/${userId}`);
      const id_cnpj = userResponse.data.id_cnpj;

      const newSurvey = {
        title: `${selectedSurvey.title} (Cópia)`,
        questions: selectedSurvey.surveyQuestions,
        id_cnpj,
      };

      await axios.post('/surveys', newSurvey);
      toast.success('Pesquisa duplicada com sucesso!');
      fetchSurveys();
    } catch (error) {
      console.error('Erro ao duplicar pesquisa:', error);
      toast.error('Erro ao duplicar pesquisa');
    }
    handleMenuClose();
  };

  const handleArchiveSurvey = async () => {
    try {
      const response = await axios.put(`/surveys/${selectedSurvey.id}/archive`);
      toast.success('Pesquisa arquivada com sucesso!');
      toast.info(`A pesquisa será excluída em ${response.data.deleteDate}`);
      fetchSurveys();
    } catch (error) {
      console.error('Erro ao arquivar pesquisa:', error);
      toast.error('Erro ao arquivar pesquisa');
    }
    handleMenuClose();
  };

  const exportToExcel = async (survey) => {
    try {
      const response = await axios.get(`/surveys/${survey.id}/download-report`, {
        responseType: 'blob'
      });

      // Criar URL do blob e link para download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Pesquisa_${survey.title}_${moment().format('DDMMYYYY')}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

      toast.success('Relatório exportado com sucesso!');
    } catch (error) {
      console.error('Erro ao exportar relatório:', error);
      toast.error('Erro ao exportar relatório');
    }
  };

  const handleNext = () => {
    if (activeStep === 0 && !surveyTitle.trim()) {
      toast.error('Por favor, preencha o título da pesquisa');
      return;
    }
    if (activeStep === 1) {
      const invalidQuestions = questions.some(q => 
        !q.questionText.trim() || q.answers.some(a => !a.trim())
      );
      if (invalidQuestions) {
        toast.error('Por favor, preencha todas as perguntas e respostas');
        return;
      }
    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSurveyTitleChange = (event) => {
    setSurveyTitle(event.target.value);
  };

  const handleSurveyDescriptionChange = (event) => {
    setSurveyDescription(event.target.value);
  };

  const handleQuestionChange = (questionIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].questionText = value;
    setQuestions(newQuestions);
  };

  const handleAnswerChange = (questionIndex, answerIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].answers[answerIndex] = value;
    setQuestions(newQuestions);
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, { questionText: '', answers: ['', ''] }]);
  };

  const handleRemoveQuestion = (questionIndex) => {
    if (questions.length > 1) {
      const newQuestions = questions.filter((_, index) => index !== questionIndex);
      setQuestions(newQuestions);
    } else {
      toast.warning('A pesquisa deve ter pelo menos uma pergunta');
    }
  };

  const handleAddAnswer = (questionIndex) => {
    const newQuestions = [...questions];
    if (newQuestions[questionIndex].answers.length < 5) {
      newQuestions[questionIndex].answers.push('');
      setQuestions(newQuestions);
    } else {
      toast.warning('Máximo de 5 opções de resposta por pergunta');
    }
  };

  const handleRemoveAnswer = (questionIndex, answerIndex) => {
    const newQuestions = [...questions];
    if (newQuestions[questionIndex].answers.length > 2) {
      newQuestions[questionIndex].answers = newQuestions[questionIndex].answers.filter(
        (_, index) => index !== answerIndex
      );
      setQuestions(newQuestions);
    } else {
      toast.warning('A pergunta deve ter pelo menos duas opções de resposta');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      const userId = localStorage.getItem('userId');
      const userResponse = await axios.get(`/users/${userId}`);
      const id_cnpj = userResponse.data.id_cnpj;

      const surveyData = {
        title: surveyTitle,
        questions: questions.map(q => ({
          questionText: q.questionText,
          answers: q.answers.filter(a => a.trim() !== '')
        })),
        id_cnpj
      };

      await axios.post('/surveys', surveyData);
      toast.success('Pesquisa criada com sucesso!');
      
      // Resetar o formulário
      setSurveyTitle('');
      setSurveyDescription('');
      setQuestions([{ questionText: '', answers: ['', ''] }]);
      setActiveStep(0);
      setTabValue(1); // Mudar para a aba de listagem
      fetchSurveys(); // Atualizar a lista
    } catch (error) {
      console.error('Erro ao criar pesquisa:', error);
      toast.error('Erro ao criar pesquisa');
    } finally {
      setIsSubmitting(false);
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Card sx={{ p: 2, mb: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Informações Básicas da Pesquisa
              </Typography>
              <TextField
                label="Título da Pesquisa"
                value={surveyTitle}
                onChange={handleSurveyTitleChange}
                fullWidth
                margin="normal"
                variant="outlined"
                required
                helperText="Digite um título claro e objetivo"
              />
              <TextField
                label="Descrição da Pesquisa"
                value={surveyDescription}
                onChange={handleSurveyDescriptionChange}
                fullWidth
                margin="normal"
                variant="outlined"
                multiline
                rows={4}
                helperText="Explique o objetivo desta pesquisa"
              />
            </CardContent>
          </Card>
        );
      case 1:
        return (
          <>
            {questions.map((question, qIndex) => (
              <Card key={qIndex} sx={{ p: 2, mb: 2 }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Chip
                      label={`Pergunta ${qIndex + 1}`}
                      color="primary"
                      sx={{ mr: 2 }}
                    />
                    <TextField
                      label="Pergunta"
                      value={question.questionText}
                      onChange={(e) => handleQuestionChange(qIndex, e.target.value)}
                      fullWidth
                      required
                      variant="outlined"
                    />
                    <Tooltip title="Remover Pergunta" TransitionComponent={Zoom}>
                      <IconButton
                        onClick={() => handleRemoveQuestion(qIndex)}
                        color="error"
                        sx={{ ml: 1 }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                    Opções de Resposta:
                  </Typography>
                  {question.answers.map((answer, aIndex) => (
                    <Box key={aIndex} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <TextField
                        label={`Opção ${aIndex + 1}`}
                        value={answer}
                        onChange={(e) => handleAnswerChange(qIndex, aIndex, e.target.value)}
                        fullWidth
                        required
                        variant="outlined"
                        size="small"
                      />
                      <Tooltip title="Remover Opção" TransitionComponent={Zoom}>
                        <IconButton
                          onClick={() => handleRemoveAnswer(qIndex, aIndex)}
                          color="error"
                          size="small"
                          sx={{ ml: 1 }}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ))}
                  <Button
                    variant="outlined"
                    onClick={() => handleAddAnswer(qIndex)}
                    startIcon={<AddIcon />}
                    size="small"
                    sx={{ mt: 1 }}
                  >
                    Adicionar Opção
                  </Button>
                </CardContent>
              </Card>
            ))}
            <Button
              variant="contained"
              onClick={handleAddQuestion}
              startIcon={<AddIcon />}
              fullWidth
              sx={{ mt: 2 }}
            >
              Adicionar Nova Pergunta
            </Button>
          </>
        );
      case 2:
        return (
          <Card sx={{ p: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Revisão da Pesquisa
              </Typography>
              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" color="primary">
                  {surveyTitle}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {surveyDescription}
                </Typography>
              </Box>
              {questions.map((question, qIndex) => (
                <Paper key={qIndex} sx={{ p: 2, mb: 2 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    {qIndex + 1}. {question.questionText}
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    {question.answers.map((answer, aIndex) => (
                      <Typography key={aIndex} variant="body2" sx={{ mb: 0.5 }}>
                        {String.fromCharCode(97 + aIndex)}) {answer}
                      </Typography>
                    ))}
                  </Box>
                </Paper>
              ))}
            </CardContent>
          </Card>
        );
      default:
        return null;
    }
  };

  const handleViewDetails = async (survey) => {
    setSelectedSurvey(survey);
    setDetailsModalOpen(true);
    setLoadingResponses(true);

    try {
      const response = await axios.get(`/surveys/${survey.id}/responses`);
      const responses = response.data;

      // Agrupar respostas por usuário
      const userResponses = responses.reduce((acc, response) => {
        if (!acc[response.userId]) {
          acc[response.userId] = {
            answers: new Array(survey.surveyQuestions.length).fill(null),
            completed: 0
          };
        }
        acc[response.userId].answers[response.questionIndex] = response.answer;
        acc[response.userId].completed++;
        return acc;
      }, {});

      setSelectedSurveyResponses(responses);
    } catch (error) {
      console.error('Erro ao carregar respostas:', error);
      toast.error('Erro ao carregar respostas da pesquisa');
    } finally {
      setLoadingResponses(false);
    }
  };

  const handleCloseDetails = () => {
    setDetailsModalOpen(false);
    setSelectedSurvey(null);
    setSelectedSurveyResponses([]);
  };

  const calculateProgress = (responses, questionCount) => {
    if (!responses?.length || !questionCount) return 0;
    
    // Agrupar respostas por usuário para contar respostas únicas
    const uniqueResponses = responses.reduce((acc, response) => {
      if (!acc[response.userId]) {
        acc[response.userId] = new Set();
      }
      acc[response.userId].add(response.questionIndex);
      return acc;
    }, {});

    // Calcular a média de progresso de todos os usuários
    const totalUsers = Object.keys(uniqueResponses).length;
    if (totalUsers === 0) return 0;

    const totalProgress = Object.values(uniqueResponses).reduce((sum, userResponses) => {
      return sum + (userResponses.size / questionCount) * 100;
    }, 0);

    return Math.round(totalProgress / totalUsers);
  };

  return (
    <Grid container spacing={3}>
      <ToastContainer />
      <Grid item xs={12} md={9}>
        <Paper sx={{ width: '100%', mb: 3 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="fullWidth"
          
            sx={{ 
               borderBottom: 1, 
               borderColor: 'divider',
               bgcolor: '#6A438B',
               '&:hover': {
                 bgcolor: '#5a3777'
               }
            }}
          >
            <Tab 
            
            sx={{
              color: '#fff',
             '&.Mui-selected': {
                color: '#FBDD23'
              }}} 
              
              label="Criar Pesquisa" />

            <Tab 
            sx={{
              color: '#fff',            
              '&.Mui-selected': {
                color: '#FBDD23'
              }}}           
            
            label="Pesquisas Criadas" />
          </Tabs>

          <TabPanel value={tabValue} index={0}>
            <Typography variant="h5" gutterBottom>
              Criar Nova Pesquisa de Clima
            </Typography>
            <Divider sx={{ mb: 3 }} />
            <Stepper activeStep={activeStep} alternativeLabel={!isMobile}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box sx={{ mt: 4 }}>
              <form onSubmit={handleSubmit}>
                {getStepContent(activeStep)}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    startIcon={<ArrowBackIcon />}
                  >
                    Voltar
                  </Button>
                  <Box>
                    {activeStep === steps.length - 1 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                        startIcon={<SaveIcon />}
                      >
                        {isSubmitting ? 'Salvando...' : 'Criar Pesquisa'}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        endIcon={<ArrowForwardIcon />}
                      >
                        Próximo
                      </Button>
                    )}
                  </Box>
                </Box>
              </form>
            </Box>
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h5" gutterBottom>
                Pesquisas Criadas
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Gerencie suas pesquisas de clima organizacional
              </Typography>
            </Box>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Título</TableCell>
                    <TableCell>Data de Criação</TableCell>
                    <TableCell>Respostas</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <CircularProgress size={24} sx={{ my: 2 }} />
                      </TableCell>
                    </TableRow>
                  ) : surveys.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        Nenhuma pesquisa encontrada
                      </TableCell>
                    </TableRow>
                  ) : (
                    surveys.map((survey) => (
                      <TableRow key={survey.id}>
                        <TableCell>{survey.title}</TableCell>
                        <TableCell>
                          {moment(survey.createdAt).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell>
                          {survey.responseCount || 0} respostas de {survey.questionCount || 0} questões
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={survey.archived ? 'Arquivada' : 'Ativa'}
                            color={survey.archived ? 'default' : 'success'}
                            size="small"
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="Visualizar Detalhes">
                            <IconButton onClick={() => handleViewDetails(survey)}>
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Exportar Respostas">
                            <IconButton onClick={() => exportToExcel(survey)}>
                              <FileDownloadIcon />
                            </IconButton>
                          </Tooltip>
                          <IconButton onClick={(e) => handleMenuOpen(e, survey)}>
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleDuplicateSurvey}>
                <ContentCopyIcon sx={{ mr: 1 }} />
                Duplicar Pesquisa
              </MenuItem>
              <MenuItem onClick={handleArchiveSurvey}>
                <ArchiveIcon sx={{ mr: 1 }} />
                Arquivar Pesquisa
              </MenuItem>
            </Menu>
          </TabPanel>
        </Paper>
      </Grid>

      <Grid item xs={12} md={3}>
        <Card sx={{ height: '100%', bgcolor: '#F8F9FA' }}>
          <CardContent>
            <Box
              component="img"
              src="/assets/Clima_avatar.png"
              alt="Clima Avatar"
              sx={{
                width: '100%',
                height: 'auto',
                mb: 2
              }}
            />
            <Typography variant="h6" gutterBottom>
              Dicas para uma boa pesquisa
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                <HelpIcon color="primary" sx={{ mt: 0.5 }} />
                <Typography variant="body2">
                  Mantenha as perguntas claras e objetivas
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                <HelpIcon color="primary" sx={{ mt: 0.5 }} />
                <Typography variant="body2">
                  Use linguagem simples e direta
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                <HelpIcon color="primary" sx={{ mt: 0.5 }} />
                <Typography variant="body2">
                  Limite as opções de resposta a no máximo 5
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                <HelpIcon color="primary" sx={{ mt: 0.5 }} />
                <Typography variant="body2">
                  Evite perguntas tendenciosas
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Dialog
        open={detailsModalOpen}
        onClose={handleCloseDetails}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Detalhes da Pesquisa: {selectedSurvey?.title}
        </DialogTitle>
        <DialogContent>
          {loadingResponses ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Progresso da Pesquisa
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Box sx={{ flex: 1 }}>
                    <LinearProgress
                      variant="determinate"
                      value={calculateProgress(
                        selectedSurveyResponses,
                        selectedSurvey?.surveyQuestions?.length || 0
                      )}
                      sx={{ height: 10, borderRadius: 5 }}
                    />
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    {calculateProgress(
                      selectedSurveyResponses,
                      selectedSurvey?.surveyQuestions?.length || 0
                    )}%
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  {Object.keys(selectedSurveyResponses.reduce((acc, r) => {
                    acc[r.userId] = true;
                    return acc;
                  }, {})).length} usuários responderam a pesquisa
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Total de {selectedSurveyResponses.length} respostas para {selectedSurvey?.surveyQuestions?.length || 0} questões
                </Typography>
              </Box>

              <Typography variant="subtitle1" gutterBottom>
                Respostas por Questão
              </Typography>
              {selectedSurvey?.surveyQuestions?.map((question, index) => (
                <Accordion key={index}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>
                      Questão {index + 1}: {question.questionText}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {selectedSurveyResponses
                        .filter(response => response.questionIndex === index)
                        .map((response, rIndex) => (
                          <ListItem key={rIndex}>
                            <ListItemText
                              primary={`Resposta ${rIndex + 1}`}
                              secondary={response.answer}
                            />
                          </ListItem>
                        ))}
                      {selectedSurveyResponses.filter(response => response.questionIndex === index).length === 0 && (
                        <ListItem>
                          <ListItemText
                            secondary="Ainda não há respostas para esta questão"
                          />
                        </ListItem>
                      )}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetails}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default SurveyCreator;
