// UniformsEpiUser.js
import React from 'react';
import UniformsEpiDashboard from "../components/DashBoards/UniformsEpiDashboard"
import SideBarUser from "../components/SideBarUser";
import { Box, useTheme, Typography, Paper, Divider, Container, Grid } from '@mui/material';
import { LocalMall as UniformIcon, Security as EpiIcon } from '@mui/icons-material';

const UniformsEpiUser = () => {
  const theme = useTheme();

  return (
    <>
      <SideBarUser />
      <Box
        component="main"
        sx={{
          marginTop: 10,
          p: 3,
          [theme.breakpoints.down('sm')]: {
            marginTop: 7,
            p: 2
          },
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            {/* Cabeçalho */}
            <Grid item xs={12}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 3, 
                  borderRadius: 2,
                  border: '1px solid #e0e0e0',
                  background: 'linear-gradient(135deg, #6A438B 0%, #6A438B 100%)',
                  color: '#fff',
                  mb: 2
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Box>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
                      Uniformes e EPIs Disponíveis
                    </Typography>
                    <Typography variant="body1" sx={{ opacity: 0.8 }}>
                      Selecione os itens desejados e escolha seus tamanhos
                    </Typography>
                  </Box>
                  <Box sx={{ ml: 'auto', display: 'flex', gap: 2 }}>
                    <Box sx={{ textAlign: 'center' }}>
                      <UniformIcon sx={{ fontSize: 40, color: '#FBDD23' }} />
                      <Typography variant="caption" display="block">
                        Uniformes
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                      <EpiIcon sx={{ fontSize: 40, color: '#FBDD23' }} />
                      <Typography variant="caption" display="block">
                        EPIs
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>

            {/* Dashboard de Uniformes/EPIs */}
            <Grid item xs={12}>
              <Paper 
                elevation={0}
                sx={{ 
                  borderRadius: 2,
                  border: '1px solid #e0e0e0',
                  overflow: 'hidden'
                }}
              >
                <UniformsEpiDashboard />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default UniformsEpiUser;
