// src/components/ContractSigningPlatform.js
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  TextField,
  Button,
  InputAdornment,
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
  Input,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  Paper,
  Menu,
  MenuItem,
  Avatar,
  Tooltip,
  Modal,
  Chip,
  ListItemIcon,
  Grid,
  Stack,
  Divider
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  Search as SearchIcon,
  Remove as RemoveIcon,
  Add as AddIcon,
  MoreHoriz as MoreHorizIcon,
  Visibility as VisibilityOutlined,
  Download as DownloadOutlined,
  Archive as ArchiveOutlined,
  Delete as DeleteOutlined,
  Upload as UploadFileIcon,
  InsertDriveFile as InsertDriveFileIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';

// Definindo o componente ContractTable
const ContractTable = ({ contracts, filters }) => {
  // Lógica do ContractTable aqui
  return (
    <div>
      {/* Renderize a tabela de Documentos aqui */}
  
    </div>
  );
};

// Definindo os passos do Stepper
const steps = ['Upload do Arquivo', 'Inserir Assinantes', 'Conferir e Enviar'];

const ContractSigningPlatform = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [contractTitle, setContractTitle] = useState('');
  const [filter, setFilter] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [file, setfile] = useState(null);
  const [signers, setSigners] = useState([{ name: '', email: '', cpf: '' }]);
  const [contracts, setContracts] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [contractToDelete, setContractToDelete] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchContracts = async () => {
      try {
        const userId = localStorage.getItem('userId');
        if (!userId) {
          toast.error('Usuário não identificado');
          return;
        }
        const response = await axios.get(`/contracts?userId=${userId}`);
        setContracts(response.data);
      } catch (error) {
        console.error('Erro ao buscar Documentos:', error);
        const errorMessage = error.response?.data?.error || 'Erro ao carregar Documentos';
        toast.error(errorMessage);
      }
    };

    fetchContracts();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
    return new Date(dateString).toLocaleString('pt-BR', options);
  };

  const [anchorEl, setAnchorEl] = useState(null);
const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};
const handleClose = () => {
  setAnchorEl(null);
};

const getInitials = (name) => {
  const names = name.split(' ');
  return names.map(n => n.charAt(0).toUpperCase()).join('');
};

const handleDownload = async (contractId) => {
  try {
    const response = await axios.get(`/contracts/${contractId}/download`, {
      responseType: 'blob'
    });
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Documento.pdf');
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Erro ao baixar Documento:', error);
    toast.error('Erro ao baixar o Documento');
  }
};

const handleView = async (contractId) => {
  try {
    // Redirecionar para a página de visualização do Documento
    window.location.href = `/contract-signing/${contractId}`;
  } catch (error) {
    console.error('Erro ao visualizar Documento:', error);
    toast.error('Erro ao visualizar o Documento');
  }
};

const handleArchive = async (contractId) => {
  try {
    await axios.put(`/contracts/${contractId}/archive`);
    toast.success('Documento arquivado com sucesso');
    // Atualizar a lista de Documentos
    const userId = localStorage.getItem('userId');
    const response = await axios.get(`/contracts?userId=${userId}`);
    setContracts(response.data);
  } catch (error) {
    console.error('Erro ao arquivar Documento:', error);
    toast.error('Erro ao arquivar o Documento');
  }
};

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handlefileChange = (event) => {
    setfile(event.target.files[0]);
  };

  const handleSignerChange = (index, event) => {
    const { name, value } = event.target;
    const newSigners = [...signers];
    newSigners[index][name] = value;
    setSigners(newSigners);
  };

  const handleAddSigner = () => {
    setSigners([...signers, { name: '', email: '' }]);
  };

  const handleRemoveSigner = (index) => {
    const newSigners = signers.filter((_, i) => i !== index);
    setSigners(newSigners);
  };

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmitContract = async () => {
    if (!file || signers.some(signer => !signer.name || !signer.email || !signer.cpf)) {
      toast.error('Por favor, preencha todos os campos obrigatórios.');
      return;
    }
  
    const userId = localStorage.getItem('userId');
    if (!userId) {
      toast.error('User ID não encontrado. Por favor, faça login novamente.');
      return;
    }
  
    const formData = new FormData();
    formData.append('userId', userId);
    formData.append('file', file);
    formData.append('title', contractTitle);
    formData.append('signers', JSON.stringify(signers));

    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
  
    try {
      const response = await axios.post('/contracts', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log('Resposta do servidor:', response.data);
      toast.success('Documento enviado com sucesso!');

      // Limpar formulário
      setContractTitle('');
      setfile(null);
      setSigners([{ name: '', email: '', cpf: '' }]);
      setActiveStep(0);
      setTabIndex(0);

      // Recarregar a lista de Documentos
      const updatedResponse = await axios.get(`/contracts?userId=${userId}`);
      setContracts(updatedResponse.data);
    } catch (error) {
      console.error('Erro ao enviar Documento:', error);
      toast.error(error.response?.data?.error || 'Erro ao enviar Documento.');
    }
  };

  const handleDeleteContract = async () => {
    try {
      await axios.delete(`/contracts/${contractToDelete.id}`);
      toast.success('Documento excluído com sucesso');
      
      // Atualizar a lista de Documentos
      const userId = localStorage.getItem('userId');
      const response = await axios.get(`/contracts?userId=${userId}`);
      setContracts(response.data);
      
      setDeleteModalOpen(false);
      setContractToDelete(null);
    } catch (error) {
      console.error('Erro ao excluir Documento:', error);
      toast.error('Erro ao excluir o Documento');
    }
  };

  const handleOpenDeleteModal = (contract) => {
    setContractToDelete(contract);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setContractToDelete(null);
  };

  // Filtrar Documentos baseado na aba atual
  const filteredContracts = contracts.filter(contract => {
    const searchTerm = filter.toLowerCase();
    const matchesFilter = 
      contract.id.toString().includes(searchTerm) ||
      contract.title.toLowerCase().includes(searchTerm) ||
      contract.status.toLowerCase().includes(searchTerm) ||
      contract.signers.some(signer => 
        signer.email.toLowerCase().includes(searchTerm)
      ) ||
      contract.createdAt.includes(searchTerm) ||
      contract.updatedAt.includes(searchTerm);

    // Filtro por aba
    if (tabIndex === 0) {
      // Aba Documentos: mostrar apenas Pendentes e Assinados
      return (contract.status === 'Pendente' || contract.status === 'Assinado') && matchesFilter;
    } else if (tabIndex === 1) {
      // Aba Novo Documento: não mostrar nada
      return false;
    } else {
      // Aba Arquivados: mostrar apenas Arquivados
      return contract.status === 'Arquivado' && matchesFilter;
    }
  });

  return (
    <Box sx={{ width: '100%', typography: 'body1', bgcolor: '#fff', minHeight: '100vh' }}>
          <ToastContainer />

      <Box sx={{ 
        borderBottom: 1, 
        borderColor: 'divider',
        bgcolor: '#6A438B',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        position: 'sticky',
        top: 0,
        zIndex: 1000
      }}>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
          variant="fullWidth"
        sx={{
            '& .MuiTab-root': { 
              color: '#fff',
              fontSize: isMobile ? '0.875rem' : '1rem',
              textTransform: 'none',
              fontWeight: 500,
              minHeight: isMobile ? 48 : 64,
              opacity: 0.7,
              '&.Mui-selected': {
                color: '#FBDD23',
                opacity: 1
              }
            },
            '& .MuiTabs-indicator': { 
              backgroundColor: '#FBDD23',
              height: 3
            },
          }}
        >
          <Tab label="Documentos" />
          <Tab label="Novo" />
        <Tab label="Arquivados" />
      </Tabs>
      </Box>

      {tabIndex === 0 && (
        <Box sx={{ p: isMobile ? 1 : 3 }}>
          <Paper 
            elevation={0}
            sx={{ 
              p: isMobile ? 2 : 3, 
              mb: 2,
              borderRadius: 2,
              background: 'linear-gradient(135deg, #6A438B 0%, #6A438B 100%)',
              color: '#fff'
            }}
          >
            <Typography variant="h5" gutterBottom sx={{ 
              fontWeight: 600,
              fontSize: isMobile ? '1.25rem' : '1.5rem'
            }}>
              Lista de Documentos
    </Typography>

      <TextField
              label="Filtrar Documentos..."
        value={filter}
        onChange={handleFilterChange}
              fullWidth
              size={isMobile ? "small" : "medium"}
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderRadius: 1,
                '& .MuiOutlinedInput-root': {
                  color: '#fff',
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.3)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#FBDD23',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#FBDD23',
                },
              }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
                    <SearchIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
            </InputAdornment>
          ),
        }}
            />
          </Paper>

          {isMobile ? (
            // Visualização em cards para mobile
            <Stack spacing={2}>
              {filteredContracts.map((contract) => (
                <Paper
                  key={contract.id}
                  sx={{ 
                    p: 2,
                    borderRadius: 2,
                    border: '1px solid #e0e0e0'
                  }}
                >
                  <Stack spacing={1}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                        {contract.title}
                      </Typography>
                      <Chip
                        label={contract.status}
                        size="small"
                        sx={{
                          bgcolor: contract.status === 'Assinado' ? '#4caf50' : 
                                 contract.status === 'Pendente' ? '#ff9800' : '#6A438B',
                          color: '#fff',
                          fontWeight: 500
                        }}
      />
    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography variant="body2" color="text.secondary">
                        ID: {contract.id}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        • {formatDate(contract.createdAt)}
                      </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                      {contract.signers.map((signer, index) => (
                        <Tooltip title={`${signer.name} (${signer.email})`} key={signer.email}>
                          <Avatar
                            sx={{
                              width: 28,
                              height: 28,
                              marginLeft: index > 0 ? '-8px' : '0',
                              backgroundColor: '#6A438B',
                              color: '#fff',
                              fontSize: '0.75rem',
                              border: '2px solid #fff'
                            }}
                          >
                            {getInitials(signer.name)}
                          </Avatar>
                        </Tooltip>
                      ))}
                    </Box>

                    <Divider sx={{ my: 1 }} />

                    <Stack direction="row" spacing={1} justifyContent="flex-end">
                      <IconButton
                        onClick={() => handleView(contract.id)}
                        sx={{ 
                          color: '#6A438B',
                          '&:hover': { 
                            bgcolor: 'rgba(106, 67, 139, 0.1)'
                          }
                        }}
                      >
                        <VisibilityOutlined />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDownload(contract.id)}
                        sx={{ 
                          color: '#6A438B',
                          '&:hover': { 
                            bgcolor: 'rgba(106, 67, 139, 0.1)'
                          }
                        }}
                      >
                        <DownloadOutlined />
                      </IconButton>
                      {tabIndex === 0 ? (
                        <IconButton
                          onClick={() => handleArchive(contract.id)}
                          sx={{ 
                            color: '#6A438B',
                            '&:hover': { 
                              bgcolor: 'rgba(106, 67, 139, 0.1)'
                            }
                          }}
                        >
                          <ArchiveOutlined />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => handleOpenDeleteModal(contract)}
                          sx={{ 
                            color: '#ff5252',
                            '&:hover': { 
                              bgcolor: 'rgba(255, 82, 82, 0.1)'
                            }
                          }}
                        >
                          <DeleteOutlined />
                        </IconButton>
                      )}
                    </Stack>
                  </Stack>
                </Paper>
              ))}
            </Stack>
          ) : (
            // Tabela para desktop
            <Paper 
              sx={{ 
                borderRadius: 2,
                overflow: 'hidden',
                border: '1px solid #e0e0e0',
                boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
              }}
            >
              <TableContainer>
                <Table sx={{ minWidth: 650 }}>
        <TableHead>
                    <TableRow sx={{ bgcolor: '#f8f9fa' }}>
                      <TableCell sx={{ fontWeight: 600, color: '#333' }}>ID</TableCell>
                      <TableCell sx={{ fontWeight: 600, color: '#333' }}>Título</TableCell>
                      <TableCell sx={{ fontWeight: 600, color: '#333' }}>Status</TableCell>
                      <TableCell sx={{ fontWeight: 600, color: '#333' }}>Email</TableCell>
                      <TableCell sx={{ fontWeight: 600, color: '#333' }}>Criado em</TableCell>
                      <TableCell sx={{ fontWeight: 600, color: '#333' }}>Atualizado em</TableCell>
                      <TableCell sx={{ fontWeight: 600, color: '#333' }}>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
                    {filteredContracts.map((contract) => (
                      <TableRow 
                        key={contract.id}
                        sx={{ 
                          '&:hover': { 
                            bgcolor: '#f8f9fa',
                            transition: 'background-color 0.2s'
                          }
                        }}
                      >
        <TableCell>{contract.id}</TableCell>
                        <TableCell sx={{ fontWeight: 500 }}>{contract.title}</TableCell>
                        <TableCell>
                          <Chip
                            label={contract.status}
                            size="small"
                            sx={{
                              bgcolor: contract.status === 'Assinado' ? '#4caf50' : 
                                     contract.status === 'Pendente' ? '#ff9800' : '#6A438B',
                              color: '#fff',
                              fontWeight: 500
                            }}
                          />
                        </TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {contract.signers.map((signer, index) => (
              <Tooltip title={`${signer.name} (${signer.email})`} key={signer.email}>
                <Avatar
                  sx={{
                                    width: 32,
                                    height: 32,
                                    marginLeft: index > 0 ? '-8px' : '0',
                                    backgroundColor: '#6A438B',
                                    color: '#fff',
                                    fontSize: '0.875rem',
                                    border: '2px solid #fff'
                  }}
                >
                  {getInitials(signer.name)}
                </Avatar>
              </Tooltip>
            ))}
          </Box>
        </TableCell>
        <TableCell>{formatDate(contract.createdAt)}</TableCell>
        <TableCell>{formatDate(contract.updatedAt)}</TableCell>
        <TableCell>
                          <Stack direction="row" spacing={1}>
                            <IconButton
                              onClick={() => handleView(contract.id)}
                              sx={{ 
                                color: '#6A438B',
                                '&:hover': { 
                                  bgcolor: 'rgba(106, 67, 139, 0.1)'
                                }
                              }}
                            >
                              <VisibilityOutlined />
                            </IconButton>
                            <IconButton
                              onClick={() => handleDownload(contract.id)}
                              sx={{ 
                                color: '#6A438B',
                                '&:hover': { 
                                  bgcolor: 'rgba(106, 67, 139, 0.1)'
                                }
                              }}
                            >
                              <DownloadOutlined />
                            </IconButton>
                            {tabIndex === 0 ? (
                              <IconButton
                                onClick={() => handleArchive(contract.id)}
                                sx={{ 
                                  color: '#6A438B',
                                  '&:hover': { 
                                    bgcolor: 'rgba(106, 67, 139, 0.1)'
                                  }
                                }}
                              >
                                <ArchiveOutlined />
          </IconButton>
                            ) : (
                              <IconButton
                                onClick={() => handleOpenDeleteModal(contract)}
                                sx={{ 
                                  color: '#ff5252',
                                  '&:hover': { 
                                    bgcolor: 'rgba(255, 82, 82, 0.1)'
                                  }
                                }}
                              >
                                <DeleteOutlined />
                              </IconButton>
                            )}
                          </Stack>
        </TableCell>
      </TableRow>
    ))}
</TableBody>
      </Table>
    </TableContainer>
            </Paper>
          )}
  </Box>
)}

      {tabIndex === 1 && (
        <Box sx={{ p: isMobile ? 1 : 3 }}>
          <Paper 
            elevation={0}
            sx={{ 
              p: isMobile ? 2 : 3, 
              mb: 2,
              borderRadius: 2,
              background: 'linear-gradient(135deg, #6A438B 0%, #6A438B 100%)',
              color: '#fff'
            }}
          >
            <Typography variant="h5" gutterBottom sx={{ 
              fontWeight: 600,
              fontSize: isMobile ? '1.25rem' : '1.5rem'
            }}>
              Novo Documento
          </Typography>
          </Paper>

          <Paper sx={{ p: isMobile ? 2 : 3, borderRadius: 2, border: '1px solid #e0e0e0' }}>
            <Stepper 
              activeStep={activeStep} 
              orientation={isMobile ? "vertical" : "horizontal"}
              sx={{ 
                mb: 4,
                '& .MuiStepLabel-root .Mui-completed': {
                  color: '#6A438B',
                },
                '& .MuiStepLabel-root .Mui-active': {
                  color: '#6A438B',
                },
              }}
            >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === 0 && (
  <Box>
    <TextField
                  label="Título do Documento"
      value={contractTitle}
      onChange={(e) => setContractTitle(e.target.value)}
      fullWidth
      required
                  size={isMobile ? "small" : "medium"}
                  sx={{ mb: 3 }}
    />
                <Typography variant="body1" gutterBottom sx={{ color: '#666' }}>
                  Envie o Arquivo do Documento. Aceitamos somente o formato .pdf
    </Typography>
                <Box 
                  sx={{ 
                    border: '2px dashed #e0e0e0',
                    borderRadius: 2,
                    p: isMobile ? 2 : 3,
                    textAlign: 'center',
                    mb: 3,
                    backgroundColor: '#fafafa',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      borderColor: '#6A438B',
                      backgroundColor: 'rgba(106, 67, 139, 0.04)',
                      cursor: 'pointer'
                    }
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onDrop={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const droppedFile = e.dataTransfer.files[0];
                    if (droppedFile && droppedFile.type === 'application/pdf') {
                      setfile(droppedFile);
                    } else {
                      toast.error('Por favor, selecione apenas arquivos PDF');
                    }
                  }}
                >
    <Input
      type="file"
                    accept=".pdf"
      onChange={handlefileChange}
                    sx={{ display: 'none' }}
                    id="file-input"
                  />
                  <label htmlFor="file-input">
                    <Box sx={{ cursor: 'pointer' }}>
                      {!file ? (
                        <>
                          <UploadFileIcon sx={{ 
                            fontSize: isMobile ? 36 : 48, 
                            color: '#6A438B', 
                            mb: 1 
                          }} />
                          <Typography variant="body1" gutterBottom>
                            {isMobile ? 'Selecionar arquivo' : 'Clique para selecionar ou arraste o arquivo aqui'}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Formato aceito: PDF
                          </Typography>
                        </>
                      ) : (
                        <Box sx={{ 
                          display: 'flex', 
                          flexDirection: 'column',
                          alignItems: 'center',
                          gap: 1
                        }}>
                          <PictureAsPdfIcon sx={{ 
                            fontSize: 48,
                            color: '#6A438B'
                          }} />
                          <Chip
                            label={file.name}
                            onDelete={() => setfile(null)}
                            deleteIcon={<CloseIcon />}
                            variant="outlined"
                            sx={{
                              maxWidth: '100%',
                              '& .MuiChip-label': {
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                maxWidth: isMobile ? '150px' : '300px'
                              },
                              borderColor: '#6A438B',
                              color: '#6A438B'
                            }}
                          />
                          <Typography variant="caption" color="textSecondary">
                            Clique para trocar o arquivo
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </label>
                </Box>
                <Button 
                  variant="contained" 
                  onClick={handleNextStep}
                  fullWidth={isMobile}
                  sx={{ 
                    bgcolor: '#6A438B',
                    '&:hover': {
                      bgcolor: '#5a3777'
                    }
                  }}
                >
      Próximo
    </Button>
  </Box>
)}

          {activeStep === 1 && (
            <Box>
                <Typography variant="h6" gutterBottom sx={{ 
                  color: '#333', 
                  mb: 3,
                  fontSize: isMobile ? '1.1rem' : '1.25rem'
                }}>
                  Insira os assinantes do Documento
              </Typography>

              {signers.map((signer, index) => (
                  <Paper
                    key={index}
                    sx={{ 
                      p: isMobile ? 1.5 : 2, 
                      mb: 2, 
                      borderRadius: 2,
                      border: '1px solid #e0e0e0'
                    }}
                  >
                    <Grid container spacing={isMobile ? 1 : 2} alignItems="center">
                      <Grid item xs={12} sm={3}>
    <TextField
      label="Nome"
      name="name"
      value={signer.name}
      onChange={(event) => handleSignerChange(index, event)}
                          fullWidth
      required
                          size={isMobile ? "small" : "medium"}
    />
                      </Grid>
                      <Grid item xs={12} sm={3}>
    <TextField
      label="E-mail"
      name="email"
      value={signer.email}
      onChange={(event) => handleSignerChange(index, event)}
                          fullWidth
      required
                          size={isMobile ? "small" : "medium"}
    />
                      </Grid>
                      <Grid item xs={12} sm={3}>
    <TextField
      label="CPF"
      name="cpf"
      value={signer.cpf}
      onChange={(event) => handleSignerChange(index, event)}
                          fullWidth
      required
                          size={isMobile ? "small" : "medium"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Stack 
                          direction="row" 
                          spacing={1} 
                          justifyContent={isMobile ? "center" : "flex-end"}
                          sx={{ mt: isMobile ? 1 : 0 }}
                        >
                          <IconButton
        onClick={() => handleAddSigner()}
                            sx={{ 
                              bgcolor: '#6A438B',
                              color: '#fff',
                              '&:hover': {
                                bgcolor: '#5a3777'
                              }
                            }}
      >
        <AddIcon />
                          </IconButton>
                          {index > 0 && (
                            <IconButton
        onClick={() => handleRemoveSigner(index)}
                              sx={{ 
                                bgcolor: '#ff5252',
                                color: '#fff',
                                '&:hover': {
                                  bgcolor: '#ff1744'
                                }
                              }}
      >
        <RemoveIcon />
                            </IconButton>
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Paper>
                ))}

                <Box sx={{ 
                  mt: 3, 
                  display: 'flex', 
                  gap: 2,
                  flexDirection: isMobile ? 'column' : 'row'
                }}>
                  <Button 
                    variant="outlined" 
                    onClick={handleBackStep}
                    fullWidth={isMobile}
                    sx={{ 
                      borderColor: '#6A438B',
                      color: '#6A438B',
                      '&:hover': {
                        borderColor: '#5a3777',
                        bgcolor: 'rgba(106, 67, 139, 0.1)'
                      }
                    }}
                  >
                  Voltar
                </Button>
                  <Button 
                    variant="contained" 
                    onClick={handleNextStep}
                    fullWidth={isMobile}
                    sx={{ 
                      bgcolor: '#6A438B',
                      '&:hover': {
                        bgcolor: '#5a3777'
                      }
                    }}
                  >
                  Próximo
                </Button>
              </Box>
            </Box>
          )}

          {activeStep === 2 && (
            <Box>
                <Typography variant="h6" gutterBottom sx={{ color: '#333', mb: 3 }}>
                  Confira as informações do Documento antes de enviar
              </Typography>
                
                <Paper sx={{ p: 3, mb: 3, borderRadius: 2, bgcolor: '#f8f9fa' }}>
                  <Typography variant="subtitle1" gutterBottom sx={{ color: '#666' }}>
                Arquivo: {file ? file.name : 'Nenhum Arquivo enviado.'}
              </Typography>
                  <Typography variant="subtitle1" gutterBottom sx={{ color: '#666', mt: 2 }}>
                Assinantes:
              </Typography>
                  <Stack spacing={1}>
              {signers.map((signer, index) => (
                      <Box 
                        key={index}
                        sx={{ 
                          p: 2, 
                          borderRadius: 1, 
                          bgcolor: '#fff',
                          border: '1px solid #e0e0e0'
                        }}
                      >
                        <Typography variant="body2">
                          <strong>Nome:</strong> {signer.name}
                        </Typography>
                        <Typography variant="body2">
                          <strong>CPF:</strong> {signer.cpf}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Email:</strong> {signer.email}
                </Typography>
                      </Box>
                    ))}
                  </Stack>
                </Paper>

                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Button 
                    variant="outlined" 
                    onClick={handleBackStep}
                    sx={{ 
                      borderColor: '#6A438B',
                      color: '#6A438B',
                      '&:hover': {
                        borderColor: '#5a3777',
                        bgcolor: 'rgba(106, 67, 139, 0.1)'
                      }
                    }}
                  >
                Voltar
              </Button>
                  <Button 
                    variant="contained"
                    onClick={handleSubmitContract}
                    sx={{ 
                      bgcolor: '#6A438B',
                      '&:hover': {
                        bgcolor: '#5a3777'
                      }
                    }}
                  >
                    Enviar Documento
                  </Button>
                </Box>
            </Box>
          )}
          </Paper>
        </Box>
      )}

      {tabIndex === 2 && (
        <Box sx={{ p: 3 }}>
          <Paper 
            elevation={0}
            sx={{ 
              p: 3, 
              mb: 3, 
              borderRadius: 2,
              background: 'linear-gradient(135deg, #6A438B 0%, #6A438B 100%)',
              color: '#fff'
            }}
          >
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
              Documentos Arquivados
            </Typography>

            <TextField
              label="Filtrar (ID, Título, Status, Email, Data)"
              value={filter}
              onChange={handleFilterChange}
              fullWidth
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderRadius: 1,
                '& .MuiOutlinedInput-root': {
                  color: '#fff',
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.3)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#FBDD23',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#FBDD23',
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Paper>

          <Paper 
            sx={{ 
              borderRadius: 2,
              overflow: 'hidden',
              border: '1px solid #e0e0e0',
              boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
            }}
          >
            <TableContainer>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow sx={{ bgcolor: '#f8f9fa' }}>
                    <TableCell sx={{ fontWeight: 600, color: '#333' }}>ID</TableCell>
                    <TableCell sx={{ fontWeight: 600, color: '#333' }}>Título</TableCell>
                    <TableCell sx={{ fontWeight: 600, color: '#333' }}>Status</TableCell>
                    <TableCell sx={{ fontWeight: 600, color: '#333' }}>Email</TableCell>
                    <TableCell sx={{ fontWeight: 600, color: '#333' }}>Criado em</TableCell>
                    <TableCell sx={{ fontWeight: 600, color: '#333' }}>Atualizado em</TableCell>
                    <TableCell sx={{ fontWeight: 600, color: '#333' }}>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredContracts.map((contract) => (
                    <TableRow 
                      key={contract.id}
                      sx={{ 
                        '&:hover': { 
                          bgcolor: '#f8f9fa',
                          transition: 'background-color 0.2s'
                        }
                      }}
                    >
                      <TableCell>{contract.id}</TableCell>
                      <TableCell sx={{ fontWeight: 500 }}>{contract.title}</TableCell>
                      <TableCell>
                        <Chip
                          label={contract.status}
                          size="small"
                          sx={{
                            bgcolor: '#6A438B',
                            color: '#fff',
                            fontWeight: 500
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {contract.signers.map((signer, index) => (
                            <Tooltip title={`${signer.name} (${signer.email})`} key={signer.email}>
                              <Avatar
                                sx={{
                                  width: 32,
                                  height: 32,
                                  marginLeft: index > 0 ? '-8px' : '0',
                                  backgroundColor: '#6A438B',
                                  color: '#fff',
                                  fontSize: '0.875rem',
                                  border: '2px solid #fff'
                                }}
                              >
                                {getInitials(signer.name)}
                              </Avatar>
                            </Tooltip>
                          ))}
                        </Box>
                      </TableCell>
                      <TableCell>{formatDate(contract.createdAt)}</TableCell>
                      <TableCell>{formatDate(contract.updatedAt)}</TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={1}>
                          <IconButton
                            onClick={() => handleView(contract.id)}
                            sx={{ 
                              color: '#6A438B',
                              '&:hover': { 
                                bgcolor: 'rgba(106, 67, 139, 0.1)'
                              }
                            }}
                          >
                            <VisibilityOutlined />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDownload(contract.id)}
                            sx={{ 
                              color: '#6A438B',
                              '&:hover': { 
                                bgcolor: 'rgba(106, 67, 139, 0.1)'
                              }
                            }}
                          >
                            <DownloadOutlined />
                          </IconButton>
                          <IconButton
                            onClick={() => handleOpenDeleteModal(contract)}
                            sx={{ 
                              color: '#ff5252',
                              '&:hover': { 
                                bgcolor: 'rgba(255, 82, 82, 0.1)'
                              }
                            }}
                          >
                            <DeleteOutlined />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      )}

      {/* Modal de confirmação de exclusão */}
      <Modal
        open={deleteModalOpen}
        onClose={handleCloseDeleteModal}
        aria-labelledby="modal-delete-confirmation"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isMobile ? '90%' : 400,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: isMobile ? 2 : 4
        }}>
          <Typography variant="h6" component="h2" gutterBottom sx={{ 
            color: '#333', 
            fontWeight: 600,
            fontSize: isMobile ? '1.1rem' : '1.25rem'
          }}>
            Confirmar Exclusão
          </Typography>
          <Typography sx={{ mt: 2, color: '#666' }}>
            Tem certeza que deseja excluir o Documento "{contractToDelete?.title}"?
            Esta ação não pode ser desfeita.
          </Typography>
          <Box sx={{ 
            mt: 3, 
            display: 'flex', 
            justifyContent: 'flex-end', 
            gap: 2,
            flexDirection: isMobile ? 'column' : 'row'
          }}>
            <Button 
              onClick={handleCloseDeleteModal} 
              variant="outlined"
              fullWidth={isMobile}
              sx={{ 
                borderColor: '#6A438B',
                color: '#6A438B',
                '&:hover': {
                  borderColor: '#5a3777',
                  bgcolor: 'rgba(106, 67, 139, 0.1)'
                }
              }}
            >
              Cancelar
            </Button>
            <Button 
              onClick={handleDeleteContract} 
              variant="contained" 
              color="error"
              fullWidth={isMobile}
              sx={{
                bgcolor: '#ff5252',
                '&:hover': {
                  bgcolor: '#ff1744'
                }
              }}
            >
              Excluir
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ContractSigningPlatform;