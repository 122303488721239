import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { 
  Box, 
  Typography, 
  Grid,
  Card,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Skeleton,
  useTheme,
  useMediaQuery,
  Divider,
  Stack
} from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  Visibility as VisibilityIcon,
  Download as DownloadIcon,
  AccessTime as AccessTimeIcon,
  Update as UpdateIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

const UserContracts = (props) => {
  const [contracts, setContracts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedContractId, setSelectedContractId] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchUserContracts = async () => {
      const userId = localStorage.getItem('userId');

      if (!userId) {
        setError('User ID não encontrado. Faça login novamente.');
        setLoading(false);
        return;
      }

      try {
        const userResponse = await axios.get(`/users/${userId}`);
        const userEmail = userResponse.data.email;

        const contractsResponse = await axios.get('/contracts');
        const userContracts = contractsResponse.data.filter(contract => 
          contract.signers.some(signer => signer.email.trim().toLowerCase() === userEmail.trim().toLowerCase())
        );
        console.log('Documentos:', userEmail);
        setContracts(userContracts);
      } catch (error) {
        console.error('Erro ao buscar Documentos:', error);
        setError('Erro ao carregar Documentos.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserContracts();
  }, []);

  const handleMenuClick = (event, contractId) => {
    setAnchorEl(event.currentTarget);
    setSelectedContractId(contractId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleView = (contract) => {
    navigate(`/sign/${contract.id}`);
    handleMenuClose();
  };

  const handleDownload = (contract) => {
    if (contract) {
      props.handleDownload(contract);
      handleMenuClose();
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Assinado':
        return 'success';
      case 'Pendente':
        return 'warning';
      default:
        return 'default';
    }
  };

  if (loading) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
          Meus Documentos
        </Typography>
        <Grid container spacing={3}>
          {[1, 2, 3].map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item}>
              <Card>
                <CardContent>
                  <Skeleton variant="text" width="60%" height={32} />
                  <Skeleton variant="text" width="40%" />
                  <Skeleton variant="text" width="30%" />
                  <Box sx={{ mt: 2 }}>
                    <Skeleton variant="rectangular" height={32} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error" variant="h6">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
     Documentos para assinar
      </Typography>
      
      <Grid container spacing={3}>
        {contracts.map((contract) => (
          <Grid item xs={12} sm={6} md={4} key={contract.id}>
            <Card 
              sx={{ 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: theme.shadows[4]
                }
              }}
            >
              <CardContent sx={{ flex: 1, p: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                  <Typography variant="h6" component="h2" sx={{ 
                    fontWeight: 'medium',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    minHeight: 56
                  }}>
                    {contract.title}
                  </Typography>
                  <IconButton 
                    size="small"
                    onClick={(event) => handleMenuClick(event, contract.id)}
                    sx={{ ml: 1 }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Box>

                <Stack spacing={2}>
                  <Box>
                    <Chip 
                      label={contract.status}
                      color={getStatusColor(contract.status)}
                      size="small"
                      sx={{ fontWeight: 500 }}
                    />
                  </Box>

                  <Divider />

                  <Stack spacing={1}>
                    <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
                      <AccessTimeIcon sx={{ fontSize: 20, mr: 1 }} />
                      <Typography variant="body2">
                        Criado {moment(contract.createdAt).fromNow()}
                      </Typography>
                    </Box>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
                      <UpdateIcon sx={{ fontSize: 20, mr: 1 }} />
                      <Typography variant="body2">
                        Atualizado {moment(contract.updatedAt).fromNow()}
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem 
          onClick={() => handleView(contracts.find(c => c.id === selectedContractId))}
          sx={{ minWidth: 150 }}
        >
          <ListItemIcon>
            <VisibilityIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Visualizar</ListItemText>
        </MenuItem>
        {contracts.find(c => c.id === selectedContractId)?.status === 'Assinado' && (
          <MenuItem 
            onClick={() => handleDownload(contracts.find(c => c.id === selectedContractId))}
          >
            <ListItemIcon>
              <DownloadIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Baixar Documento</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

export default UserContracts;