import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { 
    Box, 
    Typography, 
    Button, 
    Grid, 
    TextField, 
    Modal,
    Paper,
    Divider,
    Stack,
    Chip
} from '@mui/material';
import { 
    VisibilityOutlined,
    DownloadOutlined,
    AssignmentIndOutlined,
    HowToRegOutlined
} from '@mui/icons-material';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/pt-br';

// Configurar worker do PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ContractSigning = () => {
    const { contractId } = useParams();
    const [contract, setContract] = useState(null);
    const [signerInfo, setSignerInfo] = useState({ name: '', email: '', cpf: '' });
    const [numPages, setNumPages] = useState(null);
    const [pdfUrl, setPdfUrl] = useState('');
    const [error, setError] = useState(null);
    const [allSigners, setAllSigners] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [userIp, setUserIp] = useState('');

    // Efeito para limpar a URL do blob quando o componente é desmontado
    useEffect(() => {
        return () => {
            if (pdfUrl && pdfUrl.startsWith('blob:')) {
                URL.revokeObjectURL(pdfUrl);
            }
        };
    }, [pdfUrl]);

    // Efeito para buscar os dados do Documento
    useEffect(() => {
        const fetchContract = async () => {
            try {
                console.log('Iniciando busca do contrato com ID:', contractId);
                
                // Buscar IP do usuário
                const ipResponse = await axios.get('https://api.ipify.org?format=json');
                setUserIp(ipResponse.data.ip);

                // Pegar o userId do localStorage
                const userId = localStorage.getItem('userId');
                console.log('ID do usuário atual:', userId);

                // Buscar dados do usuário
                const userResponse = await axios.get(`/users/${userId}`);
                const userEmail = userResponse.data.email;
                console.log('Email do usuário atual:', userEmail);

                console.log('Fazendo requisição para:', `/contracts/${contractId}`);
                const response = await axios.get(`/contracts/${contractId}`);
                console.log('Resposta do Documento:', response.data);
                
                if (!response.data) {
                    throw new Error('Contrato não encontrado');
                }
                
                const documentPath = response.data.documentPath;
                console.log('Caminho do documento:', documentPath);
                
                if (!documentPath) {
                    throw new Error('Caminho do documento não encontrado');
                }
                
                // Buscar o PDF como blob
                console.log('Buscando PDF em:', `/uploads/${documentPath}`);
                const pdfResponse = await axios.get(`/uploads/${documentPath}`, {
                    responseType: 'blob',
                    headers: {
                        'Accept': 'application/pdf'
                    }
                });
                
                // Criar URL do blob
                const pdfBlob = new Blob([pdfResponse.data], { type: 'application/pdf' });
                const newPdfUrl = URL.createObjectURL(pdfBlob);
                console.log('URL do PDF criada:', newPdfUrl);
                setPdfUrl(newPdfUrl);
                setContract(response.data);

                // Encontrar o signatário com o email do usuário atual
                const currentSigner = response.data.signers.find(s => s.email === userEmail);
                console.log('Signatário atual:', currentSigner);

                if (currentSigner) {
                    console.log('Preenchendo formulário com dados do usuário atual:', currentSigner);
                    setSignerInfo({
                        name: currentSigner.name,
                        email: currentSigner.email,
                        cpf: currentSigner.cpf,
                    });
                } else {
                    console.log('Usuário atual não é um signatário deste documento');
                    setSignerInfo({
                        name: '',
                        email: '',
                        cpf: '',
                    });
                    setError('Você não está autorizado a assinar este documento.');
                }

                setAllSigners(response.data.signers);
            } catch (error) {
                console.error('Erro ao buscar Documento:', error);
                setError('Erro ao carregar o Documento.');
            }
        };

        if (contractId) {
            fetchContract();
        }
    }, [contractId]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setError(null); // Limpar erro quando o PDF carregar com sucesso
    };

    const onDocumentLoadError = (error) => {
        console.error('Erro ao carregar PDF:', error);
        setError('Erro ao carregar o PDF. Por favor, tente novamente mais tarde.');
    };

    const handleSign = async () => {
        try {
            // Validar dados do signatário
            if (!signerInfo.email || !signerInfo.name || !signerInfo.cpf) {
                toast.error('Por favor, preencha todos os campos.');
                return;
            }

            const ip = await axios.get('https://api.ipify.org?format=json');
            const signData = {
                email: signerInfo.email,
                name: signerInfo.name,
                cpf: signerInfo.cpf,
                ip: ip.data.ip
            };
            
            console.log('Enviando dados para assinatura:', signData);
            
            const response = await axios.put(`/contracts/${contractId}/update-signer`, {
                ...signData,
                userId: 1 // ID do usuário atual
            });

            console.log('Resposta da assinatura:', response.data);
            toast.success(response.data.message || 'Documento assinado com sucesso!');
            
            // Recarregar a página após 1 segundo
            setTimeout(() => window.location.reload(), 1000);
        } catch (error) {
            console.error('Erro ao atualizar assinante:', error);
            const errorMessage = error.response?.data?.error || 'Erro ao assinar o Documento.';
            console.error('Mensagem de erro:', errorMessage);
            toast.error(errorMessage);
        }
    };

    const handleDownload = async () => {
        console.log("Iniciando o download...");
        if (!signerInfo) {
            console.log("Nenhum dado do assinante encontrado.");
            return;
        }

        try {
            // Obtendo o IP do usuário
            const ipResponse = await axios.get('https://api.ipify.org?format=json');
            const userIp = ipResponse.data.ip;

            // Obtendo o PDF existente
            const existingPdfBytes = await fetch(pdfUrl).then(res => {
                if (!res.ok) {
                    throw new Error('Erro ao carregar o PDF: ' + res.statusText);
                }
                return res.arrayBuffer();
            });
            console.log("PDF existente carregado.");
            const pdfDoc = await PDFDocument.load(existingPdfBytes);

            // Embedding a fonte Helvetica-Bold
            const helveticaBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

            // Criando uma nova página
            const page = pdfDoc.addPage([600, 800]); // Tamanho da página (largura, altura)

            // Adicionando título
            page.drawText('Declaração de Assinatura Eletrônica', {
                x: 50,
                y: 750,
                size: 20,
                color: rgb(0, 0, 0),
                font: helveticaBoldFont,
            });

            // Adicionando uma linha de separação
            page.drawLine({
                start: { x: 50, y: 740 },
                end: { x: 550, y: 740 },
                thickness: 1,
                color: rgb(0, 0, 0),
            });

            // Adicionando texto para cada assinante
            let yPosition = 700;
            allSigners.forEach((signer) => {
                page.drawText(`Assinante:`, {
                    x: 50,
                    y: yPosition,
                    size: 14,
                    color: rgb(0, 0, 0)
                });
                yPosition -= 30;

                const textCertificado = [
                    `Eu, ${signer.name}, declaro que estou ciente e de acordo com os termos deste documento.`,
                    `Ao clicar no botão de assinatura, confirmo a veracidade das informações e minha`,
                    `concordância com o conteúdo, com a validade jurídica de uma assinatura eletrônica,`,
                    `conforme a Lei nº 14.063/2020.`
                ];

                textCertificado.forEach(line => {
                    page.drawText(line, {
                        x: 70,
                        y: yPosition,
                        size: 12,
                        color: rgb(0, 0, 0)
                    });
                    yPosition -= 15;
                });

                yPosition -= 15;

                page.drawText(`CPF: ${signer.cpf}`, {
                    x: 70,
                    y: yPosition,
                    size: 12,
                    color: rgb(0, 0, 0),
                    font: helveticaBoldFont,
                });
                yPosition -= 15;
                page.drawText(`Email: ${signer.email}`, {
                    x: 70,
                    y: yPosition,
                    size: 12,
                    color: rgb(0, 0, 0),
                    font: helveticaBoldFont,
                });
                yPosition -= 15;
                page.drawText(`IP: ${userIp}`, {
                    x: 70,
                    y: yPosition,
                    size: 12,
                    color: rgb(0, 0, 0),
                    font: helveticaBoldFont,
                });
                yPosition -= 15;

                // Formatando a data
                const dataAssinatura = signer.signedAt ? moment(signer.signedAt).format('DD/MM/YYYY [às] HH:mm:ss') : 'Não assinado';
                page.drawText(`Data: ${dataAssinatura}`, {
                    x: 70,
                    y: yPosition,
                    size: 12,
                    color: rgb(0, 0, 0),
                    font: helveticaBoldFont,
                });
                yPosition -= 40; // Espaço entre assinantes
            });

            // Serializando o PDF modificado
            const pdfBytes = await pdfDoc.save();
            console.log("PDF modificado salvo.");

            // Criando um blob e um link para download
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download =  'Documento_Com_Certificado.pdf';
            document.body.appendChild(a); // Adiciona o link ao DOM
            a.click();
            document.body.removeChild(a); // Remove o link do DOM
            URL.revokeObjectURL(url);
            console.log("Download iniciado.");
        } catch (error) {
            console.error("Erro ao baixar o Documento:", error);
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleViewPDF = () => {
        if (pdfUrl) {
            // Abrir o PDF em uma nova aba usando a URL do blob já criada
            window.open(pdfUrl, '_blank');
        }
    };

    const handleViewDeclarations = () => {
        setModalOpen(true);
    };

    // Função para gerar a declaração de um signatário
    const generateSignerDeclaration = (signer, userIp) => {
        return (
            <Box key={signer.id} sx={{ mb: 4, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
                <Typography variant="h6" gutterBottom>Assinante</Typography>
                <Typography paragraph>
                    Eu, {signer.name}, declaro que estou ciente e de acordo com os termos deste documento.
                    Ao clicar no botão de assinatura, confirmo a veracidade das informações e minha
                    concordância com o conteúdo, com a validade jurídica de uma assinatura eletrônica,
                    conforme a Lei nº 14.063/2020.
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography><strong>CPF:</strong> {signer.cpf}</Typography>
                        <Typography><strong>Email:</strong> {signer.email}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography><strong>IP:</strong> {signer.ip || userIp}</Typography>
                        <Typography>
                            <strong>Data:</strong> {signer.signedAt ? 
                                moment(signer.signedAt).format('DD/MM/YYYY [às] HH:mm:ss') : 
                                'Não assinado'}
                        </Typography>
                    </Grid>
                </Grid>
                <Box sx={{ mt: 1 }}>
                    <Chip 
                        label={signer.signed ? "Assinado" : "Pendente"} 
                        color={signer.signed ? "success" : "warning"}
                        size="small"
                    />
                </Box>
            </Box>
        );
    };

    return (
        <Box sx={{ p: 3, maxWidth: '1400px', margin: '0 auto' }}>
            <ToastContainer />
            {error && (
                <Paper sx={{ p: 2, mb: 2, bgcolor: '#FFF3F0' }}>
                    <Typography color="error">{error}</Typography>
                </Paper>
            )}
           
            {contract && (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, mb: 2 }}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Box>
                                    <Typography variant="h5" component="h1" gutterBottom>
                                        {contract.title}
                                    </Typography>
                                    <Typography 
                                        variant="subtitle1" 
                                        sx={{ 
                                            color: contract.status === 'Assinado' ? 'success.main' : 'warning.main',
                                            fontWeight: 'medium'
                                        }}
                                    >
                                        Status: {contract.status}
                                    </Typography>
                                </Box>
                                <Stack direction="row" spacing={2}>
                                    <Button
                                        variant="outlined"
                                        startIcon={<VisibilityOutlined />}
                                        onClick={handleViewPDF}
                                    >
                                        Ler Documento
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        startIcon={<AssignmentIndOutlined />}
                                        onClick={handleViewDeclarations}
                                    >
                                       Visualizar Declarações
                                    </Button>
                                    {contract.status === 'Assinado' && (
                                        <Button
                                            variant="contained"
                                            startIcon={<DownloadOutlined />}
                                            onClick={handleDownload}
                                        >
                                            Baixar Documento assinado
                                        </Button>
                                    )}
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <Paper sx={{ height: '80vh', overflow: 'hidden' }}>
                            {pdfUrl ? (
                                <Box sx={{ 
                                    height: '100%', 
                                    overflow: 'auto',
                                    bgcolor: '#f5f5f5',
                                    p: 2
                                }}>
                                    <Document
                                        file={pdfUrl}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                        onLoadError={onDocumentLoadError}
                                        loading={
                                            <Box sx={{ 
                                                display: 'flex', 
                                                justifyContent: 'center', 
                                                alignItems: 'center', 
                                                height: '100%' 
                                            }}>
                                                <Typography>Carregando PDF...</Typography>
                                            </Box>
                                        }
                                    >
                                        {Array.from(new Array(numPages), (el, index) => (
                                            <Box sx={{ mb: 2, bgcolor: 'white', boxShadow: 1 }}>
                                                <Page 
                                                    key={`page_${index + 1}`} 
                                                    pageNumber={index + 1} 
                                                    width={window.innerWidth * 0.45}
                                                    renderTextLayer={false}
                                                    renderAnnotationLayer={false}
                                                />
                                            </Box>
                                        ))}
                                    </Document>
                                </Box>
                            ) : (
                                <Box sx={{ 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center', 
                                    height: '100%' 
                                }}>
                                    <Typography>Carregando Documento...</Typography>
                                </Box>
                            )}
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Paper sx={{ p: 3 }}>
                            {contract.status !== 'Assinado' && (
                                <>
                                    <Typography variant="h6" gutterBottom>
                                        Dados do Assinante
                                    </Typography>
                                    <Divider sx={{ mb: 2 }} />
                                    {signerInfo.email ? (
                                        <Stack spacing={2}>
                                            <TextField
                                                fullWidth
                                                label="Nome"
                                                value={signerInfo.name}
                                                variant="outlined"
                                                disabled={true}
                                            />
                                            <TextField
                                                fullWidth
                                                label="Email"
                                                value={signerInfo.email}
                                                variant="outlined"
                                                disabled={true}
                                            />
                                            <TextField
                                                fullWidth
                                                label="CPF"
                                                value={signerInfo.cpf}
                                                variant="outlined"
                                                disabled={true}
                                            />
                                            <Button
                                                variant="contained"
                                                onClick={handleSign}
                                                startIcon={<HowToRegOutlined />}
                                                size="large"
                                                color="primary"
                                                sx={{ mt: 2 }}
                                                disabled={contract.status === 'Assinado'}
                                            >
                                                Assinar Documento
                                            </Button>
                                        </Stack>
                                    ) : (
                                        <Typography color="error" align="center">
                                            Você não está autorizado a assinar este documento.
                                        </Typography>
                                    )}
                                </>
                            )}

                            <Box sx={{ mt: contract.status !== 'Assinado' ? 3 : 0 }}>
                                <Typography variant="h6" gutterBottom>
                                    Status das Assinaturas
                                </Typography>
                                <Divider sx={{ mb: 2 }} />
                                {allSigners.map((signer, index) => (
                                    <Box 
                                        key={index} 
                                        sx={{ 
                                            mb: 2, 
                                            p: 2, 
                                            bgcolor: '#f5f5f5', 
                                            borderRadius: 1,
                                            border: signer.signed ? '1px solid #4caf50' : '1px solid #ff9800'
                                        }}
                                    >
                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{signer.name}</Typography>
                                        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                                            {signer.signed ? 
                                                `Assinado em ${moment(signer.signedAt).format('DD/MM/YYYY [às] HH:mm')}` : 
                                                'Aguardando assinatura'}
                                        </Typography>
                                        <Chip 
                                            label={signer.signed ? "Assinado" : "Pendente"} 
                                            color={signer.signed ? "success" : "warning"}
                                            size="small"
                                            sx={{ mt: 1 }}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            )}

            {/* Modal de Declarações */}
            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-declarations"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    maxWidth: 800,
                    maxHeight: '90vh',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    overflow: 'auto',
                    borderRadius: 2
                }}>
                    <Typography variant="h5" component="h2" gutterBottom>
                        Declarações de Assinatura
                    </Typography>
                    <Divider sx={{ mb: 3 }} />
                    
                    {allSigners.some(signer => signer.signed) ? (
                        allSigners
                            .filter(signer => signer.signed)
                            .map(signer => generateSignerDeclaration(signer, userIp))
                    ) : (
                        <Box sx={{ textAlign: 'center', py: 4 }}>
                            <Typography color="text.secondary">
                                Não há registros de assinaturas para este documento.
                            </Typography>
                        </Box>
                    )}

                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleCloseModal} variant="outlined">
                            Fechar
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default ContractSigning;