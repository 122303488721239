import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import { 
  TextField, 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Box, 
  Typography, 
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
  IconButton
} from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import axios from 'axios';
import 'react-calendar/dist/Calendar.css';
import 'react-toastify/dist/ReactToastify.css';
import Confetti from 'react-confetti';
import './CalendarWithEvents.css';
import { Close, Delete, Edit } from '@mui/icons-material';

const CalendarWithEvents = () => {
  const [date, setDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [events, setEvents] = useState({});
  const [holidays, setHolidays] = useState({});
  
  const [openDialog, setOpenDialog] = useState(false);
  const [viewDate, setViewDate] = useState(new Date());
  const [users, setUsers] = useState([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const [congratsDialog, setCongratsDialog] = useState({ open: false, message: '', title: '' });
  const [currentUser, setCurrentUser] = useState(null);
  const [isCompanyEvent, setIsCompanyEvent] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [newEvent, setNewEvent] = useState({ 
    id: null,
    title: '',
    note: '',
    startTime: '',
    endTime: ''
  });
  const [showEventModal, setShowEventModal] = useState(false);

  // Fetch user ID, CNPJ and check if is RH
  useEffect(() => {
    const fetchData = async () => {
      // Buscar dados do usuário
      const userId = localStorage.getItem('userId');
      if (!userId) {
        console.error('ID do usuário não encontrado');
        return;
      }

      try {
        // Buscar dados do usuário
        const userResponse = await axios.get(`/users/${userId}`);
        const user = userResponse.data;
        setCurrentUser(user);

        // Buscar usuários da empresa
        const companyResponse = await axios.get(`/users`);
        const companyUsers = companyResponse.data.filter(u => u.id_cnpj === user.id_cnpj);
        setUsers(companyUsers);

      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        toast.error(error.response?.data?.error || 'Erro ao carregar dados');
      }
    };

    fetchData();
  }, []);

  // Fetch events and holidays based on viewDate
  useEffect(() => {
    if (!currentUser?.iduser) return;

    const fetchEventsAndHolidays = async () => {
      try {
        const year = viewDate.getFullYear();
        const month = viewDate.getMonth() + 1;

        // Limpar os estados antes de buscar novos dados
        setEvents({});
        setHolidays({});

        // Buscar eventos
        const eventsResponse = await axios.get(`/events/${year}/${month}/${currentUser.iduser}`);
        if (eventsResponse.data) {
          setEvents(eventsResponse.data);
        }

        // Buscar feriados da API Brasil
        try {
          const holidaysResponse = await fetch(`https://brasilapi.com.br/api/feriados/v1/${year}`);
          if (holidaysResponse.ok) {
            const holidays = await holidaysResponse.json();
            const formattedHolidays = {};
            
            // Filtrar apenas os feriados do mês atual
            holidays
              .filter(holiday => {
                const [holidayYear, holidayMonth] = holiday.date.split('-').map(Number);
                return holidayMonth === month;
              })
              .forEach(holiday => {
                const holidayDate = new Date(holiday.date + 'T12:00:00');
                const dateKey = format(holidayDate, 'dd/MM/yyyy');
                if (!formattedHolidays[dateKey]) {
                  formattedHolidays[dateKey] = [];
                }
                formattedHolidays[dateKey].push({
                  id: `holiday-${holiday.date}`,
                  title: `Feriado: ${holiday.name}`,
                  note: holiday.name,
                  startTime: holidayDate.toISOString(),
                  endTime: holidayDate.toISOString(),
                  type: 'holiday',
                  isHoliday: true
                });
              });
            
            setHolidays(formattedHolidays);
          }
        } catch (error) {
          console.error('Erro ao buscar feriados:', error);
        }

      } catch (error) {
        console.error('Erro ao buscar eventos:', error);
        toast.error('Erro ao carregar eventos');
      }
    };

    fetchEventsAndHolidays();
  }, [viewDate, currentUser]);

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const handleDayClick = (value) => {
    const dateKey = format(value, 'dd/MM/yyyy');
    const specialEvents = events[dateKey]?.filter(event => event.type === 'birthday' || event.type === 'anniversary');
    
    if (specialEvents?.length > 0) {
      const event = specialEvents[0];
      setCongratsDialog({
        open: true,
        title: event.type === 'birthday' ? 'Feliz Aniversário! 🎂' : 'Parabéns! 🎉',
        message: event.note
      });
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 5000);
    } else {
      setSelectedDate(value);
      setNewEvent({
        id: null,
        title: '',
        note: '',
        startTime: '09:00',
        endTime: '18:00'
      });
      setIsCompanyEvent(false);
      setSelectedUser(null);
      setOpenDialog(true);
    }
  };

  const handleViewChange = ({ activeStartDate }) => {
    if (!(activeStartDate instanceof Date)) {
      console.error('activeStartDate não é um objeto Date:', activeStartDate);
      return;
    }
    
    // Atualizar a data de visualização
    setViewDate(activeStartDate);
  };

  const handleSaveEvent = async () => {
    if (!newEvent.title || !selectedDate) {
      toast.error('Por favor, preencha o título e selecione uma data');
      return;
    }

    try {
      // Criar data/hora de início e fim
      const [startHours, startMinutes] = newEvent.startTime.split(':').map(Number);
      const [endHours, endMinutes] = newEvent.endTime.split(':').map(Number);

      const startDate = new Date(selectedDate);
      startDate.setHours(startHours || 0);
      startDate.setMinutes(startMinutes || 0);

      const endDate = new Date(selectedDate);
      endDate.setHours(endHours || 23);
      endDate.setMinutes(endMinutes || 59);

      // Validar se as datas são válidas
      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        toast.error('Data/hora inválida');
        return;
      }

      if (endDate <= startDate) {
        toast.error('A hora de fim deve ser posterior à hora de início');
        return;
      }

      // Formatar a data no formato correto YYYY-MM-DD
      const dateKey = format(startDate, 'yyyy-MM-dd');

      const eventData = {
        title: newEvent.title,
        note: newEvent.note || '',
        start_time: startDate.toISOString(),
        end_time: endDate.toISOString(),
        date_key: dateKey,
        id_cnpj: currentUser.id_cnpj,
        is_company_event: isCompanyEvent,
        user_id: currentUser.iduser,
        target_user_id: selectedUser || null
      };

      console.log('Salvando evento:', eventData);
      
      const response = await axios.post('/events', eventData);
      console.log('Resposta do servidor:', response.data);
      
      // Atualizar lista de eventos
      const year = startDate.getFullYear();
      const month = startDate.getMonth() + 1;
      const eventsResponse = await axios.get(`/events/${year}/${month}/${currentUser.iduser}`);
      setEvents(eventsResponse.data);
      
      setNewEvent({ title: '', note: '', startTime: '', endTime: '' });
      setIsCompanyEvent(false);
      setSelectedUser(null);
      setOpenDialog(false);
      toast.success('Evento criado com sucesso!');

    } catch (error) {
      console.error('Erro ao salvar evento:', error);
      const errorMessage = error.response?.data?.message || 
                          error.response?.data?.error || 
                          'Erro ao salvar evento';
      toast.error(errorMessage);
    }
  };

  const handleDeleteEvent = async (eventId) => {
    if (!eventId) return;
    
    try {
      await axios.delete(`/events/${eventId}`);
      
      // Atualizar lista de eventos
      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth() + 1;
      const eventsResponse = await axios.get(`/events/${year}/${month}/${currentUser.iduser}`);
      setEvents(eventsResponse.data);
      
      setShowEventModal(false);
      toast.success('Evento excluído com sucesso!');
    } catch (error) {
      console.error('Erro ao excluir evento:', error);
      toast.error(error.response?.data?.error || 'Erro ao excluir evento');
    }
  };

  const handleUpdateEvent = async () => {
    if (!newEvent.title || !selectedDate || !newEvent.id) {
      toast.error('Por favor, preencha o título e selecione uma data');
      return;
    }

    try {
      // Criar data/hora de início e fim
      const [startHours, startMinutes] = newEvent.startTime.split(':').map(Number);
      const [endHours, endMinutes] = newEvent.endTime.split(':').map(Number);

      const startDate = new Date(selectedDate);
      startDate.setHours(startHours || 0);
      startDate.setMinutes(startMinutes || 0);

      const endDate = new Date(selectedDate);
      endDate.setHours(endHours || 23);
      endDate.setMinutes(endMinutes || 59);

      // Validar se as datas são válidas
      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        toast.error('Data/hora inválida');
        return;
      }

      if (endDate <= startDate) {
        toast.error('A hora de fim deve ser posterior à hora de início');
        return;
      }

      const dateKey = format(startDate, 'yyyy-MM-dd');

      const eventData = {
        title: newEvent.title,
        note: newEvent.note || '',
        start_time: startDate.toISOString(),
        end_time: endDate.toISOString(),
        date_key: dateKey,
        is_company_event: isCompanyEvent,
        target_user_id: selectedUser || null
      };

      console.log('Atualizando evento:', eventData);
      
      const response = await axios.put(`/events/${newEvent.id}`, eventData);
      console.log('Resposta do servidor:', response.data);
      
      // Atualizar lista de eventos
      const year = startDate.getFullYear();
      const month = startDate.getMonth() + 1;
      const eventsResponse = await axios.get(`/events/${year}/${month}/${currentUser.iduser}`);
      setEvents(eventsResponse.data);
      
      setNewEvent({ title: '', note: '', startTime: '', endTime: '' });
      setIsCompanyEvent(false);
      setSelectedUser(null);
      setShowEventModal(false);
      toast.success('Evento atualizado com sucesso!');

    } catch (error) {
      console.error('Erro ao atualizar evento:', error);
      const errorMessage = error.response?.data?.message || 
                          error.response?.data?.error || 
                          'Erro ao atualizar evento';
      toast.error(errorMessage);
    }
  };

  const handleEventClick = (event) => {
    setSelectedDate(new Date(event.startTime));
    setNewEvent({
      id: event.id,
      title: event.title,
      note: event.note,
      startTime: format(new Date(event.startTime), 'HH:mm'),
      endTime: format(new Date(event.endTime), 'HH:mm')
    });
    setIsCompanyEvent(event.isCompanyEvent);
    setSelectedUser(event.targetUserId);
    setShowEventModal(true);
  };

  const handleCloseEventModal = () => {
    setShowEventModal(false);
    setNewEvent({ 
      id: null,
      title: '', 
      note: '', 
      startTime: '', 
      endTime: '' 
    });
    setIsCompanyEvent(false);
    setSelectedUser(null);
  };

  const checkSpecialEvents = (date) => {
    const dateKey = format(date, 'dd/MM/yyyy');
    const dayEvents = events[dateKey] || [];
    
    return dayEvents.filter(event => 
      event.type === 'birthday' || event.type === 'anniversary'
    );
  };

  const tileContent = ({ date }) => {
    const dateKey = format(date, 'dd/MM/yyyy');
    const dayEvents = events[dateKey] || [];
    const dayHolidays = holidays[dateKey] || [];
    const allEvents = [...dayEvents, ...dayHolidays];
    const specialEvents = checkSpecialEvents(date);

    if (allEvents.length === 0) return null;

    return (
      <Box sx={{ 
        position: 'absolute',
        bottom: 2,
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        gap: 0.5
      }}>
        {allEvents.map((_, index) => (
          <Box
            key={index}
            sx={{
              width: 4,
              height: 4,
              borderRadius: '50%',
              backgroundColor: specialEvents.length > 0 ? '#FFD700' : '#6A438B'
            }}
          />
        ))}
      </Box>
    );
  };

  const getEventsForMonth = () => {
    if (!(viewDate instanceof Date)) {
      return <Typography variant="body2">Nenhum evento para este mês.</Typography>;
    }

    const monthStart = startOfMonth(viewDate);
    const monthEnd = endOfMonth(viewDate);
    
    // Criar um objeto unificado de eventos
    const allEvents = { ...events };
    
    // Adicionar feriados apenas se não existirem eventos na mesma data
    Object.entries(holidays).forEach(([dateKey, holidayEvents]) => {
      if (!allEvents[dateKey]) {
        allEvents[dateKey] = holidayEvents;
      } else {
        // Se já existem eventos, adicionar apenas os feriados que não estão duplicados
        const existingEventIds = new Set(allEvents[dateKey].map(e => e.id));
        const uniqueHolidays = holidayEvents.filter(h => !existingEventIds.has(h.id));
        allEvents[dateKey] = [...allEvents[dateKey], ...uniqueHolidays];
      }
    });

    const eventsInMonth = Object.entries(allEvents)
      .filter(([dateKey]) => {
        const [day, month, year] = dateKey.split('/').map(Number);
        const eventDate = new Date(year, month - 1, day);
        return eventDate >= monthStart && eventDate <= monthEnd;
      })
      .map(([dateKey, dayEvents]) => 
        dayEvents.map((event, index) => (
          <Box key={`${dateKey}-${event.id}-${index}`} mb={1} sx={{ position: 'relative' }}>
            <Typography variant="body2" sx={{ 
              backgroundColor: event.type === 'birthday' ? '#FFD700' : 
                             event.type === 'anniversary' ? '#9370DB' : 
                             '#6A438B',
              fontWeight: "600", 
              color: event.type ? '#FDEE00' : '#fff', 
              padding: 0.3,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              {event.title || event.note}
              {!event.isHoliday && (
                <Box>
                  <IconButton 
                    size="small" 
                    onClick={() => handleEventClick(event)}
                    sx={{ color: '#000' }}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                  <IconButton 
                    size="small" 
                    onClick={() => handleDeleteEvent(event.id)}
                    sx={{ color: '#000' }}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Box>
              )}
            </Typography>
            <Typography variant="body2" sx={{ backgroundColor: '#ead3ff', color: '#3f2854', padding: 0.3 }}>
              Data: {dateKey}
            </Typography>
            {event.startTime && event.endTime && (
              <Typography variant="body2" sx={{ backgroundColor: '#daafff', color: '#3f2854', padding: 0.3 }}>
                Horário: {format(new Date(event.startTime), 'HH:mm')} - {format(new Date(event.endTime), 'HH:mm')}
              </Typography>
            )}
            {event.note && event.title && (
              <Typography variant="body2" sx={{ backgroundColor: '#f0e6ff', color: '#3f2854', padding: 0.3 }}>
                Obs: {event.note}
              </Typography>
            )}
          </Box>
        ))
      );

    return eventsInMonth.length > 0 ? eventsInMonth.flat() : <Typography variant="body2">Nenhum evento para este mês.</Typography>;
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewEvent({
      id: null,
      title: '',
      note: '',
      startTime: '',
      endTime: ''
    });
    setIsCompanyEvent(false);
    setSelectedUser(null);
  };

  return (
    <Grid container justifyContent="center" spacing={2} sx={{ p: 2 }}>
      {showConfetti && <Confetti />}
      <Grid item xs={12} md={8}>
        <Box sx={{ 
          width: '100%',
          '& .react-calendar': {
            width: '100%',
            maxWidth: '100%',
            backgroundColor: '#fff',
            border: '1px solid #a0a096',
            fontFamily: 'Arial, Helvetica, sans-serif',
            lineHeight: '1.125em',
            padding: '0px',
            borderRadius: '8px',
            boxShadow: 3,
            minHeight: '500px'
          },
          '& .react-calendar__tile': {
            position: 'relative',
            height: '80px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '20px 6px'
          },
          '& .react-calendar__month-view__days__day': {
            padding: '8px',
            position: 'relative'
          },
          '& .highlight': {
            backgroundColor: '#f0e6ff'
          },
          '& .special-event': {
            backgroundColor: '#fff3cd'
          }
        }}>
          <Calendar
            onChange={handleDateChange}
            value={date}
            tileClassName={({ date }) => {
              const dateKey = format(date, 'dd/MM/yyyy');
              const hasSpecialEvent = events[dateKey]?.some(event => event.type === 'birthday' || event.type === 'anniversary');
              return hasSpecialEvent ? 'highlight special-event' : events[dateKey] || holidays[dateKey] ? 'highlight' : null;
            }}
            onClickDay={handleDayClick}
            onActiveStartDateChange={handleViewChange}
            tileContent={tileContent}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box sx={{ 
          backgroundColor: '#fff',
          padding: 2,
          borderRadius: '8px',      
          maxHeight: 'calc(100vh - 100px)',
          overflowY: 'auto',
          boxShadow: 3
        }}>
          <Typography variant="h6" sx={{ backgroundColor: '#fcee9f', fontWeight: "600", color: '#3f2854', padding: 0.5, mb: 0.5 }}>
            Agenda de eventos
          </Typography>
          {getEventsForMonth()}
        </Box>
      </Grid>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Adicionar Evento</DialogTitle>
        <DialogContent>
          <TextField
            value={newEvent.title}
            onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
            fullWidth
            label="Título"
            margin="normal"
            required
          />
          
          <TextField
            value={newEvent.note}
            onChange={(e) => setNewEvent({ ...newEvent, note: e.target.value })}
            fullWidth
            label="Observações"
            margin="normal"
            multiline
            rows={3}
          />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                type="time"
                value={newEvent.startTime}
                onChange={(e) => setNewEvent({ ...newEvent, startTime: e.target.value })}
                fullWidth
                label="Hora de Início"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="time"
                value={newEvent.endTime}
                onChange={(e) => setNewEvent({ ...newEvent, endTime: e.target.value })}
                fullWidth
                label="Hora de Fim"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                required
              />
            </Grid>
          </Grid>

          {currentUser?.isRH && (
            <>
              <FormControlLabel
                control={
                  <Switch
                    checked={isCompanyEvent}
                    onChange={(e) => setIsCompanyEvent(e.target.checked)}
                  />
                }
                label="Evento da Empresa"
                sx={{ mt: 2, mb: 1 }}
              />

              {!isCompanyEvent && (
                <FormControl fullWidth margin="normal">
                  <InputLabel>Usuário Específico</InputLabel>
                  <Select
                    value={selectedUser || ''}
                    onChange={(e) => setSelectedUser(e.target.value)}
                    label="Usuário Específico"
                  >
                    <MenuItem value="">
                      <em>Nenhum (evento pessoal)</em>
                    </MenuItem>
                    {users.map(user => (
                      <MenuItem key={user.iduser} value={user.iduser}>
                        {user.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          <Button onClick={handleSaveEvent} variant="contained" color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showEventModal} onClose={handleCloseEventModal} maxWidth="sm" fullWidth>
        <DialogTitle>
          Detalhes do Evento
          <IconButton
            aria-label="close"
            onClick={handleCloseEventModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            value={newEvent.title}
            onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
            fullWidth
            label="Título"
            margin="normal"
            required
          />
          
          <TextField
            value={newEvent.note}
            onChange={(e) => setNewEvent({ ...newEvent, note: e.target.value })}
            fullWidth
            label="Observações"
            margin="normal"
            multiline
            rows={3}
          />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                type="time"
                value={newEvent.startTime}
                onChange={(e) => setNewEvent({ ...newEvent, startTime: e.target.value })}
                fullWidth
                label="Hora de Início"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="time"
                value={newEvent.endTime}
                onChange={(e) => setNewEvent({ ...newEvent, endTime: e.target.value })}
                fullWidth
                label="Hora de Fim"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                required
              />
            </Grid>
          </Grid>

          {currentUser?.isRH && (
            <>
              <FormControlLabel
                control={
                  <Switch
                    checked={isCompanyEvent}
                    onChange={(e) => setIsCompanyEvent(e.target.checked)}
                  />
                }
                label="Evento da Empresa"
                sx={{ mt: 2, mb: 1 }}
              />

              {!isCompanyEvent && (
                <FormControl fullWidth margin="normal">
                  <InputLabel>Usuário Específico</InputLabel>
                  <Select
                    value={selectedUser || ''}
                    onChange={(e) => setSelectedUser(e.target.value)}
                    label="Usuário Específico"
                  >
                    <MenuItem value="">
                      <em>Nenhum (evento pessoal)</em>
                    </MenuItem>
                    {users.map(user => (
                      <MenuItem key={user.iduser} value={user.iduser}>
                        {user.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEventModal}>Cancelar</Button>
          <Button 
            onClick={handleUpdateEvent} 
            variant="contained" 
            color="primary"
          >
            Atualizar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={congratsDialog.open} 
        onClose={() => setCongratsDialog({ open: false, message: '', title: '' })}
      >
        <DialogTitle sx={{ textAlign: 'center' }}>{congratsDialog.title}</DialogTitle>
        <DialogContent>
          <Typography variant="h6" sx={{ textAlign: 'center', my: 2 }}>
            {congratsDialog.message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCongratsDialog({ open: false, message: '', title: '' })}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </Grid>
  );
};

export default CalendarWithEvents;
