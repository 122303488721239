import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Card, CardContent, Grid, Typography, Stack } from "@mui/material";
import UserList from "./UserList";
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ViewUsersRH = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentUserCnpj, setCurrentUserCnpj] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const navigate = useNavigate();

  const getUsers = useCallback(async (userCnpj, currentUserId) => {
    try {
      const res = await axios.get("/users");
      console.log("Todos os usuários:", res.data);
      
      // Filtra os usuários pelo id_cnpj e exclui o usuário atual
      const filteredUsers = res.data.filter(user => 
        user.id_cnpj === userCnpj && user.iduser !== parseInt(currentUserId)
      );
      console.log("Usuários filtrados por CNPJ (excluindo usuário atual):", filteredUsers);
      
      setUsers(filteredUsers);
    } catch (error) {
      console.error("Erro ao buscar usuários:", error);
      toast.error("Erro ao carregar a lista de usuários");
    }
  }, []);

  const getCurrentUserCnpj = useCallback(async (userId) => {
    try {
      const response = await axios.get(`/users/${userId}`);
      console.log("Dados do usuário atual:", response.data);
      const userCnpj = response.data.id_cnpj;
      setCurrentUserCnpj(userCnpj);
      getUsers(userCnpj, userId);
    } catch (error) {
      console.error("Erro ao buscar dados do usuário:", error);
      toast.error("Erro ao carregar informações do usuário");
    }
  }, [getUsers]);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      setCurrentUserId(userId);
      getCurrentUserCnpj(userId);
    }
  }, [getCurrentUserCnpj]);

  const handleSendMessage = (phoneNumber) => {
    const message = "Olá, gostaria de mais informações sobre o seu cadastro.";
    const encodedMessage = encodeURIComponent(message);
    window.open(`https://wa.me/55${phoneNumber.replace(/\D/g, '')}?text=${encodedMessage}`, '_blank');
  };

  const handleEditUser = async (user) => {
    try {
      console.log("Editando usuário:", user);
      if (user && user.iduser) {
        navigate(`/FormEdit/${user.iduser}`);
      } else {
        toast.error("ID do usuário não encontrado");
      }
    } catch (error) {
      console.error("Erro ao editar usuário:", error);
      toast.error("Erro ao editar usuário");
    }
  };

  const handleDeleteUser = async (user) => {
    try {
      console.log("Excluindo usuário:", user);
      if (user && user.iduser) {
        const response = await axios.delete(`/users/${user.iduser}`);
        console.log("Resposta da exclusão:", response);
        
        if (response.status === 200) {
          toast.success("Colaborador excluído com sucesso!");
          // Atualiza a lista mantendo o filtro
          getUsers(currentUserCnpj, currentUserId);
        } else {
          console.error("Resposta inesperada:", response);
          toast.error("Erro ao excluir colaborador");
        }
      } else {
        console.error("ID do usuário não encontrado");
        toast.error("ID do colaborador não encontrado");
      }
    } catch (error) {
      console.error("Erro ao excluir colaborador:", error);
      toast.error(error.response?.data?.message || "Erro ao excluir colaborador");
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = users.filter((user) =>
    user.nome.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
            <Typography variant="h5" gutterBottom>
              Lista de Colaboradores
            </Typography>
            <OutlinedInput
              size="small"
              placeholder="Pesquisar colaborador"
              value={searchTerm}
              onChange={handleSearch}
              startAdornment={
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              }
              sx={{ width: { xs: '100%', sm: 300 } }}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ boxShadow: 5, borderRadius: 3 }}>
            <CardContent>
              <UserList
                users={filteredUsers}
                onSendMessage={handleSendMessage}
                onEdit={handleEditUser}
                onDelete={handleDeleteUser}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewUsersRH;
