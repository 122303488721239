import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import { useTheme, useMediaQuery, Box, CircularProgress, Typography } from '@mui/material';
import axios from 'axios';

const PieRh = () => {
  const chartRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem("userId");
        if (!userId) {
          throw new Error("Usuário não encontrado");
        }

        const response = await axios.get(`/users/${userId}`);
        const currentUserData = response.data;

        const responseAllUsers = await axios.get("/users");
        const allUsers = responseAllUsers.data;

        const users = allUsers.filter(user => 
          user.id_cnpj === currentUserData.id_cnpj && !user.cnpj
        );

        setUserData(users);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (chartRef.current && userData) {
      if (chartRef.current.chart) {
        chartRef.current.chart.destroy();
      }

      const ctx = chartRef.current.getContext('2d');

      // Calcular dados
      let completed = 0;
      let pendingExams = 0;
      let pendingDocs = 0;
      let pendingReg = 0;

      userData.forEach(user => {
        const isComplete = user.rg && user.uploadsPath && user.uploadsPathAso;
        if (isComplete) {
          completed++;
        } else {
          if (!user.rg) pendingReg++;
          if (!user.uploadsPath) pendingDocs++;
          if (!user.uploadsPathAso) pendingExams++;
        }
      });

      const data = {
        labels: ['Processos Concluídos', 'Pendentes Exames', 'Pendentes Documentos', 'Pendentes Cadastros'],
        datasets: [{
          data: [completed, pendingExams, pendingDocs, pendingReg],
          backgroundColor: [
            'rgba(106, 67, 139, 0.8)',
            'rgba(184, 0, 216, 0.8)',
            'rgba(46, 150, 255, 0.8)',
            'rgba(244, 211, 24, 0.8)'
          ],
          borderColor: [
            'rgba(106, 67, 139, 1)',
            'rgba(184, 0, 216, 1)',
            'rgba(46, 150, 255, 1)',
            'rgba(244, 211, 24, 1)'
          ],
          borderWidth: 2,
          hoverOffset: 15,
          hoverBorderWidth: 3
        }]
      };

      const config = {
        type: 'doughnut',
        data: data,
        options: {
          maintainAspectRatio: false,
          responsive: true,
          cutout: '65%',
          animation: {
            animateScale: true,
            animateRotate: true,
            duration: 2000,
            easing: 'easeInOutQuart',
            delay: (context) => {
              return context.dataIndex * 300;
            }
          },
          plugins: {
            legend: {
              position: isMobile ? 'bottom' : 'right',
              labels: {
                boxWidth: isMobile ? 12 : 40,
                padding: isMobile ? 10 : 20,
                font: {
                  size: isMobile ? 10 : 12,
                  weight: 'bold'
                },
                usePointStyle: true,
                pointStyle: 'rectRounded'
              }
            },
            tooltip: {
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              titleColor: '#000',
              titleFont: {
                size: 13,
                weight: 'bold'
              },
              bodyColor: '#666',
              bodyFont: {
                size: 12
              },
              borderColor: '#ddd',
              borderWidth: 1,
              padding: 12,
              displayColors: true,
              callbacks: {
                label: function(context) {
                  const value = context.raw;
                  const total = userData.length;
                  const percentage = ((value / total) * 100).toFixed(1);
                  return `${context.label}: ${value} (${percentage}%)`;
                }
              }
            }
          }
        }
      };

      chartRef.current.chart = new Chart(ctx, config);
    }
  }, [userData, isMobile]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="400px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="400px">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <canvas ref={chartRef} />
    </div>
  );
};

export default PieRh;
