import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Typography,
  Box,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  CircularProgress,
  Modal,
  Button,
  Card,
  CardContent,
  CardActionArea,
  Grid,
  useTheme,
  useMediaQuery,
  IconButton,
  Tooltip,
  LinearProgress,
  Alert,
  Fade,
  Grow,
  styled,
} from '@mui/material';
import {
  Poll as PollIcon,
  Close as CloseIcon,
  Send as SendIcon,
  CheckCircle as CheckCircleIcon,
  AccessTime as AccessTimeIcon,
} from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  borderRadius: '8px',
  marginBottom: theme.spacing(2),
  '&:hover': {
    boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
    cursor: 'pointer'
  }
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.1rem',
  fontWeight: 600,
  color: theme.palette.text.primary,
  textRendering: 'optimizeLegibility',
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale'
}));

const StyledQuestionText = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  lineHeight: 1.5,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(2),
  fontWeight: 500,
  textRendering: 'optimizeLegibility',
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale'
}));

const StyledAnswerText = styled(Typography)(({ theme }) => ({
  fontSize: '0.95rem',
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
  textRendering: 'optimizeLegibility',
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale'
}));

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '8px',
  padding: theme.spacing(4),
  maxWidth: '600px',
  width: '90%',
  maxHeight: '80vh',
  overflowY: 'auto',
  margin: 'auto',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '&:focus': {
    outline: 'none'
  }
}));

const SurveyDashboard = ({ userData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [surveys, setSurveys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [userId, setUserId] = useState(null);
  const [responses, setResponses] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [completedSurveys, setCompletedSurveys] = useState(new Set());

  const fetchData = async () => {
    try {
      const userId = localStorage.getItem('userId');
      if (!userId) {
        throw new Error('Usuário não encontrado');
      }

      const userResponse = await axios.get(`/users/${userId}`);
      const surveyResponse = await axios.get('/surveys');
      
      // Filtrar pesquisas pelo id_cnpj do usuário
      const filteredSurveys = surveyResponse.data.filter(
        survey => survey.id_cnpj === userResponse.data.id_cnpj && !survey.archived
      );

      setSurveys(filteredSurveys);

      // Buscar todas as respostas do usuário para identificar pesquisas respondidas
      try {
        const completedSurveysSet = new Set();
        
        // Verificar cada pesquisa se já foi respondida
        for (const survey of filteredSurveys) {
          const surveyResponses = await axios.get(`/surveys/${survey.id}/responses`);
          const hasResponded = surveyResponses.data.some(
            response => response.userId === parseInt(userId)
          );
          if (hasResponded) {
            completedSurveysSet.add(survey.id);
          }
        }
        
        setCompletedSurveys(completedSurveysSet);
      } catch (error) {
        console.error('Erro ao verificar respostas:', error);
      }

      setIsLoading(false);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      setError('Erro ao carregar dados. Por favor, tente novamente mais tarde.');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const userIdFromStorage = localStorage.getItem('userId');
    setUserId(userIdFromStorage);

    if (userIdFromStorage && userData) {
      fetchData();
    } else {
      setIsLoading(false);
      setError('Usuário não encontrado. Verifique se está logado corretamente.');
    }
  }, [userData]);

  const handleAnswerChange = (questionIndex, answer) => {
    setResponses(prev => ({
      ...prev,
      [questionIndex]: answer
    }));
  };

  const handleSurveyClick = async (survey) => {
    try {
      setSelectedSurvey(survey);
      const userId = localStorage.getItem('userId');
      const responseResponse = await axios.get(`/surveys/${survey.id}/responses`);
      const userResponses = responseResponse.data.filter(
        response => response.userId === parseInt(userId)
      );
      
      // Converter as respostas para o formato de objeto
      const responsesObj = {};
      userResponses.forEach(response => {
        responsesObj[response.questionIndex] = response.answer;
      });
      
      setResponses(responsesObj);
      setOpenModal(true);
    } catch (error) {
      console.error('Erro ao buscar respostas da pesquisa:', error);
      toast.error('Erro ao carregar respostas da pesquisa');
    }
  };

  const handleCloseModal = () => {
    if (submitting) return;
    setOpenModal(false);
    setSelectedSurvey(null);
    setResponses({});
  };

  const handleSubmit = async () => {
    if (submitting) return;
    
    try {
      setSubmitting(true);
      const userId = localStorage.getItem('userId');

      // Verificar se todas as perguntas foram respondidas
      const unansweredQuestions = selectedSurvey.surveyQuestions.filter(
        (_, index) => !responses[index]
      );

      if (unansweredQuestions.length > 0) {
        toast.error('Por favor, responda todas as perguntas antes de enviar.');
        return;
      }

      // Preparar as respostas para envio
      const surveyResponses = selectedSurvey.surveyQuestions.map((_, index) => ({
        surveyId: selectedSurvey.id,
        userId: parseInt(userId),
        questionIndex: index,
        answer: responses[index]
      }));

      // Enviar respostas uma por uma
      for (const response of surveyResponses) {
        await axios.post('/surveys/response', response);
      }

      toast.success('Pesquisa respondida com sucesso!');
      setCompletedSurveys(prev => new Set([...prev, selectedSurvey.id]));
      setOpenModal(false);
      setResponses({});
      setSelectedSurvey(null);
      
      // Atualizar dados
      fetchData();
    } catch (error) {
      console.error('Erro ao enviar respostas:', error);
      toast.error('Erro ao enviar respostas. Por favor, tente novamente.');
    } finally {
      setSubmitting(false);
    }
  };

  const allQuestionsAnswered = () => {
    if (!selectedSurvey?.surveyQuestions) return false;
    return selectedSurvey.surveyQuestions.every((_, index) => responses[index]);
  };

  if (isLoading) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="200px">
        <CircularProgress size={40} />
        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
          Carregando pesquisas...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ width: '100%', mb: 2 }}>
        {error}
      </Alert>
    );
  }

  return (
    <Box sx={{ maxWidth: '100%', margin: 2 }}>
      <ToastContainer />
      {surveys.length === 0 ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="200px"
          textAlign="center"
        >
          <PollIcon sx={{ fontSize: 60, color: 'text.secondary', mb: 2 }} />
          <Typography variant="h6" color="text.secondary">
            Nenhuma pesquisa disponível no momento
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Fique atento! Novas pesquisas serão publicadas em breve.
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={2}>
          {surveys.map((survey, index) => (
            <Grow in={true} timeout={300 * (index + 1)} key={survey.id}>
              <Grid item xs={12}>
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    '&:hover': {
                      transform: completedSurveys.has(survey.id) ? 'none' : 'translateY(-4px)',
                      boxShadow: completedSurveys.has(survey.id) ? 1 : 6,
                    },
                    opacity: completedSurveys.has(survey.id) ? 0.7 : 1,
                  }}
                >
                  <CardActionArea
                    onClick={() => handleSurveyClick(survey)}
                    disabled={completedSurveys.has(survey.id)}
                  >
                    <CardContent>
                      <Box display="flex" alignItems="center" mb={1}>
                        {completedSurveys.has(survey.id) ? (
                          <CheckCircleIcon color="success" sx={{ mr: 1 }} />
                        ) : (
                          <AccessTimeIcon color="primary" sx={{ mr: 1 }} />
                        )}
                        <Typography variant="h6" component="div">
                          {survey.title}
                        </Typography>
                      </Box>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        Ajude-nos a melhorar nosso ambiente de trabalho respondendo esta pesquisa.
                      </Typography>
                      <Box mt={1}>
                        <Typography variant="caption" color="text.secondary">
                          {survey.surveyQuestions?.length || 0} {(survey.surveyQuestions?.length || 0) === 1 ? 'pergunta' : 'perguntas'}
                        </Typography>
                        {completedSurveys.has(survey.id) && (
                          <Typography variant="caption" color="success.main" sx={{ display: 'block' }}>
                            Respondida
                          </Typography>
                        )}
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grow>
          ))}
        </Grid>
      )}

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: isMobile ? '95%' : '80%',
              maxWidth: 800,
              maxHeight: '90vh',
              bgcolor: 'background.paper',
              borderRadius: 2,
              boxShadow: 24,
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* Header */}
            <Box
              sx={{
                p: 2,
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h6">
                {selectedSurvey?.title}
              </Typography>
              <Tooltip title="Fechar">
                <IconButton
                  onClick={handleCloseModal}
                  disabled={submitting}
                  sx={{ color: 'inherit' }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>

            {/* Progress */}
            {selectedSurvey && (
              <LinearProgress 
                variant="determinate" 
                value={(Object.keys(responses).length / selectedSurvey.surveyQuestions?.length || 0) * 100}
                sx={{ height: 4 }}
              />
            )}

            {/* Content */}
            <Box sx={{ p: 3, overflowY: 'auto', flexGrow: 1 }}>
              {selectedSurvey?.surveyQuestions?.map((question, qIndex) => (
                <Card key={qIndex} sx={{ mb: 2, bgcolor: 'background.default' }}>
                  <CardContent>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend" sx={{ mb: 2 }}>
                        <Typography variant="subtitle1" fontWeight="medium">
                          {qIndex + 1}. {question.questionText}
                        </Typography>
                      </FormLabel>
                      <RadioGroup
                        value={responses[qIndex] || ''}
                        onChange={(e) => handleAnswerChange(qIndex, e.target.value)}
                      >
                        <Grid container spacing={1}>
                          {Array.isArray(question.answers) && question.answers.map((answer, aIndex) => (
                            <Grid item xs={12} key={aIndex}>
                              <Paper
                                elevation={responses[qIndex] === answer ? 3 : 1}
                                sx={{
                                  p: 1,
                                  transition: 'all 0.2s',
                                  bgcolor: responses[qIndex] === answer ? 'action.selected' : 'background.paper',
                                  '&:hover': {
                                    bgcolor: 'action.hover',
                                  },
                                }}
                              >
                                <FormControlLabel
                                  value={answer}
                                  control={<Radio />}
                                  label={answer}
                                  sx={{ width: '100%', m: 0 }}
                                />
                              </Paper>
                            </Grid>
                          ))}
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  </CardContent>
                </Card>
              ))}
            </Box>

            {/* Footer */}
            <Box
              sx={{
                p: 2,
                bgcolor: 'background.default',
                borderTop: 1,
                borderColor: 'divider',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="body2" color="text.secondary">
                {selectedSurvey && `${Object.keys(responses).length} de ${selectedSurvey.surveyQuestions?.length || 0} respondidas`}
              </Typography>
              <Box>
                <Button
                  onClick={handleCloseModal}
                  disabled={submitting}
                  sx={{ mr: 2 }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={submitting || !allQuestionsAnswered()}
                  startIcon={<SendIcon />}
                >
                  {submitting ? 'Enviando...' : 'Enviar Respostas'}
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default SurveyDashboard;
