import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography, Button, MobileStepper } from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const UploadImages = () => {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [uploadMessage, setUploadMessage] = useState("");
  const [userId, setUserId] = useState("");
  const [userDocs, setUserDocs] = useState([]);
  const [currentDocIndex, setCurrentDocIndex] = useState(0);

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      setUserId(storedUserId);
      fetchUserDocs(storedUserId);
    }
  }, []);

  const fetchUserDocs = async (userId) => {
    try {
      const response = await axios.get("/users");
      const users = response.data;
      const user = users.find((user) => user.iduser === parseInt(userId));
      if (user) {
        setUserDocs(user.docs.split(","));
      } else {
        console.warn("Usuário não encontrado.");
      }
    } catch (error) {
      console.error("Erro ao buscar os documentos do usuário:", error);
    }
  };

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    if (selectedFiles.length > 0) {
      setFiles((prevFiles) => [...prevFiles, ...Array.from(selectedFiles)]);
      setUploadMessage("");
    }
  };

  const handleUpload = async () => {
    if (files.length === 0 || !userId) {
      setUploadMessage(
        <div className="alertRed">
          Nenhum arquivo selecionado ou usuário não identificado.
        </div>
      );
      return;
    }

    console.log('Iniciando upload:', {
      userId,
      quantidadeArquivos: files.length,
      arquivos: files.map(f => ({
        nome: f.name,
        tipo: f.type,
        tamanho: f.size
      }))
    });

    // Validar tamanho dos arquivos
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
    const invalidFiles = files.filter(file => file.size > MAX_FILE_SIZE);
    if (invalidFiles.length > 0) {
      setUploadMessage(
        <div className="alertRed">
          Os seguintes arquivos excedem o tamanho máximo de 5MB:<br/>
          {invalidFiles.map(file => file.name).join(', ')}
        </div>
      );
      return;
    }

    // Validar tipos de arquivo permitidos
    const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
    const invalidTypes = files.filter(file => !ALLOWED_TYPES.includes(file.type));
    if (invalidTypes.length > 0) {
      setUploadMessage(
        <div className="alertRed">
          Os seguintes arquivos têm formato inválido (apenas JPG, PNG e PDF são permitidos):<br/>
          {invalidTypes.map(file => file.name).join(', ')}
        </div>
      );
      return;
    }

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("userId", userId);

    console.log('FormData criado com:', {
      userId,
      quantidadeArquivos: files.length
    });

    setUploadMessage(
      <div className="alertYellow">
        Enviando arquivos, por favor aguarde...
      </div>
    );

    try {
      console.log('Enviando requisição para:', '/uploads');
      const response = await axios.post("/uploads", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          UserId: userId,
        },
      });

      console.log('Resposta recebida:', response.data);

      if (response.data.savedFiles === 0) {
        setUploadMessage(
          <div className="alertRed">
            Nenhum arquivo foi salvo com sucesso. Por favor, tente novamente.
          </div>
        );
        return;
      }

      setUploadMessage(
        <div className="alertGreen">
          {response.data.savedFiles} arquivo(s) enviado(s) com sucesso!<br/>
          Paths salvos: {response.data.paths}<br/>
          Selecione o próximo documento abaixo:
        </div>
      );
      setFiles([]);

      if (currentDocIndex < userDocs.length - 1) {
        setCurrentDocIndex(currentDocIndex + 1);
      } else {
        setUploadMessage(
          <div className="alertGreen">
            Todos os documentos foram enviados com sucesso.
          </div>
        );
        setTimeout(() => {
          navigate("/DashboardUser");
        }, 2000);
      }

    } catch (error) {
      console.error("Erro ao enviar as imagens:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      setUploadMessage(
        <div className="alertRed">
          {error.response?.data?.error || 'Erro ao enviar documento. Por favor, tente novamente.'}
        </div>
      );
    }
  };

  const renderFilePreview = (file) => {
    const fileType = file.type;

    if (fileType.startsWith('image/')) {
      return <img src={URL.createObjectURL(file)} alt="preview" width={100} />;
    }

    if (fileType === 'application/pdf') {
      return <PictureAsPdfIcon color="error" sx={{ fontSize: 100 }} />;
    }

    return <InsertDriveFileIcon color="action" sx={{ fontSize: 100 }} />;
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" sx={{ p: 3 }}>
      <Typography variant="h6" align="center" gutterBottom>
        Por favor, forneça os documentos solicitados pelo recrutador para avançar com o seu processo.
      </Typography>

      <Box sx={{ width: "100%" }}>
        <label htmlFor="fileInput" className="custom-file-upload">
          <input
            type="file"
            id="fileInput"
            multiple
            onChange={handleFileChange}
            style={{ display: "none" }}
          />

          <Typography align="center">
            Clique aqui para tirar fotos ou selecionar os documentos. Anexe frente e verso quando necessário antes de enviar.
          </Typography>
          {uploadMessage && <Typography align="center">{uploadMessage}</Typography>}

          <Box sx={{ flexGrow: 1, width: '100%' }}>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography sx={{ p: 1, fontSize: "1.2em", textAlign: "center", mt: 1, color: "#6A438B" }}>
                    Documento {currentDocIndex + 1} de {userDocs.length}:
                    <br />
                    {userDocs[currentDocIndex]}
                  </Typography>

                  <img
                    src="/assets/idupload.png"
                    alt="idupload"
                    style={{ maxWidth: '50%', height: 'auto' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <MobileStepper
            variant="progress"
            steps={userDocs.length}
            position="static"
            activeStep={currentDocIndex}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "transparent",
              "& .MuiMobileStepper-progress": {
                backgroundColor: "#FFFF00",
                width: "100%",
              },
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#633687",
              },
              "& .MuiMobileStepper-dot": {
                display: "none",
              },
            }}
          />
        </label>

        <Box className="image-preview" sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap", mt: 2 }}>
          {files.map((file, index) => (
            <Box key={index} sx={{ m: 1 }}>
              {renderFilePreview(file)}
              <Typography variant="caption" sx={{ mt: 1, textAlign: "center" }}>{file.name}</Typography>
            </Box>
          ))}
        </Box>

        <Button
          variant="contained"
          onClick={handleUpload}
          fullWidth
          sx={{ backgroundColor: "#633687", color: "white", padding: "15px" }}
        >
          Enviar documento
        </Button>
      </Box>
    </Box>
  );
};

export default UploadImages;
