import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useTheme, Box, Typography, Grid, TextField, Button } from "@mui/material";
import api from '../services/api';

const Login = () => {
  const [nome, setNome] = useState("");
  const [cpf, setCPF] = useState("");
  const [loginError, setLoginError] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();

  const formatCPF = (inputCPF) => {
    const numericCPF = inputCPF.replace(/\D/g, "");
    return numericCPF.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  };

  const handleNomeChange = (e) => {
    setNome(e.target.value.toUpperCase());
  };

  const handleCPFChange = (e) => {
    const numericCPF = e.target.value.replace(/\D/g, "");
    if (numericCPF.length <= 11) {
      setCPF(formatCPF(numericCPF));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const cpfLimpo = cpf.replace(/\D/g, '');
      
      const response = await api.post("/users/login", {
        nome,
        cpf: cpfLimpo,
      });

      const { token, userId } = response.data;

      // Adicione logs para depuração
      console.log("Token recebido após o login:", token);
      console.log("ID do usuário recebido após o login:", userId);

      // Verifique se o ID do usuário é definido antes de armazenar
      if (userId !== undefined) {
        // Armazenar o token e o ID do usuário no localStorage
        localStorage.setItem("token", token);
        localStorage.setItem("userId", userId);

        // Redirecionar o usuário para a rota desejada
        navigate("/DashboardUser");
        window.location.reload();
      } else {
        setLoginError(
          <div className="alertRed">
            Credenciais inválidas. Verifique nome e CPF.
          </div>
        );
        console.error("ID do usuário não fornecido na resposta do servidor.");
      }
    } catch (error) {
      setLoginError(
        <div className="alertRed">
          Credenciais inválidas. Verifique nome e CPF.
        </div>
      );
      console.error("Erro ao realizar login:", error.message);
    }
  };

  return (
    <Grid
      container  
      sx={{       
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        [theme.breakpoints.down('sm')]:{ 
          height:"auto"
        }
      }}
    >
      <Grid  xs={12} sm={12} md={8}>
        <Box  sx={{  
          [theme.breakpoints.down('sm')]:{ 
           
            p:3,  height: '45vh',
          },
          position: 'relative',
          height: '100vh',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
        flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          color: 'white',
        }}>
          
          <video
            autoPlay
            loop
            muted
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              minWidth: '100%',
              minHeight: '100%',
              width: 'auto',
              height: 'auto',
              zIndex: -1,
              transform: 'translate(-50%, -50%)',
            }}
          >
            <source src="/assets/bg-5.mp4" type="video/mp4" />
            Seu navegador não suporta vídeos.
          </video>
         
          <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%', 
          backgroundColor: 'rgba(100, 20, 150, 0.8)', // Roxo com opacidade
          backdropFilter: 'blur(2px)', // Efeito de desfoque
          zIndex: -1,
        }}
      />
          <img
            src="/assets/tree-1.png"
            alt=""
            style={{ maxWidth: '140px', height: 'auto' }}
          />         

          <Typography            
            sx={{width:'70%', color: "white", textAlign: "center", mt: -2,
             [theme.breakpoints.down('sm')]:{ 
              width:'100%',             
            } }}
          >
            Bem-vindo, Colaborador! Estamos animados em tê-lo conosco e ansiosos para que você aproveite as
             oportunidades de crescimento em nossa plataforma.
          </Typography>
        </Box>
      </Grid>

      <Grid  xs={12} sm={12} md={4}  sx={{       
        justifyContent: "center",
        alignItems: "center",
   
      }}>
        <Box component="form" onSubmit={handleSubmit} sx={{  p:3}}>
          <Typography variant="h4">Login</Typography>
          <Typography color="text.secondary" variant="body2"  sx={{  mb:3}} >
         
              Ainda não realizou o cadastro?    <Link to="/register">cadastre-se agora!
            </Link>
          </Typography>

         

  <TextField
              fullWidth
              label="Nome completo"
              type="text"
              id="nome"
              value={nome}
              onChange={handleNomeChange}
              placeholder="Nome completo"
              sx={{  mb:2}} 
            />

            <TextField
              fullWidth
              label="CPF"
              type="text"
              id="cpf"
              value={cpf}
              maxLength={14}
              onChange={handleCPFChange}
              placeholder="Digite seu CPF"
              sx={{  mb:2}} 
            />
         
         
            <Button
              fullWidth
              color="primary"
              type="submit"
              sx={{
                backgroundColor: "#633687",
                color: "white",
                padding: "15px",
              }}
            >
              Entrar
            </Button>
            {loginError && <p className="errorMessage">{loginError}</p>}
         
        </Box>
        <Box display="flex" justifyContent="center" sx={{mt:5  }}>
          <img
              src="/assets/fogueterh.png"
              alt=""
              style={{ maxWidth: '50%', height: 'auto' }}
            />
        </Box>

      </Grid>




    </Grid>
  );
};

export default Login;
