import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  StepLabel,
  Step,
  Stepper,
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
} from "@mui/material";
import axios from "axios";
import CardDocStep from "./CardDocStep";
import CardRegisterStep from "./CardRegisterStep";
import CardAsoStep from "./CardAsoStep";
import CardFinalStep from "./CardFinalStep";
import SurveyDashboard from "./SurveyDashboard";
import CalendarWithEvents from "../CalendarWithEvents";
import PollIcon from "@mui/icons-material/Poll";

const StatusUserStep = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const userId = localStorage.getItem("userId");
      
      if (!userId) {
        setError("ID do usuário não encontrado");
        setLoading(false);
        return;
      }

      try {
        console.log("[Debug] Iniciando busca de usuário");
        console.log("[Debug] ID do usuário:", userId);
        console.log("[Debug] URL da requisição:", `/users/${userId}`);
        
        const response = await axios.get(`/users/${userId}`, {
          headers: {
            'Accept': 'application/json'
          }
        });
        
        console.log("[Debug] Resposta recebida:", response.data);
        setUserData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("[Debug] Erro na requisição:", {
          message: error.message,
          response: error.response?.data,
          status: error.response?.status,
          config: error.config
        });
        setError(error.response?.data?.message || "Erro ao carregar dados do usuário");
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const steps = [
    "Complete seu cadastro",
    "Documentos solicitados",
    "Envio de exames",
  ];

  let activeStep = 0;

  if (userData) {
    if (!userData.rg || !userData.cep || !userData.rua || !userData.numero) {
      activeStep = 0;
    } else if (!userData.uploadsPath) {
      activeStep = 1;
    } else if (!userData.uploadsPathAso) {
      activeStep = 2;
    } else {
      activeStep = 3;
    }
  }

  if (loading) {
    return <Box sx={{ p: 3 }}>Carregando...</Box>;
  }

  if (error) {
    return <Box sx={{ p: 3 }}>Erro: {error}</Box>;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ width: "100%" }}>
        {activeStep === 0 && <CardRegisterStep userData={userData} />}
        {activeStep === 1 && <CardDocStep userData={userData} />}
        {activeStep === 2 && <CardAsoStep userData={userData} />}
        {activeStep === 3 && <CardFinalStep userData={userData} />}
      </Box>

      <Box sx={{ width: "100%", borderRadius: 3, boxShadow: 5 }}>
        <Stepper
          activeStep={activeStep}
          sx={{
            m: 5,
            borderRadius: "16px",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          {steps.map((label, index) => (
            <Step
              sx={{ p: 2 }}
              display="flex"
              alignItems="flex-start"
              key={label}
              completed={index < activeStep}
            >
              <StepLabel
                StepIconProps={{
                  style: {
                    color: index === activeStep ? "#FDEE00" : "#633687",
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6} md={8}>
          <Card sx={{ boxShadow: 5, borderRadius: 3 }}>
            <CardContent>
                   <CalendarWithEvents />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ 
            minHeight: "400px", 
            boxShadow: 5, 
            borderRadius: 3,
            overflow: 'hidden'
          }}>
            <CardContent>
              <Box sx={{ mb: 2 }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <PollIcon color="primary" />
                  <Typography variant="h6">
                    Pesquisa de Clima
                  </Typography>
                </Stack>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  Responda as pesquisas e compartilhe sua opinião de forma anônima. 
                  Suas respostas são confidenciais e fundamentais para melhorar 
                  continuamente nossos processos e ambiente de trabalho.
                </Typography>
              </Box>
              <Divider sx={{ mb: 2 }} />
              <Box sx={{ height: 'calc(100% - 120px)', overflow: 'auto' }}>
                <SurveyDashboard userData={userData} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StatusUserStep;
