import React, { useEffect, useState } from "react";
import SurveyDashboard from "../components/DashBoards/SurveyDashboard";
import SideBarUser from "../components/SideBarUser";
import {
  Box,
  useTheme,
  Typography,
  Card,
  CardContent,
  Divider,
  Container,
  Grid,
  Paper,
} from '@mui/material';
import axios from "axios";
import {
  Poll as PollIcon,
  Comment as CommentIcon,
  Security as SecurityIcon,
} from '@mui/icons-material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SurveyUser = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchUserData = async () => {
      const userId = localStorage.getItem("userId");
      
      if (!userId) {
        setError("ID do usuário não encontrado");
        setLoading(false);
        return;
      }

      try {
        console.log("[Debug] Iniciando busca de usuário");
        console.log("[Debug] ID do usuário:", userId);
        
        const response = await axios.get(`/users/${userId}`);
        console.log("[Debug] Resposta recebida:", response.data);
        
        setUserData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("[Debug] Erro na requisição:", {
          message: error.message,
          response: error.response?.data,
          status: error.response?.status
        });
        setError(error.response?.data?.message || "Erro ao carregar dados do usuário");
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography>Carregando...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <>
      <SideBarUser />
      <Box
        component="main"
        sx={{
          marginTop: 10,
          p: 3,
          [theme.breakpoints.down('sm')]: {
            marginTop: 7,
          },
          bgcolor: '#F4F5FA',
          minHeight: '100vh',
        }}
      >
        <ToastContainer />
        <Container maxWidth="xl">
          {/* Cabeçalho */}
          <Paper
            elevation={0}
            sx={{
              p: 3,
              mb: 4,
              background: 'linear-gradient(45deg, #6a438b 30%, #B800D8 90%)',
              color: 'white',
              borderRadius: 2,
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: -20,
                right: -20,
                opacity: 0.1,
                transform: 'rotate(30deg)',
              }}
            >
              <PollIcon sx={{ fontSize: 180 }} />
            </Box>
            <Typography variant="h4" gutterBottom fontWeight="bold">
              Pesquisa de Clima Organizacional
            </Typography>
            <Typography variant="subtitle1">
              Sua opinião é fundamental para construirmos um ambiente de trabalho melhor
            </Typography>
          </Paper>

          {/* Cards Informativos */}
          <Grid container spacing={3} sx={{ mb: 4 }}>
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  height: '100%',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                  },
                }}
              >
                <CardContent>
                  <Box display="flex" alignItems="center" mb={2}>
                    <CommentIcon
                      sx={{
                        fontSize: 40,
                        color: theme.palette.primary.main,
                        mr: 2,
                      }}
                    />
                    <Typography variant="h6">
                      Compartilhe sua Opinião
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    Suas respostas nos ajudam a identificar pontos de melhoria e criar
                    um ambiente mais agradável para todos.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  height: '100%',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                  },
                }}
              >
                <CardContent>
                  <Box display="flex" alignItems="center" mb={2}>
                    <SecurityIcon
                      sx={{
                        fontSize: 40,
                        color: theme.palette.success.main,
                        mr: 2,
                      }}
                    />
                    <Typography variant="h6">
                      Anonimato Garantido
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    Todas as respostas são anônimas e confidenciais, garantindo sua
                    privacidade e liberdade de expressão.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  height: '100%',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                  },
                }}
              >
                <CardContent>
                  <Box display="flex" alignItems="center" mb={2}>
                    <PollIcon
                      sx={{
                        fontSize: 40,
                        color: theme.palette.warning.main,
                        mr: 2,
                      }}
                    />
                    <Typography variant="h6">
                      Impacto Real
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    Com base nas respostas, implementamos melhorias contínuas para
                    tornar nosso ambiente de trabalho cada vez melhor.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Lista de Pesquisas */}
          <Box sx={{ borderRadius: 2, bgcolor: 'background.paper', boxShadow: 1 }}>
            <Box sx={{ p: 3 }}>
              <Typography variant="h5" gutterBottom>
                Pesquisas Disponíveis
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Participe das pesquisas abaixo e contribua para a melhoria do nosso ambiente de trabalho
              </Typography>
              <Divider sx={{ my: 3 }} />
              <SurveyDashboard userData={userData} />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default SurveyUser;
