// DashboardUser.js
import React from "react";
import "../styles/styles.css";
import SideBarUser from "../components/SideBarUser";
import UserContracts from "../components/UserContracts";
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import axios from 'axios';
import moment from 'moment';

const UserContractsSign = ({ toggleTheme }) => {
    const theme = useTheme();

    // Função para baixar o contrato
    const handleDownload = async (contract) => {
        if (!contract) {
            console.error('Contrato não fornecido para download');
            return;
        }

        try {
            console.log('Iniciando download do contrato:', contract);
            
            // Ajustando o caminho do documento para ser relativo
            const documentPath = contract.documentPath.replace(/^.*[\\\/]uploads[\\\/]/, '');
            console.log('Caminho ajustado:', documentPath);
            
            // Buscar o PDF do contrato
            const pdfResponse = await axios.get(`/uploads/${documentPath}`, {
                responseType: 'blob',
                headers: {
                    'Accept': 'application/pdf'
                }
            });

            // Buscar o IP do usuário
            const ipResponse = await axios.get('https://api.ipify.org?format=json');
            const userIp = ipResponse.data.ip;

            // Carregar o PDF existente
            const existingPdfBytes = await pdfResponse.data.arrayBuffer();
            const pdfDoc = await PDFDocument.load(existingPdfBytes);

            // Embedding a fonte Helvetica-Bold
            const helveticaBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

            // Criando uma nova página
            const page = pdfDoc.addPage([600, 800]);

            // Adicionando título
            page.drawText('Declaração de Assinatura Eletrônica', {
                x: 50,
                y: 750,
                size: 20,
                color: rgb(0, 0, 0),
                font: helveticaBoldFont,
            });

            // Adicionando linha separadora
            page.drawLine({
                start: { x: 50, y: 740 },
                end: { x: 550, y: 740 },
                thickness: 1,
                color: rgb(0, 0, 0),
            });

            // Adicionando declarações dos signatários
            let yPosition = 700;
            contract.signers.forEach((signer) => {
                page.drawText(`Assinante:`, {
                    x: 50,
                    y: yPosition,
                    size: 14,
                    color: rgb(0, 0, 0)
                });
                yPosition -= 30;

                const textCertificado = [
                    `Eu, ${signer.name}, declaro que estou ciente e de acordo com os termos deste documento.`,
                    `Ao clicar no botão de assinatura, confirmo a veracidade das informações e minha`,
                    `concordância com o conteúdo, com a validade jurídica de uma assinatura eletrônica,`,
                    `conforme a Lei nº 14.063/2020.`
                ];

                textCertificado.forEach(line => {
                    page.drawText(line, {
                        x: 70,
                        y: yPosition,
                        size: 12,
                        color: rgb(0, 0, 0)
                    });
                    yPosition -= 15;
                });

                yPosition -= 15;

                page.drawText(`CPF: ${signer.cpf}`, {
                    x: 70,
                    y: yPosition,
                    size: 12,
                    color: rgb(0, 0, 0),
                    font: helveticaBoldFont,
                });
                yPosition -= 15;
                page.drawText(`Email: ${signer.email}`, {
                    x: 70,
                    y: yPosition,
                    size: 12,
                    color: rgb(0, 0, 0),
                    font: helveticaBoldFont,
                });
                yPosition -= 15;
                page.drawText(`IP: ${signer.ip || userIp}`, {
                    x: 70,
                    y: yPosition,
                    size: 12,
                    color: rgb(0, 0, 0),
                    font: helveticaBoldFont,
                });
                yPosition -= 15;

                const dataAssinatura = signer.signedAt ? 
                    moment(signer.signedAt).format('DD/MM/YYYY [às] HH:mm:ss') : 
                    'Não assinado';
                page.drawText(`Data: ${dataAssinatura}`, {
                    x: 70,
                    y: yPosition,
                    size: 12,
                    color: rgb(0, 0, 0),
                    font: helveticaBoldFont,
                });
                yPosition -= 40;
            });

            // Salvar o PDF modificado
            const pdfBytes = await pdfDoc.save();

            // Criar o blob e fazer o download
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `Contrato_${contract.title}_Assinado.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
            console.log('Download iniciado com sucesso');

        } catch (error) {
            console.error('Erro detalhado ao baixar contrato:', error);
            if (error.response) {
                console.error('Resposta do servidor:', error.response.data);
                console.error('Status:', error.response.status);
            }
        }
    };

    return (
        <>
            <SideBarUser />
            <Box
                component="main"
                sx={{
                    marginTop: 10,
                    p: 3,
                    [theme.breakpoints.down('sm')]: {
                        marginTop: 10,
                    },
                }}
            >
                <UserContracts handleDownload={handleDownload} />
            </Box>
        </>
    );
};

export default UserContractsSign;
