import React, { useState } from "react";
import { 
  Box, 
  IconButton, 
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Stack,
  Divider
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';

const UserListAdm = ({ users, onSendMessage, onEdit, onDelete, currentUser }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [userImages, setUserImages] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedActionUser, setSelectedActionUser] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleMenuOpen = (event, user) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedActionUser(user);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedActionUser(null);
  };

  const handleAction = (action) => {
    if (!selectedActionUser) return;

    switch(action) {
      case 'view':
        handleVerClick(selectedActionUser);
        break;
      case 'edit':
        console.log("Chamando onEdit com:", selectedActionUser);
        onEdit && onEdit(selectedActionUser);
        break;
      case 'delete':
        console.log("Iniciando processo de exclusão para:", selectedActionUser);
        setConfirmDelete(true);
        break;
      default:
        break;
    }
    handleMenuClose();
  };

  const handleConfirmDelete = () => {
    if (selectedActionUser) {
      console.log("Confirmando exclusão para:", selectedActionUser);
      onDelete && onDelete(selectedActionUser);
      setConfirmDelete(false);
      setSelectedActionUser(null);
    }
  };

  const handleVerClick = (user) => {
    setSelectedUser(user);
  };

  const handleVerDoc = async (user) => {
    try {
      if (user.uploadsPath) {
        const images = user.uploadsPath.split(", ");
        setUserImages(images);
      } else {
        setUserImages([]);
      }
    } catch (error) {
      console.error('Erro ao obter imagens do usuário:', error);
    }
  };

  const handleVerAso = async (user) => {
    try {
      if (user.uploadsPathAso) {
        const images = user.uploadsPathAso.split(", ");
        setUserImages(images);
      } else {
        setUserImages([]);
      }
    } catch (error) {
      console.error('Erro ao obter imagens do usuário:', error);
    }
  };

  const closeModal = () => {
    setSelectedUser(null);
    setUserImages([]);
    setLightboxOpen(false);
    setConfirmDelete(false);
  };

  // Verificar se currentUser está definido e possui a propriedade id_cnpj
  const isCurrentUserValid = currentUser && currentUser.id_cnpj;

  // Filtrar usuários apenas se o currentUser estiver definido e possuir a propriedade id_cnpj
  const filteredUsers = isCurrentUserValid
    ? users.filter((user) => user.id_cnpj === currentUser.id_cnpj)
    : users;

  return (
    <div className="ContainerDefault">
      <div className="LabelList">
        <div className="ListFlex">
          <div className="IdList">ID</div>
          <div className="ListGrid">Nome</div>
          <div className="ListGrid">E-mail</div>
          <div className="ListGrid">Celular</div>
          <div className="ListGrid">Documentos</div>
          <div className="IdList">ASO</div>
          <div className="ListGrid">Status</div>
          <div className="IdList">Ações</div>
        </div>
      </div>
      {filteredUsers.map((user, index) => (
        <div className="ListFlex" key={`user-${user.iduser || user.id || index}`}>
          <div className="IdList">{user.iduser}</div>
          <div className="ListGrid">{user.nome}</div>
          <div className="ListGrid">{user.email}</div>
          <div className="ListGrid">
            <Stack direction="row" spacing={1} alignItems="center">
              <span>{user.fone}</span>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onSendMessage(user.fone);
                }}
                sx={{ 
                  color: 'success.main',
                  '&:hover': {
                    backgroundColor: 'success.lighter'
                  }
                }}
              >
                <WhatsAppIcon fontSize="small" />
              </IconButton>
            </Stack>
          </div>
          <div className="ListGrid">
            <button 
              className="ViewBtnList" 
              onClick={(e) => {
                e.stopPropagation();
                handleVerDoc(user);
              }}
            >
              Documentos
            </button>
          </div>
          <div className="IdList">
            <button 
              className="ViewBtnList" 
              onClick={(e) => {
                e.stopPropagation();
                handleVerAso(user);
              }}
            >
              ASO
            </button>
          </div>
          <div className="ListGrid">
            {user.rg === null ? (
              <span className="AlertStatusRed">Cadastro Pendente</span>
            ) : user.uploadsPath === null ? (
              <span className="AlertStatusYellow">Doc pendentes</span>
            ) : user.uploadsPathAso === null ? (
              <span className="AlertStatusOrange">ASO Pendente</span>
            ) : (
              <span className="AlertStatusGreen">Processo concluído</span>
            )}
          </div>
          <div className="IdList">
            <IconButton
              size="small"
              onClick={(event) => handleMenuOpen(event, user)}
              sx={{ 
                color: 'text.secondary',
                '&:hover': {
                  backgroundColor: 'action.hover'
                }
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </div>
        </div>
      ))}

      {/* Modal de exibição de informações do usuário */}
      <Dialog 
        open={Boolean(selectedUser)} 
        onClose={closeModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Informações pessoais do colaborador</Typography>
            <IconButton onClick={closeModal} size="small">
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          {selectedUser && (
            <Stack spacing={2}>
              <Typography><strong>ID:</strong> {selectedUser.iduser}</Typography>
              <Typography><strong>Nome:</strong> {selectedUser.nome}</Typography>
              <Typography><strong>CPF:</strong> {selectedUser.cpf}</Typography>
              <Typography><strong>RG:</strong> {selectedUser.rg}</Typography>
              <Typography><strong>Gênero:</strong> {selectedUser.genero}</Typography>
              <Typography><strong>Dependentes:</strong> {selectedUser.dependentes}</Typography>
              <Typography><strong>Data de nascimento:</strong> {selectedUser.data_nascimento}</Typography>
              <Typography><strong>Data de início de processo:</strong> {selectedUser.data}</Typography>
              <Typography><strong>E-mail:</strong> {selectedUser.email}</Typography>
              <Divider />
              <Typography variant="h6">Endereço</Typography>
              <Typography><strong>Rua:</strong> {selectedUser.rua}</Typography>
              <Typography><strong>Número:</strong> {selectedUser.numero}</Typography>
              <Typography><strong>Complemento:</strong> {selectedUser.complemento}</Typography>
              <Typography><strong>Cidade:</strong> {selectedUser.cidade}</Typography>
              <Typography><strong>Estado:</strong> {selectedUser.estado}</Typography>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Menu de ações */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => handleAction('view')}>
          <ListItemIcon>
            <FindInPageOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Visualizar</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleAction('edit')}>
          <ListItemIcon>
            <EditOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Editar</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleAction('delete')}>
          <ListItemIcon>
            <DeleteOutlineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Excluir</ListItemText>
        </MenuItem>
      </Menu>

      {/* Modal de confirmação de exclusão */}
      <Dialog
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h6">Confirmar Exclusão</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Tem certeza que deseja excluir o usuário {selectedActionUser?.nome}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal para exibir imagens do usuário */}
      <Dialog 
        open={userImages.length > 0} 
        onClose={closeModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Visualizar Documentos</Typography>
            <IconButton onClick={closeModal} size="small">
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: 2 }}>
            {userImages.map((path, index) => (
              <img
                key={index}
                style={{ 
                  width: '100%', 
                  height: 'auto',
                  cursor: 'pointer',
                  borderRadius: '8px'
                }}
                src={`/api/${path.trim()}`}
                alt={`Documento ${index + 1}`}
                onClick={() => {
                  setSelectedImageIndex(index);
                  setLightboxOpen(true);
                }}
              />
            ))}
          </Box>
        </DialogContent>
      </Dialog>

      {/* Modal de imagem ampliada */}
      <Dialog
        open={lightboxOpen}
        onClose={() => setLightboxOpen(false)}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="flex-end">
            <IconButton onClick={() => setLightboxOpen(false)} size="small">
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box
            component="img"
            sx={{
              width: '100%',
              height: 'auto',
              maxHeight: '80vh',
              objectFit: 'contain'
            }}
            src={userImages[selectedImageIndex] ? `/api/${userImages[selectedImageIndex].trim()}` : ''}
            alt="Documento Ampliado"
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UserListAdm;
