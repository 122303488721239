import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import SideBarAdm from "../components/SideBarAdm";
import NavBar from "../components/NavBar";
import UserListAdm from "../components/UserListAdm";
import { useNavigate } from "react-router-dom";

const ListarAdm = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getUsers();
    getCurrentUser();
  }, []);

  const getUsers = async () => {
    try {
      const res = await axios.get("/api/users");
      console.log("Usuários carregados:", res.data);
      setUsers(res.data);
    } catch (error) {
      console.error("Erro ao buscar usuários:", error);
      toast.error("Erro ao carregar a lista de usuários");
    }
  };

  const getCurrentUser = async () => {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        const res = await axios.get("/api/auth/me", {
          headers: { Authorization: `Bearer ${token}` }
        });
        setCurrentUser(res.data);
      }
    } catch (error) {
      console.error("Erro ao buscar usuário atual:", error);
    }
  };

  const handleSendMessage = (phoneNumber) => {
    const message = "Olá, gostaria de mais informações sobre o seu cadastro.";
    const encodedMessage = encodeURIComponent(message);
    window.open(`https://wa.me/55${phoneNumber.replace(/\D/g, '')}?text=${encodedMessage}`, '_blank');
  };

  const handleEditUser = async (user) => {
    try {
      console.log("Editando usuário:", user);
      if (user && user.iduser) {
        navigate(`/FormEdit/${user.iduser}`);
      } else {
        toast.error("ID do usuário não encontrado");
      }
    } catch (error) {
      console.error("Erro ao editar usuário:", error);
      toast.error("Erro ao editar usuário");
    }
  };

  const handleDeleteUser = async (user) => {
    try {
      console.log("Excluindo usuário:", user);
      if (user && user.iduser) {
        const response = await axios.delete(`/api/users/${user.iduser}`);
        if (response.status === 200) {
          toast.success("Usuário excluído com sucesso!");
          getUsers();
        } else {
          toast.error("Erro ao excluir usuário");
        }
      } else {
        toast.error("ID do usuário não encontrado");
      }
    } catch (error) {
      console.error("Erro ao excluir usuário:", error);
      toast.error(error.response?.data?.message || "Erro ao excluir usuário");
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = users.filter((user) =>
    user.nome.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <SideBarAdm />
      <div className="ContainerApp70">
        <NavBar />
        <div className="ContentApp">
          <div className="SearchRh">
            <h3>Pesquisar Cadastros</h3>
            <input
              type="text"
              placeholder="Pesquisar colaborador"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          <UserListAdm
            users={filteredUsers}
            onSendMessage={handleSendMessage}
            onEdit={handleEditUser}
            onDelete={handleDeleteUser}
            currentUser={currentUser}
          />
        </div>
      </div>
    </>
  );
};

export default ListarAdm;
