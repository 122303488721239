import React, { useState, useEffect } from "react";
import {
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Grid,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const FormCadastroCurriculo = () => {
  const [curriculum, setCurriculum] = useState({
    nome: "",
    fone: "",
    email: "",
    cpf: "",
    data_nascimento: "",
    rua: "",
    civil: "",
    genero: "",
    resumoProfissional: "",
    FormacaoAcademicas: [],
    ExperienciasProfissionais: [],
    Idiomas: [],
  });
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const userId = localStorage.getItem("userId");
  const theme = useTheme();

  useEffect(() => {
    const fetchUserAndCurriculum = async () => {
      try {
        if (!userId) {
          console.error("userId não encontrado no localStorage");
          setLoading(false);
          return;
        }

        // Primeiro busca os dados do usuário
        const userResponse = await axios.get(`/users/${userId}`);
        const userData = userResponse.data;

        // Busca o currículo associado ao userId
        try {
          const curriculumResponse = await axios.get(`/curriculum/user/${userId}`);
          if (curriculumResponse.data) {
            // Se existe currículo, usa os dados do currículo
            const curriculumData = {
              ...curriculumResponse.data,
              FormacaoAcademicas: curriculumResponse.data.FormacaoAcademicas || [],
              ExperienciasProfissionais: curriculumResponse.data.ExperienciasProfissionais || [],
              Idiomas: curriculumResponse.data.Idiomas || [],
            };
            setCurriculum(curriculumData);
          }
        } catch (error) {
          if (error.response?.status === 404) {
            // Se não existe currículo, preenche com dados do usuário
            setCurriculum({
              nome: userData.nome || "",
              fone: userData.fone || "",
              email: userData.email || "",
              cpf: userData.cpf || "",
              data_nascimento: userData.data_nascimento ? userData.data_nascimento.split('T')[0] : "",
              rua: userData.rua || "",
              civil: userData.estado_civil || "",
              genero: userData.genero || "",
              resumoProfissional: "",
              FormacaoAcademicas: [],
              ExperienciasProfissionais: [],
              Idiomas: [],
            });
          } else {
            throw error; // Re-lança o erro se não for 404
          }
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        toast.error(
          error.response?.data?.error || 
          "Erro ao carregar dados do usuário e currículo. Por favor, tente novamente."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchUserAndCurriculum();
  }, [userId]);

  const validate = () => {
    let tempErrors = {};
    let isValid = true;

    // Validações básicas
    if (!curriculum.nome) {
      tempErrors.nome = "Nome é obrigatório";
      isValid = false;
    }
    if (!curriculum.email) {
      tempErrors.email = "Email é obrigatório";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(curriculum.email)) {
      tempErrors.email = "Email inválido";
      isValid = false;
    }
    if (!curriculum.cpf) {
      tempErrors.cpf = "CPF é obrigatório";
      isValid = false;
    }
    if (!curriculum.fone) {
      tempErrors.fone = "Telefone é obrigatório";
      isValid = false;
    }
    if (!curriculum.data_nascimento) {
      tempErrors.data_nascimento = "Data de nascimento é obrigatória";
      isValid = false;
    }

    // Validações para formação acadêmica
    curriculum.FormacaoAcademicas.forEach((formacao, index) => {
      if (!formacao.instituicao) {
        tempErrors[`formacao_${index}_instituicao`] = "Instituição é obrigatória";
        isValid = false;
      }
      if (!formacao.curso) {
        tempErrors[`formacao_${index}_curso`] = "Curso é obrigatório";
        isValid = false;
      }
    });

    // Validações para experiência profissional
    curriculum.ExperienciasProfissionais.forEach((exp, index) => {
      if (!exp.cargo) {
        tempErrors[`exp_${index}_cargo`] = "Cargo é obrigatório";
        isValid = false;
      }
      if (!exp.descricao) {
        tempErrors[`exp_${index}_descricao`] = "Descrição é obrigatória";
        isValid = false;
      }
    });

    setErrors(tempErrors);
    return isValid;
  };

  const handleChange = (e, category, index) => {
    const { name, value } = e.target;
    if (category) {
      setCurriculum((prevCurriculum) => {
        const updatedCategory = [...prevCurriculum[category]];
        updatedCategory[index] = { ...updatedCategory[index], [name]: value };
        return { ...prevCurriculum, [category]: updatedCategory };
      });
    } else {
      setCurriculum((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAdd = (category) => {
    setCurriculum((prevCurriculum) => ({
      ...prevCurriculum,
      [category]: [...prevCurriculum[category], {}],
    }));
  };

  const handleRemove = (category, index) => {
    setCurriculum((prevCurriculum) => {
      const updatedCategory = prevCurriculum[category].filter(
        (_, i) => i !== index
      );
      return { ...prevCurriculum, [category]: updatedCategory };
    });
  };

  const handleSave = async () => {
    try {
      if (!userId) {
        toast.error("ID do usuário não encontrado no armazenamento local.");
        return;
      }

      if (!validate()) {
        toast.error("Por favor, corrija os erros de validação.");
        return;
      }

      setLoading(true);

      // Formata os dados antes de enviar
      const formattedCurriculum = {
        ...curriculum,
        userId,
        formacaoAcademica: curriculum.FormacaoAcademicas.map(formacao => ({
          instituicao: formacao.instituicao,
          localidade: formacao.localidade,
          curso: formacao.curso,
          nivel: formacao.nivel,
          dataInicio: formacao.dataInicio,
          dataFim: formacao.dataFim
        })),
        experienciaProfissional: curriculum.ExperienciasProfissionais.map(exp => ({
          cargo: exp.cargo,
          area: exp.area,
          especializacao: exp.especializacao,
          descricao: exp.descricao
        })),
        idiomas: curriculum.Idiomas.map(idioma => ({
          idioma: idioma.idioma,
          nivel: idioma.nivel
        }))
      };

      try {
        const response = curriculum.id
          ? await axios.put(`/curriculum/id/${curriculum.id}`, formattedCurriculum)
          : await axios.post("/curriculum", formattedCurriculum);

        if (response.status === 200 || response.status === 201) {
          toast.success(
            curriculum.id
              ? "Currículo atualizado com sucesso!"
              : "Currículo criado com sucesso!"
          );
          
          if (!curriculum.id && response.data.id) {
            // Se foi uma criação, atualiza o ID do currículo
            setCurriculum(prev => ({
              ...prev,
              id: response.data.id
            }));
          }
        }
      } catch (error) {
        console.error("Erro na requisição:", error);
        const errorMessage = error.response?.data?.error || 
          (curriculum.id ? "Erro ao atualizar currículo" : "Erro ao criar currículo");
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error("Erro ao processar dados:", error);
      toast.error("Erro ao processar os dados do currículo");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="top"
      sx={{
        minHeight: "80vh",
        p: 2
      }}
    >
      <Grid item xs={12} sm={10} md={9}>
        <Box>
          <Box sx={{ pb: 2 }}>
            <Typography variant="h4" gutterBottom>
              Meu currículo
            </Typography>
            <Typography variant="body1" gutterBottom color="text.secondary">
              Mantenha seu currículo atualizado para receber vagas e
              oportunidades que se encaixam no seu perfil. Não perca chances de
              avançar na sua carreira!
            </Typography>
          </Box>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Informações pessoais</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Nome"
                    name="nome"
                    value={curriculum.nome}
                    onChange={(e) => handleChange(e)}
                    error={!!errors.nome}
                    helperText={errors.nome}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Telefone"
                    name="fone"
                    value={curriculum.fone}
                    onChange={(e) => handleChange(e)}
                    error={!!errors.fone}
                    helperText={errors.fone}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={curriculum.email}
                    onChange={(e) => handleChange(e)}
                    error={!!errors.email}
                    helperText={errors.email}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="CPF"
                    name="cpf"
                    value={curriculum.cpf}
                    onChange={(e) => handleChange(e)}
                    error={!!errors.cpf}
                    helperText={errors.cpf}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Data de Nascimento"
                    name="data_nascimento"
                    type="date"
                    value={curriculum.data_nascimento}
                    onChange={(e) => handleChange(e)}
                    error={!!errors.data_nascimento}
                    helperText={errors.data_nascimento}
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Estado Civil"
                    name="civil"
                    value={curriculum.civil}
                    onChange={(e) => handleChange(e)}
                    error={!!errors.civil}
                    helperText={errors.civil}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Gênero"
                    name="genero"
                    value={curriculum.genero}
                    onChange={(e) => handleChange(e)}
                    error={!!errors.genero}
                    helperText={errors.genero}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Endereço"
                    name="rua"
                    value={curriculum.rua}
                    onChange={(e) => handleChange(e)}
                    error={!!errors.rua}
                    helperText={errors.rua}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Resumo Profissional"
                    name="resumoProfissional"
                    value={curriculum.resumoProfissional}
                    onChange={(e) => handleChange(e)}
                    error={!!errors.resumoProfissional}
                    helperText={errors.resumoProfissional}
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Formação Acadêmica</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {curriculum.FormacaoAcademicas.map((item, index) => (
                <Box key={index} sx={{ mt: 2, p: 2, bgcolor: "background.paper", borderRadius: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Instituição"
                        name="instituicao"
                        value={item.instituicao || ""}
                        onChange={(e) => handleChange(e, "FormacaoAcademicas", index)}
                        error={!!errors[`formacao_${index}_instituicao`]}
                        helperText={errors[`formacao_${index}_instituicao`]}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Localidade"
                        name="localidade"
                        value={item.localidade || ""}
                        onChange={(e) => handleChange(e, "FormacaoAcademicas", index)}
                        error={!!errors[`formacao_${index}_localidade`]}
                        helperText={errors[`formacao_${index}_localidade`]}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Curso"
                        name="curso"
                        value={item.curso || ""}
                        onChange={(e) => handleChange(e, "FormacaoAcademicas", index)}
                        error={!!errors[`formacao_${index}_curso`]}
                        helperText={errors[`formacao_${index}_curso`]}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Nível"
                        name="nivel"
                        value={item.nivel || ""}
                        onChange={(e) => handleChange(e, "FormacaoAcademicas", index)}
                        error={!!errors[`formacao_${index}_nivel`]}
                        helperText={errors[`formacao_${index}_nivel`]}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Data de Início"
                        name="dataInicio"
                        type="date"
                        value={item.dataInicio || ""}
                        onChange={(e) => handleChange(e, "FormacaoAcademicas", index)}
                        error={!!errors[`formacao_${index}_dataInicio`]}
                        helperText={errors[`formacao_${index}_dataInicio`]}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Data de Conclusão"
                        name="dataFim"
                        type="date"
                        value={item.dataFim || ""}
                        onChange={(e) => handleChange(e, "FormacaoAcademicas", index)}
                        error={!!errors[`formacao_${index}_dataFim`]}
                        helperText={errors[`formacao_${index}_dataFim`]}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleRemove("FormacaoAcademicas", index)}
                      startIcon={<RemoveIcon />}
                    >
                      Remover Formação
                    </Button>
                  </Box>
                </Box>
              ))}
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleAdd("FormacaoAcademicas")}
                  startIcon={<AddIcon />}
                >
                  Adicionar Formação
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Experiência Profissional</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {curriculum.ExperienciasProfissionais.map((item, index) => (
                <Box key={index} sx={{ mt: 2, p: 2, bgcolor: "background.paper", borderRadius: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Cargo"
                        name="cargo"
                        value={item.cargo || ""}
                        onChange={(e) => handleChange(e, "ExperienciasProfissionais", index)}
                        error={!!errors[`exp_${index}_cargo`]}
                        helperText={errors[`exp_${index}_cargo`]}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Área"
                        name="area"
                        value={item.area || ""}
                        onChange={(e) => handleChange(e, "ExperienciasProfissionais", index)}
                        error={!!errors[`exp_${index}_area`]}
                        helperText={errors[`exp_${index}_area`]}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Especialização"
                        name="especializacao"
                        value={item.especializacao || ""}
                        onChange={(e) => handleChange(e, "ExperienciasProfissionais", index)}
                        error={!!errors[`exp_${index}_especializacao`]}
                        helperText={errors[`exp_${index}_especializacao`]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Descrição da Atividade"
                        name="descricao"
                        value={item.descricao || ""}
                        onChange={(e) => handleChange(e, "ExperienciasProfissionais", index)}
                        error={!!errors[`exp_${index}_descricao`]}
                        helperText={errors[`exp_${index}_descricao`]}
                        multiline
                        rows={4}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleRemove("ExperienciasProfissionais", index)}
                      startIcon={<RemoveIcon />}
                    >
                      Remover Experiência
                    </Button>
                  </Box>
                </Box>
              ))}
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleAdd("ExperienciasProfissionais")}
                  startIcon={<AddIcon />}
                >
                  Adicionar Experiência
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Idiomas</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {curriculum.Idiomas.map((item, index) => (
                <Box key={index} sx={{ mt: 2, p: 2, bgcolor: "background.paper", borderRadius: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Idioma"
                        name="idioma"
                        value={item.idioma || ""}
                        onChange={(e) => handleChange(e, "Idiomas", index)}
                        error={!!errors[`idioma_${index}`]}
                        helperText={errors[`idioma_${index}`]}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Nível"
                        name="nivel"
                        value={item.nivel || ""}
                        onChange={(e) => handleChange(e, "Idiomas", index)}
                        error={!!errors[`nivel_${index}`]}
                        helperText={errors[`nivel_${index}`]}
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleRemove("Idiomas", index)}
                      startIcon={<RemoveIcon />}
                    >
                      Remover Idioma
                    </Button>
                  </Box>
                </Box>
              ))}
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleAdd("Idiomas")}
                  startIcon={<AddIcon />}
                >
                  Adicionar Idioma
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Box sx={{ mt: 3, mb: 3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={loading}
              sx={{ mr: 2 }}
            >
              {loading ? (
                <>
                  <CircularProgress size={24} sx={{ mr: 1 }} />
                  Salvando...
                </>
              ) : (
                "Salvar Currículo"
              )}
            </Button>
          </Box>
          <ToastContainer />
        </Box>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Box
          component="img"
          src="/assets/curriculouser.png"
          alt="Currículo"
          sx={{
            width: "100%",
            height: "auto",
            mt: 5,
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              height: "auto",
              mt: 0,
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default FormCadastroCurriculo;