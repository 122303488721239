import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputMask from "react-input-mask";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  TextField,
  Button,
  Grid,
  Typography,
  MenuItem,
  Paper,
  CircularProgress
} from '@mui/material';

// Função para validar CPF
const isValidCPF = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, ""); // Remove caracteres especiais

  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
    return false;
  }

  let result = 0;
  let factor = 10;

  for (let i = 0; i < 9; i++) {
    result += parseInt(cpf.charAt(i)) * factor;
    factor--;
  }

  let remainder = result % 11;
  if (remainder < 2) {
    remainder = 0;
  } else {
    remainder = 11 - remainder;
  }

  if (remainder !== parseInt(cpf.charAt(9))) {
    return false;
  }

  result = 0;
  factor = 11;

  for (let i = 0; i < 10; i++) {
    result += parseInt(cpf.charAt(i)) * factor;
    factor--;
  }

  remainder = result % 11;
  if (remainder < 2) {
    remainder = 0;
  } else {
    remainder = 11 - remainder;
  }

  return remainder === parseInt(cpf.charAt(10));
};

const FormEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [userData, setUserData] = useState({
    nome: "",
    email: "",
    fone: "",
    cpf: "",
    rg: "",
    cep: "",
    rua: "",
    complemento: "",
    numero: "",
    cidade: "",
    estado: "",
    civil: "",
    genero: "",
    dependentes: "",
    data_nascimento: "",
  });

  useEffect(() => {
    if (!id) {
      toast.error("ID do usuário não fornecido");
      navigate("/listarrh");
    }
  }, [id, navigate]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`/users/${id}`);
        const formattedData = {
          nome: response.data.nome || "",
          email: response.data.email || "",
          fone: response.data.fone || "",
          cpf: response.data.cpf || "",
          rg: response.data.rg || "",
          cep: response.data.cep || "",
          rua: response.data.rua || "",
          complemento: response.data.complemento || "",
          numero: response.data.numero || "",
          cidade: response.data.cidade || "",
          estado: response.data.estado || "",
          civil: response.data.civil || "",
          genero: response.data.genero || "",
          dependentes: response.data.dependentes || "",
          data_nascimento: response.data.data_nascimento ? response.data.data_nascimento.split('T')[0] : "",
        };
        setUserData(formattedData);
        setLoading(false);
      } catch (error) {
        toast.error(error.response?.data?.message || "Erro ao carregar dados do usuário");
        setLoading(false);
      }
    };

    if (id) {
      fetchUser();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData(prev => ({
      ...prev,
      [name]: value || ""
    }));
  };

  const handleCPFChange = (e) => {
    const value = e.target.value || "";
    const maskedCPF = value
      .replace(/[^\d]/g, "")
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    setUserData(prev => ({
      ...prev,
      cpf: maskedCPF
    }));
  };

  const handleCEPChange = (e) => {
    const value = e.target.value || "";
    const formattedCEP = value.replace(/\D/g, "");
    setUserData(prev => ({
      ...prev,
      cep: formattedCEP
    }));
  };

  const loadAddress = async (cep) => {
    if (!cep || cep.length !== 8) return;

    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      if (!response.data.erro) {
        const { logradouro, localidade, uf } = response.data;
        setUserData(prev => ({
          ...prev,
          rua: logradouro || prev.rua,
          cidade: localidade || prev.cidade,
          estado: uf || prev.estado,
        }));
      } else {
        toast.error("CEP não encontrado");
      }
    } catch (error) {
      toast.error("Erro ao buscar endereço pelo CEP");
    }
  };

  const handleCEPBlur = () => {
    const cep = userData.cep.replace(/\D/g, "");
    if (cep.length === 8) {
      loadAddress(cep);
    }
  };

  const validateForm = () => {
    // Validar campos obrigatórios
    const camposObrigatorios = {
      nome: "Nome",
      email: "E-mail",
      fone: "Telefone",
      cpf: "CPF",
      rg: "RG",
      cep: "CEP",
      rua: "Rua",
      numero: "Número",
      complemento: "Complemento",
      cidade: "Cidade",
      estado: "Estado",
      civil: "Estado Civil",
      genero: "Gênero",
      dependentes: "Dependentes",
      data_nascimento: "Data de Nascimento"
    };

    // Verificar campos vazios
    const camposVazios = Object.entries(camposObrigatorios)
      .filter(([key]) => {
        const value = userData[key];
        // Verifica se o valor é undefined, null, string vazia ou contém apenas espaços
        return !value || (typeof value === 'string' && value.trim() === '');
      })
      .map(([_, label]) => label);

    if (camposVazios.length > 0) {
      toast.error(`Os seguintes campos são obrigatórios: ${camposVazios.join(", ")}`);
      return false;
    }

    // Validar formato do e-mail
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userData.email)) {
      toast.error("Formato de e-mail inválido!");
      return false;
    }

    // Validar CPF
    if (!isValidCPF(userData.cpf)) {
      toast.error("CPF inválido!");
      return false;
    }

    // Validar CEP
    const cepLimpo = String(userData.cep).replace(/\D/g, '');
    if (cepLimpo.length !== 8) {
      toast.error("CEP inválido! Deve conter 8 dígitos.");
      return false;
    }

    // Validar telefone
    const foneLimpo = String(userData.fone).replace(/\D/g, '');
    if (foneLimpo.length < 10 || foneLimpo.length > 11) {
      toast.error("Telefone inválido! Deve conter entre 10 e 11 dígitos.");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (submitting) return;
    if (!validateForm()) return;

    try {
      setSubmitting(true);
      toast.info("Atualizando dados...");

      const dadosParaEnviar = {
        ...userData,
        iduser: parseInt(id),
        numero: String(userData.numero),
        dependentes: String(userData.dependentes),
        data_nascimento: userData.data_nascimento
      };

      const response = await axios({
        method: 'put',
        url: `/users/${id}`,
        data: dadosParaEnviar,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        toast.success("Usuário atualizado com sucesso!");
        setTimeout(() => {
          navigate(-1);
        }, 1500);
      }
    } catch (error) {
      const mensagemErro = error.response?.data?.message || 
                          error.message || 
                          "Erro ao atualizar usuário. Tente novamente.";
      toast.error(mensagemErro);
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress sx={{ color: '#6A438B' }} />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      
      <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
        <Typography variant="h5" component="h1" gutterBottom sx={{ color: '#6A438B', mb: 4 }}>
          Editar Cadastro
        </Typography>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Nome completo"
                name="nome"
                value={userData.nome}
                onChange={handleChange}
                required
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="E-mail"
                name="email"
                type="email"
                value={userData.email}
                onChange={handleChange}
                required
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Telefone"
                name="fone"
                value={userData.fone}
                onChange={handleChange}
                required
                variant="outlined"
                InputProps={{
                  inputComponent: InputMask,
                  inputProps: {
                    mask: "(99) 99999-9999"
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="CPF"
                name="cpf"
                value={userData.cpf}
                onChange={handleCPFChange}
                required
                variant="outlined"
                InputProps={{
                  inputComponent: InputMask,
                  inputProps: {
                    mask: "999.999.999-99"
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="RG"
                name="rg"
                value={userData.rg}
                onChange={handleChange}
                required
                variant="outlined"
                InputProps={{
                  inputComponent: InputMask,
                  inputProps: {
                    mask: "99.999.999-9"
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="CEP"
                name="cep"
                value={userData.cep}
                onChange={handleCEPChange}
                onBlur={handleCEPBlur}
                required
                variant="outlined"
                inputProps={{ maxLength: 8 }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Rua"
                name="rua"
                value={userData.rua}
                onChange={handleChange}
                required
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Número"
                name="numero"
                value={userData.numero}
                onChange={handleChange}
                required
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Complemento"
                name="complemento"
                value={userData.complemento}
                onChange={handleChange}
                required
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Cidade"
                name="cidade"
                value={userData.cidade}
                onChange={handleChange}
                required
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Estado"
                name="estado"
                value={userData.estado}
                onChange={handleChange}
                required
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                select
                label="Estado Civil"
                name="civil"
                value={userData.civil}
                onChange={handleChange}
                required
                variant="outlined"
              >
                <MenuItem value="">Selecione</MenuItem>
                <MenuItem value="solteiro">Solteiro(a)</MenuItem>
                <MenuItem value="casado">Casado(a)</MenuItem>
                <MenuItem value="divorciado">Divorciado(a)</MenuItem>
                <MenuItem value="viuvo">Viúvo(a)</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                select
                label="Gênero"
                name="genero"
                value={userData.genero}
                onChange={handleChange}
                required
                variant="outlined"
              >
                <MenuItem value="">Selecione</MenuItem>
                <MenuItem value="masculino">Masculino</MenuItem>
                <MenuItem value="feminino">Feminino</MenuItem>
                <MenuItem value="outro">Outro</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Dependentes"
                name="dependentes"
                value={userData.dependentes}
                onChange={handleChange}
                required
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Data de Nascimento"
                name="data_nascimento"
                type="date"
                value={userData.data_nascimento}
                onChange={handleChange}
                required
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={submitting}
                  sx={{
                    bgcolor: '#6A438B',
                    '&:hover': {
                      bgcolor: '#563472'
                    },
                    minWidth: 200
                  }}
                >
                  {submitting ? "Atualizando..." : "Atualizar cadastro"}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default FormEdit;
